let words = [
  "அம்மாறு",
  "அசினம்",
  "அலவன்",
  "அழுகல்",
  "அலைசோலி",
  "அடைமழை",
  "அம்மார்",
  "அரசாணி",
  "அனான்மா",
  "அம்மாள்",
  "அளியன்",
  "அன்பன்",
  "அய்யன்",
  "அயணம்",
  "அலரோன்",
  "அதிகம்",
  "அசகம்",
  "அய்யர்",
  "அடியுறை",
  "அநாதன்",
  "அவிசாரி",
  "அருத்து",
  "அருத்தி",
  "அருகணை",
  "அறுதாலி",
  "அகடம்",
  "அன்பிலி",
  "அனூபம்",
  "அறுசுவை",
  "அடைமொழி",
  "அய்யவி",
  "அனிலன்",
  "அருகனி",
  "அதீனம்",
  "அகரம்",
  "அளியர்",
  "அயர்தி",
  "அமானம்",
  "அலையல்",
  "அயிலான்",
  "அவலிடி",
  "அதிகன்",
  "அறைதல்",
  "அம்மேயோ",
  "அனிலம்",
  "அயிகம்",
  "அனுரதி",
  "அபூதம்",
  "அப்தம்",
  "அங்காரி",
  "அருகன்",
  "அனலம்",
  "அகநகை",
  "அராவைரி",
  "அயலுரை",
  "அஞ்சனா",
  "அகுதார்",
  "அஞ்சனி",
  "அடிச்சி",
  "அனீகினி",
  "அஞ்சனை",
  "அஞ்சன்",
  "அடைகல்",
  "அமோகன்",
  "அயிஞ்சி",
  "அடிமாடு",
  "அஞ்சில்",
  "அஞலம்",
  "அடியுணி",
  "அழுகண்",
  "அறநீர்",
  "அஞ்சதி",
  "அணையல்",
  "அறைகுறை",
  "அரிடம்",
  "அமோகம்",
  "அடம்பு",
  "அட்டும்",
  "அடைகரை",
  "அவ்வோன்",
  "அபியோகி",
  "அவநுதி",
  "அல்வழி",
  "அதியர்",
  "அனலன்",
  "அதர்வை",
  "அடைகொளி",
  "அரமனை",
  "அம்மான்",
  "அம்மானை",
  "அசோண்டி",
  "அரப்பு",
  "அறவாழி",
  "அளிகம்",
  "அதிவிடை",
  "அலம்பு",
  "அணிதல்",
  "அலக்கு",
  "அமரல்",
  "அளகம்",
  "அதியன்",
  "அழுகுணி",
  "அடிதடி",
  "அமரர்",
  "அடைசீலை",
  "அழனம்",
  "அம்மாயி",
  "அம்மாய்",
  "அறைவாய்",
  "அங்காடி",
  "அஃகாமை",
  "அம்மாமி",
  "அமரம்",
  "அனலாடி",
  "அதட்டு",
  "அத்தக",
  "அறுவிதி",
  "அனுமோனை",
  "அழிகால்",
  "அழிகாலி",
  "அரத்தை",
  "அகாதம்",
  "அசமோதை",
  "அவரோகி",
  "அமரன்",
  "அலசல்",
  "அலந்தை",
  "அம்மணி",
  "அபிராமி",
  "அஃகான்",
  "அந்தோர்",
  "அடுசில்",
  "அறாம்பை",
  "அம்மனோ",
  "அம்மன்",
  "அம்மனே",
  "அம்மனை",
  "அரியேறு",
  "அரியேறி",
  "அரைநாண்",
  "அகநாடு",
  "அனிச்சை",
  "அலசம்",
  "அபிமுகி",
  "அச்சன்",
  "அதிரடி",
  "அம்மம்",
  "அடிதலை",
  "அசுரம்",
  "அரூபம்",
  "அஞ்சலி",
  "அவிநாசி",
  "அஞ்சல்",
  "அவிகாரி",
  "அம்மையோ",
  "அளப்பு",
  "அராகம்",
  "அபிசாரி",
  "அளமம்",
  "அரசிலை",
  "அமுசம்",
  "அறநூல்",
  "அஞ்சம்",
  "அரசிறை",
  "அனைத்து",
  "அறுபகை",
  "அசுரர்",
  "அனுஞ்ஞை",
  "அரங்கி",
  "அரங்கு",
  "அனாயம்",
  "அம்மகோ",
  "அசாரம்",
  "அருகர்",
  "அவணம்",
  "அவ்வது",
  "அப்போது",
  "அனுகூலி",
  "அடிமடி",
  "அகப்பை",
  "அலாபம்",
  "அபானன்",
  "அகப்பா",
  "அளுங்கு",
  "அடிமடை",
  "அகப்பு",
  "அகப்பூ",
  "அருகம்",
  "அயுதம்",
  "அவணன்",
  "அரைப்பை",
  "அவதானி",
  "அருங்கு",
  "அஞ்சாமை",
  "அரைநாள்",
  "அறவினை",
  "அடியீடு",
  "அகுளுதி",
  "அலாரிதா",
  "அருகல்",
  "அவிசல்",
  "அமிதம்",
  "அதப்பு",
  "அதிரல்",
  "அலசடி",
  "அறுவாய்",
  "அச்சம்",
  "அழிபசி",
  "அசைதல்",
  "அபானம்",
  "அஃறிணை",
  "அமையம்",
  "அகராதி",
  "அயர்வு",
  "அனுடம்",
  "அசரீரி",
  "அடைகோழி",
  "அமரோர்",
  "அளாவன்",
  "அமயம்",
  "அடையாள்",
  "அன்னாசி",
  "அன்னாசு",
  "அறவிலை",
  "அடையார்",
  "அருநிலை",
  "அவசம்",
  "அணிகம்",
  "அனலேறு",
  "அவயவி",
  "அன்றாடு",
  "அவிஞ்சை",
  "அம்புதி",
  "அடிமனை",
  "அவையல்",
  "அனீகனி",
  "அசுகுணி",
  "அலமாரி",
  "அருகாழி",
  "அன்னார்",
  "அத்துணை",
  "அழுக்கு",
  "அவையர்",
  "அலுப்பு",
  "அபயர்",
  "அறிவன்",
  "அலிகம்",
  "அழும்பு",
  "அருட்டி",
  "அயுக்தி",
  "அறுபான்",
  "அறிதல்",
  "அநாமிகை",
  "அறிவழி",
  "அபயம்",
  "அவயம்",
  "அலுவல்",
  "அனிழம்",
  "அனீகம்",
  "அகங்கை",
  "அடைசொல்",
  "அழற்சி",
  "அன்னாய்",
  "அழிப்பு",
  "அறுகுறை",
  "அகலறை",
  "அறசம்",
  "அபயன்",
  "அம்புயை",
  "அஞ்செவி",
  "அகலம்",
  "அறுநீர்",
  "அடிமணை",
  "அருநெறி",
  "அடிமண்",
  "அணிமலை",
  "அன்னான்",
  "அசுத்தை",
  "அசுத்தி",
  "அசைப்பு",
  "அவனிகை",
  "அமிர்தை",
  "அமிர்து",
  "அலியன்",
  "அரைதல்",
  "அகலன்",
  "அலகன்",
  "அகல்வு",
  "அரிதாள்",
  "அணியல்",
  "அல்குல்",
  "அழலாடி",
  "அண்டன்",
  "அழாந்தை",
  "அசாவேரி",
  "அணைக்கை",
  "அடர்பு",
  "அபகம்",
  "அல்கல்",
  "அறுகீரை",
  "அமந்தி",
  "அடைபுடை",
  "அகநிலை",
  "அணியம்",
  "அடியேன்",
  "அணுவலி",
  "அத்துவா",
  "அலிக்கை",
  "அணியன்",
  "அவையம்",
  "அடுப்பு",
  "அல்வான்",
  "அயிரம்",
  "அங்ஙன்",
  "அருளாழி",
  "அசோகன்",
  "அவிதல்",
  "அசரம்",
  "அபேதம்",
  "அசடன்",
  "அனிசம்",
  "அறாவிலை",
  "அறுமுறி",
  "அடியாள்",
  "அயிராணி",
  "அழாஅல்",
  "அடிதாறு",
  "அணித்து",
  "அருட்பா",
  "அவகதி",
  "அசோகம்",
  "அசையடி",
  "அழற்றி",
  "அழிதகை",
  "அவமழை",
  "அகைப்பு",
  "அமலர்",
  "அடாவடி",
  "அனுக்கை",
  "அண்ணார்",
  "அழித்து",
  "அசாவாமை",
  "அலட்டு",
  "அரிசம்",
  "அட்டன்",
  "அடியல்",
  "அமலம்",
  "அனுபமை",
  "அசம்பை",
  "அசம்பி",
  "அச்சாணி",
  "அட்டணை",
  "அதீதன்",
  "அசிதன்",
  "அண்டல்",
  "அடர்வு",
  "அலாதம்",
  "அறையோலை",
  "அடுதல்",
  "அண்ணாவி",
  "அகறல்",
  "அமலன்",
  "அறிக்கை",
  "அபுதன்",
  "அடியான்",
  "அதவம்",
  "அரதேசி",
  "அற்பாயு",
  "அண்டர்",
  "அல்லாரி",
  "அணையார்",
  "அவித்தை",
  "அனுபவி",
  "அனையன்",
  "அல்லாமை",
  "அங்கிரி",
  "அமுதாரி",
  "அனிதம்",
  "அபிருசி",
  "அண்டம்",
  "அனுபவை",
  "அகிலம்",
  "அரிணம்",
  "அலைப்பு",
  "அரோகம்",
  "அம்போதி",
  "அம்மாடி",
  "அடிகள்",
  "அரிவாள்",
  "அரசுவா",
  "அடர்மை",
  "அற்கன்",
  "அநீதம்",
  "அம்புளி",
  "அறவுரை",
  "அம்புலி",
  "அறுமீன்",
  "அவமதி",
  "அனுவுரு",
  "அங்காளி",
  "அளத்தி",
  "அச்சாறு",
  "அம்புவி",
  "அணுக்கு",
  "அதிபன்",
  "அறவுளி",
  "அளவெண்",
  "அங்கிகை",
  "அமுதன்",
  "அட்டல்",
  "அணுரூபி",
  "அளபெடை",
  "அல்லால்",
  "அறையல்",
  "அமுக்கி",
  "அடிவினை",
  "அசுப்பு",
  "அக்குரு",
  "அலவலை",
  "அட்டம்",
  "அணையாடை",
  "அலவல்",
  "அவிவேகி",
  "அதிபதி",
  "அமுதம்",
  "அவுணன்",
  "அரயன்",
  "அவுனியா",
  "அலவர்",
  "அற்சிரை",
  "அமர்வு",
  "அக்குள்",
  "அக்குளு",
  "அலேகம்",
  "அபிலாசை",
  "அதீதம்",
  "அசிதம்",
  "அவந்தி",
  "அசிதாரு",
  "அட்டமி",
  "அகவாய்",
  "அகுடம்",
  "அடாதது",
  "அமுதர்",
  "அமாவசி",
  "அகவடி",
  "அலைசடி",
  "அனுபூதி",
  "அடிபிடி",
  "அனுபோகி",
  "அயவாரி",
  "அவகேசி",
  "அலைசடை",
  "அவியல்",
  "அவதூதி",
  "அபாவம்",
  "அவிக்கை",
  "அறியாமை",
  "அரக்கு",
  "அரக்கி",
  "அழிவாய்",
  "அழிதலை",
  "அழிதல்",
  "அணவல்",
  "அளவிடை",
  "அளைதல்",
  "அரம்பு",
  "அம்பேல்",
  "அரம்பை",
  "அறவோலை",
  "அரனெறி",
  "அகாரம்",
  "அனந்தை",
  "அந்தில்",
  "அரைகல்",
  "அறுவரி",
  "அழிதூஉ",
  "அதிபலை",
  "அறுப்பு",
  "அவநாசி",
  "அவகேடு",
  "அரித்து",
  "அறிகரி",
  "அகிம்சை",
  "அல்லதை",
  "அரித்தை",
  "அன்றில்",
  "அல்லது",
  "அந்திரி",
  "அரையன்",
  "அரிவரி",
  "அரிதம்",
  "அகைதல்",
  "அலங்கை",
  "அணவன்",
  "அதிபம்",
  "அசபம்",
  "அசுழம்",
  "அவிகம்",
  "அறவிய",
  "அறுகால்",
  "அழக்கு",
  "அணிவில்",
  "அசுவினி",
  "அறைநன்",
  "அள்ளல்",
  "அழிபாடு",
  "அமட்டு",
  "அமேயம்",
  "அற்கம்",
  "அக்குசை",
  "அம்பாரி",
  "அசுணமா",
  "அரற்று",
  "அடிநீறு",
  "அலப்பு",
  "அத்திரி",
  "அத்திரு",
  "அபகாரி",
  "அசுணம்",
  "அறிவினா",
  "அம்பாள்",
  "அரிதகி",
  "அழிவது",
  "அம்பால்",
  "அவ்வாய்",
  "அரைஞாண்",
  "அகதேசி",
  "அவ்வாறு",
  "அம்பிகை",
  "அவைப்பு",
  "அஞ்ஞன்",
  "அணுகம்",
  "அறுவடை",
  "அணங்கு",
  "அமைப்பு",
  "அடிசில்",
  "அஞ்ஞதை",
  "அருச்சி",
  "அருச்சை",
  "அறுகாழி",
  "அகற்சி",
  "அடாநெறி",
  "அபட்கை",
  "அசுணன்",
  "அறவோன்",
  "அகவிலை",
  "அடிபாடு",
  "அனுசன்",
  "அந்தகோ",
  "அழுந்தை",
  "அழுந்து",
  "அத்தினி",
  "அறிவிலி",
  "அனுச்சை",
  "அக்கீம்",
  "அசலம்",
  "அமூலம்",
  "அர்ச்சை",
  "அருக்கு",
  "அரும்பு",
  "அதிர்வு",
  "அமணர்",
  "அசலன்",
  "அகசன்",
  "அயிநார்",
  "அரிப்பு",
  "அமணம்",
  "அந்தாக",
  "அசுவதி",
  "அல்லாத",
  "அபசாரி",
  "அடைதூண்",
  "அங்குரி",
  "அறவன்",
  "அனிகம்",
  "அதகம்",
  "அலைநீர்",
  "அளீகம்",
  "அறநெறி",
  "அஃகல்",
  "அந்திகை",
  "அகைமம்",
  "அயகம்",
  "அடித்தி",
  "அப்பால்",
  "அட்டாணி",
  "அத்திலை",
  "அந்தாளி",
  "அந்தாலே",
  "அதிகாரி",
  "அலைவாய்",
  "அழிகடை",
  "அதோகதி",
  "அணுகார்",
  "அபரம்",
  "அலவான்",
  "அஃகம்",
  "அவரம்",
  "அன்னுழி",
  "அன்னுழை",
  "அங்குலி",
  "அடைகாய்",
  "அரந்தை",
  "அரிமணி",
  "அநிதம்",
  "அதகன்",
  "அசனம்",
  "அப்பிகை",
  "அராளம்",
  "அகளம்",
  "அப்பாவி",
  "அதிகாலை",
  "அசைநிலை",
  "அவநெறி",
  "அந்தன்",
  "அயலான்",
  "அகண்டி",
  "அரிதல்",
  "அலைசல்",
  "அசுவம்",
  "அல்லல்",
  "அறியல்",
  "அறிஞன்",
  "அறிகுறி",
  "அமரார்",
  "அவதூறு",
  "அடிநிலை",
  "அப்பாயி",
  "அத்தர்",
  "அருளம்",
  "அவாவன்",
  "அறுதலி",
  "அந்தம்",
  "அளித்து",
  "அறுதல்",
  "அல்லம்",
  "அரையர்",
  "அளவில்",
  "அந்தாதி",
  "அத்தம்",
  "அந்தரி",
  "அணிநுணா",
  "அட்டாளை",
  "அடிமுடி",
  "அந்தர்",
  "அதமம்",
  "அட்டிகை",
  "அரீடம்",
  "அதுலம்",
  "அக்கட",
  "அருளல்",
  "அழிமதி",
  "அறிவாளி",
  "அந்தலை",
  "அந்தளி",
  "அக்கினி",
  "அரவுரி",
  "அபராதி",
  "அணிலம்",
  "அதுலன்",
  "அயன்மை",
  "அலகம்",
  "அகிஞ்சை",
  "அவரசை",
  "அஞ்ஞானி",
  "அண்பல்",
  "அதமன்",
  "அலகரி",
  "அறுமான்",
  "அடத்தி",
  "அனுமிதி",
  "அல்லவை",
  "அலத்தி",
  "அத்தனை",
  "அவாவல்",
  "அட்டாலை",
  "அடைசல்",
  "அத்தன்",
  "அழிஞ்சு",
  "அழலோன்",
  "அச்சுதை",
  "அஃகுள்",
  "அணைதறி",
  "அலைதல்",
  "அணைதல்",
  "அசவல்",
  "அனாதன்",
  "அதலம்",
  "அப்பம்",
  "அவசன்",
  "அபூபம்",
  "அரவம்",
  "அகிதன்",
  "அயுத்தி",
  "அகமம்",
  "அதலன்",
  "அழிநோய்",
  "அன்னோன்",
  "அப்பர்",
  "அகம்பு",
  "அரவன்",
  "அடையோலை",
  "அசித்து",
  "அப்பல்",
  "அழுவம்",
  "அடைப்பு",
  "அடைப்பை",
  "அகழான்",
  "அரைவாய்",
  "அதிதனு",
  "அபோதம்",
  "அடவாதி",
  "அல்லுழி",
  "அமைவன்",
  "அற்காமை",
  "அவத்தை",
  "அலைதாடி",
  "அமஞ்சி",
  "அச்சுறை",
  "அரவணை",
  "அரிகல்",
  "அரகர",
  "அபாயம்",
  "அடுக்கு",
  "அகாலம்",
  "அகமணை",
  "அழியல்",
  "அடும்பு",
  "அவளம்",
  "அரியணை",
  "அகமதி",
  "அகிதம்",
  "அயனம்",
  "அமிஞ்சி",
  "அரிகால்",
  "அயிணம்",
  "அடாதுடி",
  "அடிநாள்",
  "அரிட்டை",
  "அட்டிமை",
  "அக்காரை",
  "அழைப்பு",
  "அசிரன்",
  "அட்சம்",
  "அண்ணம்",
  "அங்கன்",
  "அங்கனை",
  "அருணன்",
  "அங்கனி",
  "அழுப்பு",
  "அனுவழி",
  "அடைதல்",
  "அம்பல்",
  "அடிநாய்",
  "அறுமணை",
  "அமரிறை",
  "அப்படா",
  "அப்படி",
  "அம்பலி",
  "அனுசாகை",
  "அங்கம்",
  "அம்பர்",
  "அக்காள்",
  "அருணம்",
  "அளவர்",
  "அசிரம்",
  "அண்டில்",
  "அறிநன்",
  "அளவம்",
  "அட்சதை",
  "அட்சய",
  "அழிகரு",
  "அக்கானி",
  "அப்பணை",
  "அனேகன்",
  "அண்ணன்",
  "அப்பதி",
  "அறுகடி",
  "அமைச்சு",
  "அமிழ்து",
  "அழியாமை",
  "அங்கதி",
  "அகோரம்",
  "அங்கண்",
  "அற்றம்",
  "அங்கணி",
  "அட்டில்",
  "அதேந்து",
  "அகோடம்",
  "அரணம்",
  "அனேகம்",
  "அரிகிணை",
  "அப்பன்",
  "அசட்டி",
  "அளவன்",
  "அண்ணணி",
  "அசட்டை",
  "அங்கதை",
  "அமாவாசை",
  "அசும்பு",
  "அதோமாயை",
  "அலோகம்",
  "அற்றேல்",
  "அயனாள்",
  "அசராது",
  "அக்கன்",
  "அபேட்சை",
  "அசராதி",
  "அற்பம்",
  "அப்புது",
  "அன்னன்",
  "அடலார்",
  "அதைப்பு",
  "அடைநிலை",
  "அசுவுணி",
  "அக்காணி",
  "அணைப்பு",
  "அரணியா",
  "அவாயம்",
  "அனுகன்",
  "அக்கம்",
  "அரைவாசி",
  "அல்லோன்",
  "அன்னம்",
  "அங்கர்",
  "அடியோடு",
  "அக்கடா",
  "அசப்பு",
  "அக்கடி",
  "அளவடி",
  "அகலுள்",
  "அனயம்",
  "அநிகம்",
  "அரட்சி",
  "அங்கலி",
  "அழுத்து",
  "அறைவீடு",
  "அபாரம்",
  "அமிசம்",
  "அனுசாரி",
  "அன்னல்",
  "அனுகம்",
  "அக்கணா",
  "அவலம்",
  "அதான்று",
  "அரசம்",
  "அசுமம்",
  "அடுநறா",
  "அற்பர்",
  "அரட்டி",
  "அரட்டு",
  "அக்கதை",
  "அண்ணல்",
  "அரங்க",
  "அகந்தை",
  "அன்மொழி",
  "அடலம்",
  "அனங்கு",
  "அபலம்",
  "அகவல்",
  "அசிப்பு",
  "அருவர்",
  "அறநிலை",
  "அலறல்",
  "அடைத்து",
  "அகதம்",
  "அடைமண்",
  "அகவர்",
  "அனுமதை",
  "அம்சம்",
  "அனுமதி",
  "அற்றார்",
  "அருவம்",
  "அகையாறு",
  "அத்தான்",
  "அபலன்",
  "அரசன்",
  "அசங்கை",
  "அறிப்பு",
  "அவலன்",
  "அரியம்",
  "அழகர்",
  "அரியமா",
  "அரைகுறை",
  "அருவல்",
  "அழகம்",
  "அவிச்சை",
  "அற்பசி",
  "அமைதல்",
  "அகடூரி",
  "அகுணம்",
  "அண்டார்",
  "அகதன்",
  "அரியல்",
  "அரைசிலை",
  "அறுசூலை",
  "அபிமானி",
  "அக்கரை",
  "அன்றமை",
  "அனகம்",
  "அழுங்கு",
  "அக்கறை",
  "அழிபுண்",
  "அதடம்",
  "அடைகுடி",
  "அசேடம்",
  "அத்தாணி",
  "அனகன்",
  "அழகன்",
  "அதரம்",
  "அறும்பு",
  "அபாரணை",
  "அழிம்பு",
  "அரைமூடி",
  "ஆலோலம்",
  "ஆறாட்டு",
  "ஆவத்து",
  "ஆசட்சு",
  "ஆம்புறு",
  "ஆகையர்",
  "ஆலகம்",
  "ஆனவன்",
  "ஆமயம்",
  "ஆட்டினி",
  "ஆகவும்",
  "ஆட்கூலி",
  "ஆளாபம்",
  "ஆஞ்சான்",
  "ஆரதம்",
  "ஆலுதல்",
  "ஆரவம்",
  "ஆரியன்",
  "ஆசவம்",
  "ஆப்பம்",
  "ஆருவம்",
  "ஆளெனல்",
  "ஆதிநாள்",
  "ஆகமம்",
  "ஆய்தூவி",
  "ஆயுகம்",
  "ஆலேகனி",
  "ஆவிருதி",
  "ஆளத்தி",
  "ஆறாதூறு",
  "ஆண்டகை",
  "ஆட்காசு",
  "ஆனையோசை",
  "ஆசிமொழி",
  "ஆளோட்டி",
  "ஆதிமீன்",
  "ஆங்கண்",
  "ஆரியம்",
  "ஆகனாமி",
  "ஆனுலகு",
  "ஆலிப்பு",
  "ஆயாமம்",
  "ஆற்றுணா",
  "ஆன்றார்",
  "ஆரூரன்",
  "ஆணகம்",
  "ஆலகாலி",
  "ஆழரம்",
  "ஆம்புடை",
  "ஆபயன்",
  "ஆவிலம்",
  "ஆலோபம்",
  "ஆபத்து",
  "ஆந்தோளி",
  "ஆரூடன்",
  "ஆயனம்",
  "ஆசெறூண்",
  "ஆதனம்",
  "ஆசியம்",
  "ஆரூடம்",
  "ஆனுதல்",
  "ஆடாதோடை",
  "ஆனியம்",
  "ஆசாடம்",
  "ஆட்சுமை",
  "ஆதிபுரி",
  "ஆவகம்",
  "ஆதுமம்",
  "ஆபகம்",
  "ஆடுமாடு",
  "ஆசேகம்",
  "ஆலோகம்",
  "ஆதானம்",
  "ஆகுகன்",
  "ஆய்ப்பு",
  "ஆலலம்",
  "ஆகேடம்",
  "ஆராதனை",
  "ஆடகம்",
  "ஆபீரம்",
  "ஆதொண்டை",
  "ஆயத்தி",
  "ஆரோபம்",
  "ஆழித்தீ",
  "ஆட்கால்",
  "ஆசூரம்",
  "ஆவாசம்",
  "ஆனாலும்",
  "ஆடுதுடை",
  "ஆட்பலி",
  "ஆத்துமா",
  "ஆபீரன்",
  "ஆமிசம்",
  "ஆர்த்தி",
  "ஆண்சாவி",
  "ஆராவம்",
  "ஆடகன்",
  "ஆகாயம்",
  "ஆக்கம்",
  "ஆஞ்சில்",
  "ஆலூகம்",
  "ஆள்வரி",
  "ஆக்கர்",
  "ஆட்டம்",
  "ஆரணம்",
  "ஆரீதம்",
  "ஆதிநாடி",
  "ஆகிருதி",
  "ஆதாயம்",
  "ஆம்புகு",
  "ஆவயின்",
  "ஆயோகம்",
  "ஆகின்று",
  "ஆதியூழி",
  "ஆடோபம்",
  "ஆதுரன்",
  "ஆகவாக",
  "ஆசங்கை",
  "ஆலவாய்",
  "ஆரணன்",
  "ஆயதம்",
  "ஆசரணை",
  "ஆனையடி",
  "ஆசுகம்",
  "ஆம்பலா",
  "ஆளறுதி",
  "ஆதவம்",
  "ஆளானம்",
  "ஆகாசம்",
  "ஆனைவாழை",
  "ஆன்றல்",
  "ஆம்பல்",
  "ஆவிதம்",
  "ஆக்கல்",
  "ஆர்கலி",
  "ஆடைமேல்",
  "ஆபீனம்",
  "ஆசிதம்",
  "ஆதவன்",
  "ஆமிலம்",
  "ஆனயம்",
  "ஆமோதம்",
  "ஆய்தம்",
  "ஆவலர்",
  "ஆயவன்",
  "ஆயினும்",
  "ஆசுகன்",
  "ஆயிரம்",
  "ஆட்டோசை",
  "ஆற்றல்",
  "ஆகாதிலை",
  "ஆறாமீன்",
  "ஆசீயம்",
  "ஆவலம்",
  "ஆதிநூல்",
  "ஆகதர்",
  "ஆணிவேர்",
  "ஆய்தல்",
  "ஆள்வினை",
  "ஆவாலம்",
  "ஆயிட்டு",
  "ஆபாசம்",
  "ஆகதம்",
  "ஆழாரம்",
  "ஆதாபாதா",
  "ஆடுதலி",
  "ஆடுதல்",
  "ஆமனம்",
  "ஆர்ச்சி",
  "ஆசிரமி",
  "ஆண்டலை",
  "ஆசுமணை",
  "ஆரொட்டி",
  "ஆகவம்",
  "ஆயானம்",
  "ஆலைமாலை",
  "ஆவல்லி",
  "ஆக்கினை",
  "ஆக்கன்",
  "ஆலாத்து",
  "ஆலாத்தி",
  "ஆதரவு",
  "ஆசறுதி",
  "ஆனைந்து",
  "ஆணியம்",
  "ஆவாபம்",
  "ஆரஞ்சு",
  "ஆசயம்",
  "ஆனகம்",
  "ஆட்டாள்",
  "ஆமலகி",
  "ஆட்டாளி",
  "ஆவெனல்",
  "ஆயசூரி",
  "ஆடுநர்",
  "ஆதலால்",
  "ஆனைசேனை",
  "ஆளடிமை",
  "ஆனந்தி",
  "ஆடுதோடா",
  "ஆதரம்",
  "ஆனந்தை",
  "ஆடுகால்",
  "ஆபனம்",
  "ஆற்றாமை",
  "ஆப்தன்",
  "ஆண்டான்",
  "ஆழாக்கு",
  "ஆகுதல்",
  "ஆனிலன்",
  "ஆசௌசம்",
  "ஆதன்மை",
  "ஆட்டுலா",
  "ஆயின்று",
  "ஆகலூழ்",
  "ஆள்வார்",
  "ஆலீடம்",
  "ஆமசூலை",
  "ஆண்டோன்",
  "ஆண்டார்",
  "ஆலவன்",
  "ஆபோகம்",
  "ஆகையால்",
  "ஆண்குறி",
  "ஆசேதம்",
  "ஆகாரம்",
  "ஆனாகம்",
  "ஆரகம்",
  "ஆராதூரி",
  "ஆயுநூல்",
  "ஆணிப்பூ",
  "ஆவாதம்",
  "ஆண்டாள்",
  "ஆபாதம்",
  "ஆறுதல்",
  "ஆரோணம்",
  "ஆதிகவி",
  "ஆலாபம்",
  "ஆதரணை",
  "ஆமாகோனா",
  "ஆதுவம்",
  "ஆராமம்",
  "ஆள்வாரி",
  "ஆதாரம்",
  "ஆண்பால்",
  "ஆணவம்",
  "ஆச்சிலை",
  "ஆபாதன்",
  "ஆமுகம்",
  "ஆயுதம்",
  "ஆரகன்",
  "ஆகரம்",
  "ஆசந்தி",
  "ஆழாங்கு",
  "ஆய்த்தி",
  "ஆய்த்து",
  "ஆலத்தி",
  "ஆங்காரி",
  "ஆளொட்டி",
  "ஆறுசூடி",
  "ஆமுகர்",
  "ஆர்மதி",
  "ஆனைத்தீ",
  "ஆயமேரை",
  "ஆதாரணை",
  "ஆகரன்",
  "ஆண்மாரி",
  "ஆன்மபூ",
  "ஆணையோலை",
  "ஆமையாழ்",
  "ஆதிகம்",
  "ஆசுகவி",
  "ஆவிரம்",
  "ஆடவள்",
  "ஆயசம்",
  "ஆளாதல்",
  "ஆபதம்",
  "ஆடிடம்",
  "ஆரத்தி",
  "ஆத்திரை",
  "ஆதீனம்",
  "ஆத்தியை",
  "ஆழியான்",
  "ஆண்வழி",
  "ஆலந்தை",
  "ஆசத்தி",
  "ஆவேசம்",
  "ஆதுரம்",
  "ஆடவன்",
  "ஆராட்சி",
  "ஆதிபன்",
  "ஆரூபம்",
  "ஆதிமலை",
  "ஆளகம்",
  "ஆர்கதி",
  "ஆரபடி",
  "ஆபீலம்",
  "ஆவநாழி",
  "ஆபாடம்",
  "ஆசிகம்",
  "ஆமையோடு",
  "ஆர்க்கை",
  "ஆர்க்கு",
  "ஆசெதுகை",
  "ஆசூசம்",
  "ஆதேசம்",
  "ஆச்சான்",
  "ஆரோகம்",
  "ஆனைநார்",
  "ஆகுலம்",
  "ஆமைமடி",
  "ஆனனம்",
  "ஆசலம்",
  "ஆழ்தல்",
  "ஆமரம்",
  "ஆலாதாடை",
  "ஆத்தல்",
  "ஆனந்த",
  "ஆதண்டை",
  "ஆய்ச்சி",
  "ஆத்தாடி",
  "ஆச்சன்",
  "ஆச்சனை",
  "ஆலிடம்",
  "ஆதிரம்",
  "ஆமிளிகை",
  "ஆத்தம்",
  "ஆயந்தி",
  "ஆதேயம்",
  "ஆனைமீன்",
  "ஆசாரம்",
  "ஆவேலம்",
  "ஆச்சாள்",
  "ஆதிரன்",
  "ஆவலாதி",
  "ஆசுவம்",
  "ஆண்பெண்",
  "ஆனஞ்சு",
  "ஆத்தன்",
  "ஆர்வம்",
  "ஆவஞ்சி",
  "ஆயோதம்",
  "ஆலசம்",
  "ஆதுலன்",
  "ஆனவாள்",
  "ஆலாபினி",
  "ஆச்சல்",
  "ஆரிடம்",
  "ஆடிச்சி",
  "ஆர்தல்",
  "ஆலோசனை",
  "ஆரிடர்",
  "ஆவிகம்",
  "ஆடுசதை",
  "ஆணத்தி",
  "ஆசனம்",
  "ஆலம்பி",
  "ஆத்தாள்",
  "ஆமிரம்",
  "ஆணிக்கை",
  "ஆதிபூமி",
  "ஆபானம்",
  "ஆலயம்",
  "ஆபணம்",
  "ஆவணம்",
  "ஆமிடம்",
  "ஆளிவிதை",
  "ஆடனூல்",
  "ஆதானும்",
  "ஆண்பனை",
  "ஆன்றோர்",
  "ஆண்பாடு",
  "ஆதபம்",
  "ஆதிமொழி",
  "ஆதமிலி",
  "ஆயாசம்",
  "ஆசுணம்",
  "ஆளாழம்",
  "ஆடுமாலை",
  "ஆர்ப்பு",
  "ஆலாட்டு",
  "ஆழ்வார்",
  "ஆன்றோள்",
  "ஆவாரம்",
  "ஆய்ஞன்",
  "ஆதபன்",
  "ஆனவர்",
  "ஆனதம்",
  "ஆநின்று",
  "ஆவியர்",
  "ஆனையாள்",
  "ஆலாலம்",
  "ஆவரணி",
  "ஆற்றான்",
  "ஆளிவிரை",
  "ஆகட்டு",
  "ஆவுதல்",
  "ஆனாயம்",
  "ஆப்தம்",
  "ஆழ்வான்",
  "ஆனாயன்",
  "ஆக்கும்",
  "ஆவிநீர்",
  "ஆசுரம்",
  "இளநகை",
  "இளைப்பு",
  "இனமொழி",
  "இன்னுழி",
  "இடைகலை",
  "இனைத்து",
  "இசிப்பு",
  "இல்லல்",
  "இன்சொல்",
  "இருபால்",
  "இடுகிடை",
  "இடக்கை",
  "இரசிகை",
  "இராகினி",
  "இறுதல்",
  "இருமான்",
  "இரைத்து",
  "இறுக்கு",
  "இடக்கு",
  "இல்லவை",
  "இலவம்",
  "இடையெண்",
  "இரிதல்",
  "இரேதசு",
  "இசைகேடு",
  "இரசாலை",
  "இகழ்வு",
  "இமசானு",
  "இதரம்",
  "இருவாம்",
  "இராமன்",
  "இடுநெறி",
  "இந்துகை",
  "இருளன்",
  "இறையான்",
  "இறுவரை",
  "இம்பில்",
  "இயைதல்",
  "இமையிலி",
  "இணங்கி",
  "இசுப்பு",
  "இணங்கு",
  "இலாகிரி",
  "இடிதல்",
  "இதரன்",
  "இலங்கு",
  "இதணம்",
  "இணையல்",
  "இருவேம்",
  "இகாலான்",
  "இலங்கை",
  "இஞ்சம்",
  "இரிமான்",
  "இச்சில்",
  "இடுகால்",
  "இடசாரி",
  "இற்றிசை",
  "இகாலோன்",
  "இளிம்பு",
  "இச்சியை",
  "இன்பம்",
  "இடவிய",
  "இறைதல்",
  "இலைச்சை",
  "இன்னும்",
  "இயமான்",
  "இரசகி",
  "இரம்பை",
  "இராப்பூ",
  "இங்கிரி",
  "இருகண்",
  "இமிழிசை",
  "இரக்கை",
  "இடைகழி",
  "இறையோன்",
  "இறுங்கு",
  "இப்பால்",
  "இகரம்",
  "இவறல்",
  "இசைநூல்",
  "இடறல்",
  "இத்தனை",
  "இறைப்பு",
  "இரத்தை",
  "இரத்தி",
  "இசையோர்",
  "இலாசிகை",
  "இல்லது",
  "இரிசியா",
  "இலையம்",
  "இழையூசி",
  "இனமோனை",
  "இடுகளி",
  "இணைப்பு",
  "இமைப்பு",
  "இலாளன்",
  "இலாளனை",
  "இரைசல்",
  "இருந்தை",
  "இயற்கை",
  "இடவழு",
  "இருந்து",
  "இடுப்பு",
  "இருவாடி",
  "இடங்கை",
  "இம்மடி",
  "இனவாரி",
  "இளிப்பு",
  "இணைக்கை",
  "இசைதல்",
  "இருபோது",
  "இருபூலை",
  "இமையம்",
  "இறுவாக",
  "இலீக்கை",
  "இலாகன்",
  "இராட்டு",
  "இசையோன்",
  "இசிகர்",
  "இலத்தை",
  "இந்தம்",
  "இலத்தி",
  "இமையோர்",
  "இருளல்",
  "இன்கவி",
  "இரேவதி",
  "இல்லம்",
  "இருளறை",
  "இருநீர்",
  "இருபான்",
  "இச்சம்",
  "இளகல்",
  "இலாக்கை",
  "இறுமுறி",
  "இலாக்கா",
  "இலாபம்",
  "இராதாரி",
  "இராமம்",
  "இயலாமை",
  "இலகிரி",
  "இசங்கு",
  "இன்புறா",
  "இபுதார்",
  "இடிம்பு",
  "இடவன்",
  "இரேசன்",
  "இன்னாமை",
  "இளகம்",
  "இலகிமா",
  "இயந்தா",
  "இரேசனி",
  "இருத்தை",
  "இதயம்",
  "இயந்தை",
  "இட்டவை",
  "இருத்து",
  "இருத்தி",
  "இட்டிகை",
  "இடுதிரை",
  "இழுபறி",
  "இணையசை",
  "இலைவேல்",
  "இருமடி",
  "இடிசல்",
  "இன்னாலை",
  "இலையடை",
  "இரட்டி",
  "இரட்டு",
  "இட்டலி",
  "இரமதி",
  "இரிணம்",
  "இளையெள்",
  "இரேயம்",
  "இனிமேல்",
  "இட்டறை",
  "இறவம்",
  "இன்னார்",
  "இரட்டை",
  "இடாவேணி",
  "இன்னோன்",
  "இந்துளி",
  "இடும்பி",
  "இலுப்பை",
  "இட்டிடை",
  "இடும்பு",
  "இளைவலி",
  "இன்னர்",
  "இசைப்பு",
  "இசைப்பா",
  "இட்டிய",
  "இடுக்கி",
  "இரைப்பு",
  "இடிமீன்",
  "இந்துவி",
  "இடுக்கு",
  "இசைநாள்",
  "இரட்சை",
  "இன்னல்",
  "இயாகம்",
  "இராதம்",
  "இழிதல்",
  "இன்னாது",
  "இடும்பை",
  "இரித்தை",
  "இதாசனி",
  "இந்துள்",
  "இரைப்பை",
  "இணாட்டு",
  "இளையான்",
  "இசையோலை",
  "இட்டிது",
  "இரேகழி",
  "இன்னான்",
  "இருமுறி",
  "இவர்வு",
  "இந்துறு",
  "இலட்டை",
  "இன்றிய",
  "இடிப்பு",
  "இளமழை",
  "இன்னம்",
  "இட்டேறி",
  "இல்லடை",
  "இலட்டு",
  "இழிநீர்",
  "இறையிலி",
  "இடியேறு",
  "இன்பன்",
  "இடப்பு",
  "இட்டோடு",
  "இனமுறை",
  "இருசால்",
  "இறைவரை",
  "இக்கிரி",
  "இளநீர்",
  "இளையோன்",
  "இமகிரி",
  "இருமல்",
  "இலளிதை",
  "இன்கண்",
  "இறஞ்சி",
  "இலாலனை",
  "இயைவது",
  "இழப்பு",
  "இளையார்",
  "இறுவாய்",
  "இடவகை",
  "இலக்கு",
  "இவணர்",
  "இப்போது",
  "இப்போதே",
  "இலக்கை",
  "இளையாள்",
  "இரிக்கி",
  "இலம்பை",
  "இலம்பு",
  "இடுகடை",
  "இரிசால்",
  "இருபது",
  "இலயம்",
  "இறைவன்",
  "இரண்டு",
  "இரண்டை",
  "இடுமம்",
  "இனவழி",
  "இரப்பு",
  "இலேசம்",
  "இடைநாடி",
  "இயவானி",
  "இணையணை",
  "இப்பாடு",
  "இடைவீடு",
  "இலயன்",
  "இணக்கு",
  "இரேசம்",
  "இளிகண்",
  "இளமண்",
  "இராமாறு",
  "இடநாள்",
  "இகுதல்",
  "இயமம்",
  "இராமாலை",
  "இறைகுடி",
  "இல்லெலி",
  "இங்குதி",
  "இலகாம்",
  "இயல்பு",
  "இராடம்",
  "இலகல்",
  "இந்திகை",
  "இரிஞன்",
  "இரேபன்",
  "இரிப்பு",
  "இடைவரி",
  "இருதம்",
  "இளமுறை",
  "இளைச்சி",
  "இலாடவி",
  "இருசாதி",
  "இயமன்",
  "இமயம்",
  "இருதிணை",
  "இலகரி",
  "இலேகம்",
  "இலகம்",
  "இறுகல்",
  "இலகான்",
  "இரவல்",
  "இலேகர்",
  "இனிப்பு",
  "இறுகரை",
  "இன்னிலை",
  "இயனெறி",
  "இட்டடை",
  "இருண்மை",
  "இலைப்பி",
  "இழிங்கு",
  "இன்னினி",
  "இரைதல்",
  "இராணம்",
  "இருக்கை",
  "இரதம்",
  "இலாடம்",
  "இழைப்பு",
  "இரவம்",
  "இலாச்சி",
  "இட்டீடு",
  "இராவோன்",
  "இடுமுள்",
  "இயக்கி",
  "இளவாடை",
  "இரிகம்",
  "இருநிதி",
  "இளந்தை",
  "இங்கம்",
  "இசைமறை",
  "இடிகரை",
  "இமாசலை",
  "இங்குளி",
  "இடைவழி",
  "இளங்கோ",
  "இசைநிறை",
  "இலாடன்",
  "இருக்கு",
  "இரதன்",
  "இரதனை",
  "இடைதல்",
  "இரும்பை",
  "இடிகம்",
  "இரும்பு",
  "இரவன்",
  "இசைமூடி",
  "இடிபூரா",
  "இரிசல்",
  "இளையர்",
  "இன்னன்",
  "இயலணி",
  "இடுதல்",
  "இரேகம்",
  "இற்கடை",
  "இடுவல்",
  "இருவிள",
  "இன்னது",
  "இயல்வு",
  "இரேசகி",
  "இருசுழி",
  "இறைமொழி",
  "இளநிலா",
  "இயனம்",
  "இத்துணை",
  "இறப்ப",
  "இளையள்",
  "இழிபாடு",
  "இலலிதை",
  "இயலடி",
  "இலிங்கி",
  "இலிங்கு",
  "இருட்டு",
  "இளவுறை",
  "இடுகுறி",
  "இக்கன்",
  "இராவதி",
  "இயவுள்",
  "இக்கரை",
  "இருட்பூ",
  "இராவணி",
  "இகலன்",
  "இட்டம்",
  "இண்டர்",
  "இத்துரா",
  "இறுகால்",
  "இடைசூரி",
  "இருண்டி",
  "இடகலை",
  "இரியல்",
  "இராந்து",
  "இராவடி",
  "இருப்பு",
  "இழைக்கை",
  "இலேபம்",
  "இருப்பை",
  "இயலசை",
  "இட்டிமை",
  "இரேக்கு",
  "இற்றும்",
  "இலசுனி",
  "இசக்கி",
  "இலையான்",
  "இரேணுகை",
  "இளவல்",
  "இதவிய",
  "இன்னிசை",
  "இருதலை",
  "இறைச்சி",
  "இட்டன்",
  "இடியல்",
  "இலாஞ்சி",
  "இறவுள்",
  "இழுகுணி",
  "இலேகனி",
  "இயங்கு",
  "இழவோலை",
  "இத்தால்",
  "இருணம்",
  "இருகால்",
  "இழிப்பு",
  "இயறல்",
  "இம்பர்",
  "இலச்சை",
  "இப்படி",
  "இயற்பா",
  "இறைகூடை",
  "இறைஞ்சி",
  "இறைஞ்சு",
  "இனியர்",
  "இழுக்கு",
  "இழிசொல்",
  "இடுகாடு",
  "இராசாளி",
  "இடைவெளி",
  "இணைமோனை",
  "இறகர்",
  "இராசிகை",
  "இரந்தை",
  "இரவெரி",
  "இழிதிணை",
  "இமையார்",
  "இணைவன்",
  "இராயன்",
  "இழைதல்",
  "இருவில்",
  "இராகவி",
  "இடத்தை",
  "இடபம்",
  "இரணம்",
  "இடகண்",
  "இந்தியா",
  "இயசுரு",
  "இலீனம்",
  "இப்பியை",
  "இலாயம்",
  "இடாகினி",
  "இமலம்",
  "இடாப்பு",
  "இசவில்",
  "இந்திரா",
  "இந்திரி",
  "இளையன்",
  "இந்திரை",
  "இற்புலி",
  "இருட்சி",
  "இருவீடு",
  "இளவணி",
  "இடபன்",
  "இராயணி",
  "இயவம்",
  "இளைஞன்",
  "இனைதல்",
  "இழுப்பு",
  "இழிஞன்",
  "இலாசடை",
  "இலாசடி",
  "இராகம்",
  "இழுவல்",
  "இலந்தை",
  "இளிதல்",
  "இடைச்சி",
  "இல்லாளி",
  "இறுப்பு",
  "இல்லாள்",
  "இப்பர்",
  "இயவன்",
  "இலிகம்",
  "இறக்கை",
  "இல்லாமை",
  "இழவூழ்",
  "இருவேரி",
  "இராசாணி",
  "இடாயம்",
  "இரசம்",
  "இருணாள்",
  "இழிகடை",
  "இரவோன்",
  "இருவேல்",
  "இருவேலி",
  "இராதினி",
  "இலஞ்சி",
  "இழுங்கு",
  "இமவான்",
  "இரிஞ்சி",
  "இடலம்",
  "இதக்கை",
  "இங்ஙன்",
  "இடைநரை",
  "இட்டீறு",
  "இணுக்கு",
  "இளநாள்",
  "இங்கண்",
  "இரசனா",
  "இரசனி",
  "இழிகண்",
  "இரசனை",
  "இரச்சு",
  "இசுதாரு",
  "இரச்சை",
  "இலாட்சை",
  "இறும்பு",
  "இடுமோலி",
  "இராசனை",
  "இறும்பி",
  "இராசன்",
  "இயற்று",
  "இயற்றி",
  "இம்பல்",
  "இருளுவா",
  "இலேஞ்சி",
  "இறப்பை",
  "இசைமணி",
  "இறப்பு",
  "இராயர்",
  "இலாசம்",
  "இருவினை",
  "இரவேசு",
  "ஈக்குடி",
  "ஈண்டல்",
  "ஈசானன்",
  "ஈச்சுர",
  "ஈகுதல்",
  "ஈசுவர",
  "ஈர்வாள்",
  "ஈடகம்",
  "ஈராட்டி",
  "ஈடறவு",
  "ஈரிணம்",
  "ஈழுவன்",
  "ஈன்றாள்",
  "ஈங்கிசை",
  "ஈரவன்",
  "ஈர்ங்கை",
  "ஈடுபாடு",
  "ஈயோட்டி",
  "ஈறிலான்",
  "ஈற்றம்",
  "ஈர்தல்",
  "ஈன்றல்",
  "ஈசானம்",
  "ஈனாயம்",
  "ஈட்டம்",
  "ஈசுரலை",
  "ஈகையன்",
  "ஈசனாள்",
  "ஈங்கண்",
  "ஈருள்ளி",
  "ஈர்க்கு",
  "ஈர்கோலி",
  "ஈழவன்",
  "ஈனவன்",
  "ஈழநாடு",
  "ஈயோப்பி",
  "ஈனுதல்",
  "ஈரொட்டு",
  "ஈற்றசை",
  "ஈப்புலி",
  "ஈசுவரி",
  "ஈர்ப்பு",
  "ஈர்வலி",
  "ஈரணம்",
  "ஈங்கன்",
  "ஈனனம்",
  "ஈன்றார்",
  "ஈங்கம்",
  "ஈரிப்பு",
  "ஈன்றோன்",
  "ஈடணம்",
  "ஈசுரன்",
  "ஈர்வாரி",
  "ஈமவாரி",
  "ஈப்பிணி",
  "உடுக்கை",
  "உறாவரை",
  "உட்படி",
  "உடலம்",
  "உடுக்கு",
  "உடையான்",
  "உறுத்தை",
  "உதிரன்",
  "உபலம்",
  "உலிமணி",
  "உருவிலி",
  "உண்பலி",
  "உபநிதி",
  "உள்ளோசை",
  "உரூபம்",
  "உலூகம்",
  "உறட்டை",
  "உறட்டு",
  "உள்வரி",
  "உச்சம்",
  "உரோதனி",
  "உருமேறு",
  "உவமன்",
  "உரோதனை",
  "உண்கண்",
  "உய்தடி",
  "உடனாளி",
  "உடும்பு",
  "உவப்பு",
  "உக்கிடு",
  "உபபதி",
  "உற்காதா",
  "உதிரல்",
  "உபரிகை",
  "உளுந்து",
  "உறழ்வு",
  "உரைசல்",
  "உதப்பி",
  "உத்தரி",
  "உக்காரி",
  "உமாபதி",
  "உள்ளூர்",
  "உக்காரை",
  "உவமம்",
  "உதானன்",
  "உந்துரு",
  "உள்ளான்",
  "உதிரம்",
  "உலாங்கு",
  "உதவல்",
  "உயவல்",
  "உபசாரி",
  "உப்பிலி",
  "உத்தமை",
  "உத்தமி",
  "உத்தம்",
  "உறப்பு",
  "உரணம்",
  "உறண்டை",
  "உப்பேறி",
  "உபகதை",
  "உதைகாலி",
  "உளவன்",
  "உம்பர்",
  "உதைகால்",
  "உருட்சி",
  "உடுப்பு",
  "உட்கண்",
  "உடுப்பை",
  "உதீசம்",
  "உம்பல்",
  "உருட்டு",
  "உகைப்பு",
  "உடந்தை",
  "உற்றது",
  "உறைபதி",
  "உகுதல்",
  "உகரம்",
  "உதிதன்",
  "உளப்பு",
  "உடைவேல்",
  "உறைநாழி",
  "உம்பன்",
  "உடைகொல்",
  "உள்ளாடை",
  "உழவோன்",
  "உட்கரு",
  "உரோதம்",
  "உதக்கு",
  "உறைப்பு",
  "உரைநடை",
  "உகாரம்",
  "உரியோன்",
  "உலகுரை",
  "உளங்கு",
  "உருண்டை",
  "உடனிலை",
  "உதனம்",
  "உரியசை",
  "உளறல்",
  "உரியாள்",
  "உஞற்று",
  "உலைதல்",
  "உத்திரி",
  "உபவாசி",
  "உரபிடி",
  "உலைமூடி",
  "உந்தூழ்",
  "உரைதல்",
  "உட்பூசை",
  "உகைதல்",
  "உளைப்பு",
  "உழுவல்",
  "உபாசகை",
  "உரலாணி",
  "உட்பட",
  "உருப்பு",
  "உலுவம்",
  "உபயம்",
  "உணைதல்",
  "உங்ஙன்",
  "உழுதல்",
  "உரவோன்",
  "உக்கிரை",
  "உருவசி",
  "உரவன்",
  "உலைநீர்",
  "உய்தல்",
  "உறுமால்",
  "உசாதல்",
  "உவாத்து",
  "உள்ளுறை",
  "உறுமாலை",
  "உலபம்",
  "உவாத்தி",
  "உபயர்",
  "உங்கண்",
  "உழுதூண்",
  "உழுவம்",
  "உணர்வு",
  "உண்ணீர்",
  "உரும்பு",
  "உழவடை",
  "உபோதம்",
  "உரையாணி",
  "உருக்கு",
  "உழைதல்",
  "உறுகண்",
  "உறக்கு",
  "உப்பல்",
  "உபாஞ்சு",
  "உரவம்",
  "உழுநர்",
  "உடைப்பு",
  "உப்பால்",
  "உயிரவை",
  "உவிதல்",
  "உரவர்",
  "உப்பர்",
  "உயராடு",
  "உடசம்",
  "உடைதல்",
  "உழைமண்",
  "உதிப்பு",
  "உசிரம்",
  "உரையேடு",
  "உலகம்",
  "உலோமம்",
  "உருவறை",
  "உபாசனை",
  "உரைகோள்",
  "உலுப்பை",
  "உருவல்",
  "உலகர்",
  "உழவன்",
  "உண்ணம்",
  "உன்முகி",
  "உக்களி",
  "உலோபன்",
  "உள்வாய்",
  "உபாயம்",
  "உறுதுணை",
  "உடையாளி",
  "உடையாள்",
  "உரைநூல்",
  "உக்கல்",
  "உக்கலை",
  "உலோலம்",
  "உழன்றி",
  "உவணன்",
  "உகுணம்",
  "உபரதி",
  "உறிதல்",
  "உள்ளுடை",
  "உடுவம்",
  "உடையார்",
  "உங்குணி",
  "உப்பாணி",
  "உடைநாண்",
  "உறையல்",
  "உரியன்",
  "உக்கம்",
  "உலாத்து",
  "உள்மனை",
  "உருவரை",
  "உகளம்",
  "உசனன்",
  "உழற்சி",
  "உருவம்",
  "உவணம்",
  "உழுபடை",
  "உலோகம்",
  "உரைப்பு",
  "உரோகதி",
  "உண்டாக",
  "உசிதன்",
  "உரோபம்",
  "உறந்தை",
  "உரலடி",
  "உடற்சி",
  "உழற்றி",
  "உழற்று",
  "உழப்பு",
  "உட்செல்",
  "உமணன்",
  "உரனர்",
  "உறுகோள்",
  "உள்ளன்",
  "உத்தானி",
  "உப்புமா",
  "உசிதம்",
  "உதகம்",
  "உள்ளடை",
  "உதாசனி",
  "உள்ளடி",
  "உபாத்தி",
  "உலோபம்",
  "உரியள்",
  "உமாதசி",
  "உறுசுவை",
  "உபநதி",
  "உரகாரி",
  "உன்னம்",
  "உலகோர்",
  "உன்மேதை",
  "உரோமம்",
  "உறவன்",
  "உகினம்",
  "உள்ளது",
  "உமியல்",
  "உழுவான்",
  "உறையுள்",
  "உச்சாணி",
  "உன்மணி",
  "உழுந்து",
  "உபமிதி",
  "உழக்கு",
  "உதயன்",
  "உரித்து",
  "உளுக்கு",
  "உரைக்கை",
  "உருமணி",
  "உபயோகி",
  "உடைமணி",
  "உரப்பு",
  "உரபடி",
  "உசீரம்",
  "உரண்டை",
  "உதயம்",
  "உருவொளி",
  "உமேசன்",
  "உசனார்",
  "உன்னல்",
  "உரோகம்",
  "உலூதம்",
  "உற்றுழி",
  "உமையாள்",
  "உன்மனை",
  "உதாரதை",
  "உள்ளீடு",
  "உலோட்டி",
  "உன்மனி",
  "உள்ளம்",
  "உள்ளமை",
  "உடுமடி",
  "உணக்கு",
  "உடைஞாண்",
  "உபவம்",
  "உழமண்",
  "உலண்டு",
  "உபதாது",
  "உலப்பு",
  "உட்புரை",
  "உச்சூடை",
  "உருவாணி",
  "உள்ளல்",
  "உதசம்",
  "உற்கரி",
  "உட்டுளை",
  "உற்கம்",
  "உடனுறை",
  "உள்ளறை",
  "உமிழ்வு",
  "உறைதல்",
  "உணராமை",
  "உவன்றி",
  "உற்றோர்",
  "உறியடி",
  "உரோசம்",
  "உறும்பு",
  "உட்கார்",
  "உடப்பு",
  "உதாரம்",
  "உல்லாடி",
  "உணரார்",
  "உதியன்",
  "உடனடி",
  "உனினம்",
  "உழையர்",
  "உளியம்",
  "உழுத்து",
  "உதாரன்",
  "உளர்வு",
  "உலங்கு",
  "உறுக்கு",
  "உடுபதி",
  "உலோச்சு",
  "உடைவாள்",
  "உவியல்",
  "உபதேசி",
  "உய்யல்",
  "உற்றார்",
  "உழைப்பு",
  "உரகன்",
  "உறுவன்",
  "உரைகல்",
  "உதைப்பு",
  "உரையசை",
  "உபசாகை",
  "உண்மடை",
  "உடுபன்",
  "உலக்கை",
  "உரோசனை",
  "உபேட்சை",
  "உருமம்",
  "உரோசனி",
  "உறவாளி",
  "உசகம்",
  "உலோசம்",
  "உடுபம்",
  "உரகம்",
  "உறிக்கா",
  "உறுநன்",
  "உடக்கு",
  "உதணம்",
  "உறுவது",
  "உசிலம்",
  "உட்கோள்",
  "உரகர்",
  "உழையோர்",
  "உண்டிகை",
  "உடம்பை",
  "உடம்பு",
  "உளைதல்",
  "உள்ளால்",
  "உள்ளாலை",
  "உரையல்",
  "உலைசல்",
  "உள்ளாள்",
  "உட்கிடை",
  "உள்ளார்",
  "உலைப்பு",
  "உவட்டு",
  "உவட்டி",
  "உனகன்",
  "உலவம்",
  "உல்லரி",
  "உதரம்",
  "உயரம்",
  "உரோடம்",
  "உதாவணி",
  "உமிதல்",
  "உஞ்சம்",
  "உறைமோர்",
  "உவட்சி",
  "உறுப்பு",
  "உறுப்பா",
  "உகப்பு",
  "உயர்வு",
  "உடங்கு",
  "உட்பகை",
  "உரோகிணி",
  "உந்தல்",
  "உழுக்கு",
  "உகிரம்",
  "உஞ்சல்",
  "உபானம்",
  "உண்மாசு",
  "உல்லம்",
  "உருமேனி",
  "உழத்தி",
  "உறுதல்",
  "உபாம்சு",
  "உற்றான்",
  "உரிதல்",
  "உறுவல்",
  "உறுவலி",
  "உள்ளிடை",
  "ஊக்கல்",
  "ஊங்கண்",
  "ஊழித்தீ",
  "ஊர்வாரி",
  "ஊடரம்",
  "ஊட்டுணை",
  "ஊறுகறி",
  "ஊட்டல்",
  "ஊணுதல்",
  "ஊதியம்",
  "ஊமையடி",
  "ஊற்றாணி",
  "ஊறுதல்",
  "ஊக்கம்",
  "ஊசிவெடி",
  "ஊமைச்சி",
  "ஊனொட்டி",
  "ஊழியான்",
  "ஊமத்தை",
  "ஊமத்து",
  "ஊக்குணா",
  "ஊராளன்",
  "ஊறுகோள்",
  "ஊருகால்",
  "ஊழியம்",
  "ஊலுகம்",
  "ஊமையன்",
  "ஊழ்தல்",
  "ஊறுபுண்",
  "ஊகாரம்",
  "ஊரவர்",
  "ஊர்கோள்",
  "ஊதுகணை",
  "ஊழ்முறை",
  "ஊறுபாடு",
  "ஊர்கொலை",
  "ஊரறுகு",
  "ஊமைமணி",
  "ஊனாயம்",
  "ஊடையம்",
  "ஊஞ்சல்",
  "ஊனவன்",
  "ஊச்சில்",
  "ஊழ்விதி",
  "ஊய்தல்",
  "ஊகனம்",
  "ஊசரம்",
  "ஊராட்சி",
  "ஊமாண்டி",
  "ஊழ்விணை",
  "ஊழியன்",
  "ஊற்றால்",
  "ஊர்வசி",
  "ஊடுதல்",
  "ஊருசன்",
  "ஊழகம்",
  "ஊழ்பாடு",
  "ஊடகம்",
  "ஊரோசம்",
  "ஊகாமுள்",
  "ஊசிவேர்",
  "ஊர்வரை",
  "ஊசுதல்",
  "ஊர்ணம்",
  "ஊடணம்",
  "ஊற்றம்",
  "ஊழிநோய்",
  "ஊர்தல்",
  "ஊண்சாலை",
  "ஊதுதல்",
  "ஊமச்சி",
  "ஊத்தம்",
  "ஊட்டம்",
  "ஊராண்மை",
  "ஊறுகாய்",
  "ஊர்வன",
  "ஊதாம்பை",
  "ஊகடன்",
  "எவரும்",
  "எண்ணம்",
  "எரிதல்",
  "எஞ்ஞம்",
  "எண்ணர்",
  "எழுவாய்",
  "எழுமீன்",
  "எவ்வாறு",
  "எளிஞர்",
  "எண்ணல்",
  "எரிசாலை",
  "எகினன்",
  "எரிகறி",
  "எலுவல்",
  "எறிதல்",
  "எஞ்சணி",
  "எதிரிலி",
  "எங்கண்",
  "எவ்வது",
  "எழுப்பு",
  "எம்மான்",
  "எயிறிலி",
  "எண்ணவி",
  "எழுச்சி",
  "எலியன்",
  "எம்மோன்",
  "எழுபோது",
  "எழுவான்",
  "எரிபுழு",
  "எகினம்",
  "எரியாடி",
  "எலியால்",
  "எருத்து",
  "எலிவளை",
  "எறிகாலி",
  "எறிகால்",
  "எலுவன்",
  "என்றும்",
  "எவ்வம்",
  "எய்ப்பு",
  "எற்சோறு",
  "எடுப்பு",
  "எழுதம்",
  "எருந்தி",
  "எருந்து",
  "எயினன்",
  "எகரம்",
  "எத்துணை",
  "எமதூதி",
  "எனைவன்",
  "எளிதல்",
  "எசமானி",
  "எங்கும்",
  "எசமான்",
  "எளியன்",
  "எதிரொலி",
  "எட்டுணை",
  "எழுதல்",
  "எகுன்று",
  "எரிகாலி",
  "எழுதீவு",
  "எவ்வெவை",
  "எகடம்",
  "எரியல்",
  "எத்தன்",
  "எத்தனை",
  "எண்பது",
  "என்பிலி",
  "எரியோன்",
  "எலும்பி",
  "எலும்பு",
  "எயிற்றி",
  "எண்டோளி",
  "எறியல்",
  "எட்டன்",
  "எண்டிசை",
  "எச்சம்",
  "எக்கர்",
  "எழிற்கை",
  "எண்மர்",
  "எச்சன்",
  "எச்சில்",
  "எடுபாடு",
  "எல்லரி",
  "எக்கம்",
  "எலியளை",
  "எழாநிலை",
  "எய்யாமை",
  "எல்லம்",
  "எழுத்து",
  "எழுமலை",
  "எறும்பி",
  "எட்சிணி",
  "எலவளி",
  "என்பான்",
  "எக்கல்",
  "எறுழம்",
  "எவையும்",
  "எட்டர்",
  "எதிர்மை",
  "எட்கோது",
  "எறிபடை",
  "எனினும்",
  "எறிப்பு",
  "எரிமணி",
  "எனைத்து",
  "எண்ணூல்",
  "எட்டம்",
  "என்னும்",
  "எடுத்து",
  "எரிநகை",
  "எட்கசி",
  "எம்மர்",
  "எறிமணி",
  "எண்ணெய்",
  "எள்ளுரை",
  "எதிராளி",
  "எத்தும்",
  "என்றூழ்",
  "என்னன்",
  "எரிப்பு",
  "என்றூழி",
  "எரிப்பூ",
  "என்னர்",
  "எம்மனை",
  "எனையன்",
  "எப்போது",
  "என்றிய",
  "எதிர்வு",
  "எழுமுடி",
  "எல்லாம்",
  "எடுகூலி",
  "எடுகூறு",
  "எல்லார்",
  "எறியால்",
  "எல்லோன்",
  "எஃகம்",
  "எரிமலை",
  "எருக்கு",
  "எய்தல்",
  "எவ்வேழு",
  "என்மர்",
  "எதிர்வ",
  "எதிரேறு",
  "எதிரிடை",
  "எட்கிடை",
  "எற்பாடு",
  "எண்ணிலி",
  "எற்றம்",
  "எள்ளிடை",
  "எமரன்",
  "எரிகாசு",
  "எஞ்சாமை",
  "எங்ஙனே",
  "எங்ஙன்",
  "எண்சுவை",
  "எம்முன்",
  "எலியோடி",
  "எந்திரி",
  "எரிமுகி",
  "எப்படி",
  "எள்ளல்",
  "எழிலிய",
  "எண்கோவை",
  "எள்ளோரை",
  "எடுபிடி",
  "எழுமான்",
  "எம்பர்",
  "ஏசறவு",
  "ஏடகம்",
  "ஏகாங்கி",
  "ஏழ்பரி",
  "ஏகதேசி",
  "ஏரடம்",
  "ஏடுகம்",
  "ஏசுதல்",
  "ஏம்பல்",
  "ஏதிலாள்",
  "ஏறுகடை",
  "ஏழகம்",
  "ஏற்றல்",
  "ஏற்கவே",
  "ஏகாகம்",
  "ஏர்நாழி",
  "ஏற்பாடு",
  "ஏந்தல்",
  "ஏவலன்",
  "ஏதின்மை",
  "ஏந்திசை",
  "ஏப்பாடு",
  "ஏங்கல்",
  "ஏமவதி",
  "ஏரண்டி",
  "ஏற்றம்",
  "ஏகதார்",
  "ஏகாவலி",
  "ஏதிலன்",
  "ஏகாட்சி",
  "ஏககுரு",
  "ஏறுதல்",
  "ஏறுபடி",
  "ஏதனம்",
  "ஏழுமலை",
  "ஏலபிலி",
  "ஏவரும்",
  "ஏறுசெடி",
  "ஏதிலார்",
  "ஏலுதல்",
  "ஏவதும்",
  "ஏறுதுறை",
  "ஏற்றணை",
  "ஏலரிசி",
  "ஏணகம்",
  "ஏராளர்",
  "ஏய்ப்பு",
  "ஏற்றது",
  "ஏகவெளி",
  "ஏறுகோள்",
  "ஏகவாணை",
  "ஏற்றார்",
  "ஏறாவேணி",
  "ஏண்கோண்",
  "ஏறுமாறு",
  "ஏறுவாசி",
  "ஏழ்புழை",
  "ஏறாளர்",
  "ஏப்புழை",
  "ஏகாடம்",
  "ஏதலன்",
  "ஏய்ப்ப",
  "ஏக்கம்",
  "ஏகாயம்",
  "ஏதிலர்",
  "ஏமாப்பு",
  "ஏகீயன்",
  "ஏராளம்",
  "ஏத்திரி",
  "ஏகாதசி",
  "ஏக்கர்",
  "ஏகுதல்",
  "ஏளனம்",
  "ஏர்ப்ப",
  "ஏற்போன்",
  "ஏடகணி",
  "ஏரகம்",
  "ஏலாதான்",
  "ஏகராசி",
  "ஏமத்தி",
  "ஏறுவால்",
  "ஏற்புழி",
  "ஏத்தாளி",
  "ஏராண்மை",
  "ஏச்சுரை",
  "ஏவலாள்",
  "ஏற்றூண்",
  "ஏச்சோறு",
  "ஏதண்டை",
  "ஏகாசம்",
  "ஏவுதல்",
  "ஏர்ப்பு",
  "ஏரணம்",
  "ஏந்திழை",
  "ஏருந்து",
  "ஏய்தல்",
  "ஏகவேணி",
  "ஏப்பம்",
  "ஏலாப்பு",
  "ஏந்திலை",
  "ஏலேலம்",
  "ஏழொத்து",
  "ஏளிதம்",
  "ஐக்கம்",
  "ஐவிரலி",
  "ஐயநாடி",
  "ஐயஞ்சு",
  "ஐவிரல்",
  "ஐங்கனி",
  "ஐந்துபா",
  "ஐந்நூறு",
  "ஐந்தரு",
  "ஐப்பசி",
  "ஐம்படை",
  "ஐகாரம்",
  "ஐகிகம்",
  "ஐந்திணை",
  "ஐமவதி",
  "ஐயுறவு",
  "ஐவனம்",
  "ஐம்பொறி",
  "ஐங்கணை",
  "ஐம்மீன்",
  "ஐயிதழ்",
  "ஐங்கதி",
  "ஐம்பால்",
  "ஐயனார்",
  "ஐம்பொன்",
  "ஐந்தார்",
  "ஐஞ்ஞூறு",
  "ஐதிகம்",
  "ஐம்முகி",
  "ஐம்பது",
  "ஐந்திரி",
  "ஐயவினா",
  "ஐயபூமி",
  "ஐந்தொகை",
  "ஐயெனல்",
  "ஐசிலம்",
  "ஐவைந்து",
  "ஒகரம்",
  "ஒற்றன்",
  "ஒரோவழி",
  "ஒழுங்கை",
  "ஒருவர்",
  "ஒட்பம்",
  "ஒட்டகை",
  "ஒலிப்பு",
  "ஒன்றும்",
  "ஒலியல்",
  "ஒருங்கு",
  "ஒழுங்கு",
  "ஒசிதல்",
  "ஒருங்கே",
  "ஒரானொரு",
  "ஒருதலை",
  "ஒப்புமை",
  "ஒறுப்பு",
  "ஒப்பான்",
  "ஒடுப்பை",
  "ஒடுங்க",
  "ஒடிசல்",
  "ஒற்கம்",
  "ஒல்லாமை",
  "ஒற்றுமை",
  "ஒட்டல்",
  "ஒருசார்",
  "ஒல்வழி",
  "ஒப்படை",
  "ஒள்ளொளி",
  "ஒப்படி",
  "ஒருவேளை",
  "ஒல்லார்",
  "ஒறுவாய்",
  "ஒருகுறி",
  "ஒட்டம்",
  "ஒய்யென",
  "ஒச்சம்",
  "ஒக்கம்",
  "ஒட்டார்",
  "ஒருசேர",
  "ஒப்பணி",
  "ஒய்தல்",
  "ஒற்றாள்",
  "ஒட்டர்",
  "ஒருபது",
  "ஒருவாமை",
  "ஒப்பன்",
  "ஒருபடி",
  "ஒப்பனை",
  "ஒருமொழி",
  "ஒளிர்வு",
  "ஒத்தது",
  "ஒருபான்",
  "ஒடுங்கி",
  "ஒக்கலை",
  "ஒக்கல்",
  "ஒட்டன்",
  "ஒல்லல்",
  "ஒசிதம்",
  "ஒளிப்பு",
  "ஒடிசில்",
  "ஒருபோது",
  "ஒன்றல்",
  "ஒருசொல்",
  "ஒலுங்கு",
  "ஒன்பது",
  "ஒளிதல்",
  "ஒருகால்",
  "ஒராங்கு",
  "ஒத்திகை",
  "ஒப்பம்",
  "ஒருத்தி",
  "ஒன்னார்",
  "ஒருத்து",
  "ஒடியல்",
  "ஒழிப்பு",
  "ஒத்தன்",
  "ஒல்வது",
  "ஒழுகல்",
  "ஒட்டணி",
  "ஒல்லாடி",
  "ஒவ்வோன்",
  "ஒழிதல்",
  "ஒழுக்கு",
  "ஒழுக்கை",
  "ஒழுகிசை",
  "ஒள்ளிமை",
  "ஒன்றாக",
  "ஒவ்வாமை",
  "ஒசியல்",
  "ஒத்தாசை",
  "ஒருபோகு",
  "ஒன்றுகை",
  "ஒருக்க",
  "ஒடிதல்",
  "ஒன்றாய்",
  "ஒருமாரை",
  "ஒருவன்",
  "ஒளியர்",
  "ஒளியோன்",
  "ஒருபிடி",
  "ஒழியிசை",
  "ஒன்றார்",
  "ஒடுக்கு",
  "ஒழிகடை",
  "ஒத்திசை",
  "ஒருதாரை",
  "ஒன்றாமை",
  "ஒப்பாரி",
  "ஒத்தல்",
  "ஒருகிடை",
  "ஒம்மல்",
  "ஒருசிறை",
  "ஒட்டடை",
  "ஒருதனி",
  "ஒல்லென",
  "ஒலிதல்",
  "ஒய்யல்",
  "ஒக்கோலை",
  "ஒலியன்",
  "ஒன்பான்",
  "ஒதுக்கு",
  "ஒருபுடை",
  "ஒண்டன்",
  "ஒருகுடி",
  "ஒறுவினை",
  "ஓங்கல்",
  "ஓட்டடை",
  "ஓதுவான்",
  "ஓரானொரு",
  "ஓவியன்",
  "ஓடுவாயு",
  "ஓதுதல்",
  "ஓச்சல்",
  "ஓட்டம்",
  "ஓக்கம்",
  "ஓரன்மை",
  "ஓதிமம்",
  "ஓர்தல்",
  "ஓகாரம்",
  "ஓலைமுறி",
  "ஓர்ப்பு",
  "ஓத்திரி",
  "ஓராங்கு",
  "ஓலைவாளை",
  "ஓய்தல்",
  "ஓர்சல்",
  "ஓலுதல்",
  "ஓமியம்",
  "ஓதிமன்",
  "ஓமாலிகை",
  "ஓதுவார்",
  "ஓர்வம்",
  "ஓதைவாரி",
  "ஓரொட்டு",
  "ஓம்படை",
  "ஓராயம்",
  "ஓரொன்று",
  "ஓர்ச்சி",
  "ஓரற்று",
  "ஓரோவழி",
  "ஓசரம்",
  "ஓடுகால்",
  "ஓசுநன்",
  "ஓடுகாலி",
  "ஓடுதல்",
  "ஓவெனல்",
  "ஓடியம்",
  "ஓச்சன்",
  "ஓகோதனி",
  "ஓர்மம்",
  "ஓமசாலை",
  "ஓவுதல்",
  "ஓவியம்",
  "ஓதன்மை",
  "ஓதனம்",
  "ஓகணம்",
  "ஓங்கில்",
  "ஓரீற்றா",
  "ஓட்டன்",
  "ஓச்சம்",
  "ஓங்காரி",
  "ஓலைப்பூ",
  "ஓகுலம்",
  "ஔகாரம்",
  "ஔடதம்",
  "ஔசீரம்",
  "ஔவியம்",
  "ஔடணம்",
  "ஔபாசனை",
  "ஔசனம்",
  "ஔவுதல்",
  "ஔரசன்",
  "ஔரதன்",
  "ஔடவம்",
  "ஔத்திரி",
  "கஞ்சிகை",
  "கத்துரு",
  "கதலிகை",
  "கடுமுள்",
  "கருப்பை",
  "கல்லன்",
  "கருப்பு",
  "கருநாள்",
  "கரியாள்",
  "கண்பீலி",
  "கட்டிமை",
  "கருநார்",
  "கறுவம்",
  "கணிச்சி",
  "கரிதன்",
  "கம்புள்",
  "கண்படை",
  "கலகம்",
  "கல்லணை",
  "கழுத்து",
  "கண்டீரை",
  "கண்டீர்",
  "கருநாபி",
  "கன்மலை",
  "கன்மலி",
  "கனவல்",
  "கயவாளி",
  "கலயம்",
  "கழுமுள்",
  "கவட்டை",
  "கரம்பு",
  "கடைசாரி",
  "கரம்பை",
  "கடைசார்",
  "கஞ்சாரி",
  "கரியார்",
  "கர்த்தா",
  "கயவாய்",
  "கருநாய்",
  "கற்கோவை",
  "கட்டில்",
  "கடுமழை",
  "கன்மழை",
  "கடுவெளி",
  "கவிஞன்",
  "கசமாது",
  "கந்தல்",
  "கசுமாலி",
  "கவிமாலை",
  "கழிதல்",
  "கந்தழி",
  "கரைதுறை",
  "கவடம்",
  "கவர்வு",
  "கபடம்",
  "கந்தர்",
  "கதிப்பு",
  "கவியம்",
  "கற்சூலை",
  "கவரம்",
  "கருநெறி",
  "கரோடம்",
  "கலினம்",
  "கடுகம்",
  "கசாப்பு",
  "கரீரம்",
  "கல்லுளி",
  "கழைநெல்",
  "கபடன்",
  "கலிப்பா",
  "கலிப்பு",
  "கல்லளை",
  "கடமுனி",
  "கதழ்வு",
  "கல்லல்",
  "களபம்",
  "கல்லறை",
  "கடைமீன்",
  "கதுக்கு",
  "கந்தன்",
  "கரையல்",
  "கதகம்",
  "கல்லம்",
  "கந்தம்",
  "ககேசன்",
  "கணத்தி",
  "கடைநாள்",
  "கருளன்",
  "கரிதல்",
  "கடைப்பூ",
  "கறுவல்",
  "கசிதம்",
  "கந்துள்",
  "கட்டல்",
  "கவீனம்",
  "கரைப்பு",
  "கள்ளம்",
  "கட்டர்",
  "கழுவாய்",
  "கடையூறு",
  "கண்டில்",
  "கண்கூலி",
  "கட்டான்",
  "கரையோலை",
  "கட்டம்",
  "கவைமுள்",
  "கணப்பு",
  "கவிப்பு",
  "கவிகம்",
  "கடைகால்",
  "கபோலம்",
  "கடூரம்",
  "கள்ளன்",
  "கனிதல்",
  "கவர்பு",
  "கயத்தி",
  "கரிக்கை",
  "கடாவல்",
  "கரிசலை",
  "களவாணி",
  "கழற்று",
  "கழற்றி",
  "கரிசல்",
  "கரைமடி",
  "கசிதல்",
  "கடிக்கை",
  "கயமுனி",
  "கடையூழி",
  "கடுங்கை",
  "கருங்கை",
  "கட்டளை",
  "கரிசம்",
  "கட்டாரி",
  "கவுதம்",
  "கலாவம்",
  "கருமுகை",
  "களைதல்",
  "கவுணம்",
  "கடனுரை",
  "கதைதல்",
  "கணவம்",
  "கடுப்பை",
  "கடுப்பு",
  "கழுவெளி",
  "கன்மம்",
  "கம்மால்",
  "கம்மாலை",
  "கரபம்",
  "கழுவாணி",
  "கணவன்",
  "கசபம்",
  "கரண்டை",
  "கடாதல்",
  "கரண்டி",
  "கழுநீர்",
  "கலைவாணி",
  "கற்கவி",
  "கடைகேடு",
  "கடுமீன்",
  "கனமழை",
  "கவனன்",
  "கடப்பு",
  "கர்வம்",
  "கட்டாணி",
  "கவுதாரி",
  "கதிகால்",
  "களவாளி",
  "கணவர்",
  "கட்டெலி",
  "கற்கலை",
  "கவறல்",
  "கட்டாடி",
  "கண்விழி",
  "கற்கம்",
  "கலப்பை",
  "களிம்பு",
  "கள்ளல்",
  "கற்கரி",
  "கலப்பு",
  "கலவர்",
  "கலவரை",
  "கலியம்",
  "கரையீடு",
  "கலதம்",
  "கனற்பு",
  "கம்பிலி",
  "கழற்சி",
  "கண்டது",
  "கலவல்",
  "கற்குரு",
  "கண்ணாணி",
  "கண்ணாணை",
  "களாஞ்சி",
  "கடமான்",
  "கலவறை",
  "களகள",
  "கண்ணெறி",
  "கருமாதி",
  "கங்காளி",
  "கலியன்",
  "கரைதல்",
  "கரைவழி",
  "கலவம்",
  "கண்டனை",
  "கலுவம்",
  "கண்ணாடி",
  "கண்டன்",
  "கரைவலை",
  "களைப்பு",
  "கடலோடி",
  "கவாளம்",
  "கற்சிலை",
  "கவந்தி",
  "கணிப்பு",
  "கண்டூயை",
  "கமனம்",
  "கடமணை",
  "கடிகம்",
  "கணனம்",
  "கண்டூரை",
  "கருவூர்",
  "கடிகோல்",
  "கடியடு",
  "கலிகம்",
  "கரடிகை",
  "கழிசடை",
  "கற்பாடு",
  "கற்சிறை",
  "கடையுவா",
  "கரவாள்",
  "கடலோசை",
  "கணுக்கை",
  "கணாதன்",
  "கயவன்",
  "கடுவரை",
  "கலாதன்",
  "கண்ணாள்",
  "கச்சேரி",
  "கடியன்",
  "கழுவேறி",
  "கண்ணிகை",
  "கவீரம்",
  "கண்ணாறு",
  "ககுத்து",
  "கண்ணேணி",
  "கரிசனை",
  "கறையோர்",
  "கயிப்பு",
  "கடுவன்",
  "கர்ணம்",
  "கண்ணார்",
  "கமலம்",
  "கடியல்",
  "கணையாழி",
  "கலிச்சி",
  "கலாவதி",
  "கற்பாறை",
  "கவழிகை",
  "கற்பாழி",
  "கங்கில்",
  "கடியறை",
  "கண்டம்",
  "கற்பாள்",
  "கடுவழி",
  "கண்டர்",
  "கண்டரை",
  "கமலன்",
  "கவுசனை",
  "கராடம்",
  "கடுவல்",
  "கண்டறை",
  "கடைச்சி",
  "கசையடி",
  "கடையுணி",
  "கதவம்",
  "கண்டல்",
  "கடுவளி",
  "கல்வீடு",
  "கடகன்",
  "ககனம்",
  "கடிவாய்",
  "கந்திரி",
  "கடுப்ப",
  "கக்குசு",
  "கல்யாணி",
  "கச்சல்",
  "கடையுற",
  "களப்பு",
  "கன்னான்",
  "கத்தூரி",
  "கம்மல்",
  "கல்கம்",
  "களிந்தை",
  "கலுழம்",
  "கடிவாள்",
  "கல்லேறு",
  "கச்சம்",
  "கலவோடு",
  "கனருசி",
  "கடனாளி",
  "கண்புரை",
  "கழிப்பு",
  "கச்சால்",
  "கச்சாலை",
  "கம்மம்",
  "கசடன்",
  "களமீடு",
  "கணபதி",
  "கட்செவி",
  "கலந்தை",
  "கன்னார்",
  "கவாட்டி",
  "கலுழன்",
  "கழாயர்",
  "கல்லால்",
  "கட்கண்",
  "கபாலன்",
  "கழாயம்",
  "கல்லாரை",
  "கருமேனி",
  "கல்லார்",
  "கதிரம்",
  "கனைதல்",
  "கட்கிலி",
  "கரடம்",
  "கன்னிகை",
  "கழுநர்",
  "கணபன்",
  "கவிதல்",
  "கபாலம்",
  "கடகம்",
  "கபிதம்",
  "கரத்தை",
  "கல்மழை",
  "கயப்பு",
  "கயப்பூ",
  "கனற்சி",
  "கருடர்",
  "கடிமனை",
  "கல்லான்",
  "கட்கம்",
  "கவ்வம்",
  "கடைசோரி",
  "கணிகன்",
  "கறையடி",
  "கவயமா",
  "கடயம்",
  "கவாட்சி",
  "கல்வேலை",
  "கற்பாசி",
  "கரிவாளை",
  "கண்டகி",
  "கங்காணி",
  "கயிரம்",
  "கழுதாழி",
  "கழாஅல்",
  "கல்லாமை",
  "கந்தேறு",
  "கவண்டி",
  "கவண்டு",
  "கணிகம்",
  "கவுந்தி",
  "கலமர்",
  "கடந்தை",
  "கறுப்பு",
  "கண்டூதி",
  "கருணிகை",
  "கறுப்பி",
  "கடைநன்",
  "கச்சான்",
  "கழஞ்சு",
  "கருடன்",
  "கருமான்",
  "கனிப்பு",
  "கவயல்",
  "கலகல",
  "கல்லெறி",
  "கல்லாடை",
  "ககபதி",
  "கலனிலி",
  "கடுமான்",
  "கவயம்",
  "கடையீடு",
  "கவையடி",
  "கரவாதி",
  "கண்சாடை",
  "கதிக்கை",
  "கனபாடி",
  "கலாபதி",
  "கலையோன்",
  "கண்மணி",
  "கரவல்",
  "கருநோய்",
  "கவசம்",
  "கல்மடி",
  "களியம்",
  "கன்னிமை",
  "கதலம்",
  "கந்தாலி",
  "கதாவணி",
  "கணிதம்",
  "கடுந்தி",
  "களியன்",
  "கலைஞன்",
  "கதிரோன்",
  "கலிதம்",
  "கஞ்சனை",
  "கஞ்சன்",
  "கந்திகை",
  "கவழம்",
  "கலுங்கு",
  "கடக்கை",
  "கனிட்டை",
  "கணையம்",
  "கழங்கு",
  "கற்கிடை",
  "கடம்பி",
  "கசாயம்",
  "கண்மடை",
  "கடம்பு",
  "கடம்பை",
  "கவாடம்",
  "கரைசல்",
  "கனறல்",
  "கடோரன்",
  "கழுவுணி",
  "கடையர்",
  "களமம்",
  "கடிதல்",
  "கஞ்சல்",
  "களாவம்",
  "கடையம்",
  "கல்சிலை",
  "களமர்",
  "கதைகாவி",
  "களைவாரி",
  "கடைஞன்",
  "கஞறம்",
  "கஞ்சரி",
  "கவணம்",
  "கச்சடா",
  "கந்தாடை",
  "கடிதம்",
  "கருதார்",
  "கட்டோர்",
  "கஞ்சம்",
  "கவிசனை",
  "கச்சன்",
  "கனவான்",
  "கடவுள்",
  "கராமம்",
  "கச்சுரி",
  "கலாபம்",
  "கச்சபீ",
  "கருகல்",
  "கருவிழி",
  "கடனாய்",
  "கடோரம்",
  "கருவிளை",
  "கவற்சி",
  "கண்ணீர்",
  "ககோளம்",
  "களாவதி",
  "கலசம்",
  "கவுமாரி",
  "கலாபனை",
  "கச்சுரை",
  "கலீரம்",
  "கடையல்",
  "கங்குல்",
  "கடும்பு",
  "களகம்",
  "கபாடம்",
  "கடும்பை",
  "கக்கல்",
  "கர்ணிகை",
  "கரிசாலை",
  "கல்வெடி",
  "கக்கரி",
  "கல்வம்",
  "கண்வரி",
  "கசகசா",
  "களிமம்",
  "கடிசரி",
  "கடவன்",
  "கன்னம்",
  "கழுமல்",
  "கனசாரி",
  "கடவது",
  "கக்கார்",
  "கரோடிகை",
  "கலிபிலி",
  "கடையாணி",
  "கட்டூர்",
  "கவேலம்",
  "கடுக்கை",
  "கழப்பு",
  "கலியாணி",
  "கண்ணேறு",
  "கமடம்",
  "கன்னல்",
  "கவைதல்",
  "கணைக்கை",
  "கற்பம்",
  "கலைமான்",
  "கதுப்பு",
  "கடையால்",
  "கடைமணி",
  "கடைவீதி",
  "கஞ்சுளி",
  "களிமண்",
  "கடைகோல்",
  "கட்போன்",
  "கதியால்",
  "கசங்கு",
  "கடைமடை",
  "கண்காணி",
  "கவாரம்",
  "கற்பனை",
  "கடையேடு",
  "களமாலை",
  "கலவடை",
  "களிகம்",
  "கலதிமை",
  "கருமம்",
  "கலக்கு",
  "ககரம்",
  "கருக்கு",
  "கட்டூண்",
  "கணிதன்",
  "கலம்பி",
  "களிப்பு",
  "கழுந்து",
  "கருமன்",
  "கழுமணி",
  "கரப்பு",
  "கலாநிதி",
  "கவாத்து",
  "கப்பாசு",
  "கரையான்",
  "கண்ணிறை",
  "கவுட்டி",
  "கவுரம்",
  "கடற்பூ",
  "கண்ணிலி",
  "கமைப்பு",
  "கற்காரு",
  "கட்டோடு",
  "கரகம்",
  "கரிநாள்",
  "கருமணி",
  "கலவன்",
  "கரிப்பு",
  "கண்ணிமை",
  "கற்கேணி",
  "கரும்பூ",
  "கரும்பு",
  "கனல்வு",
  "கணக்கு",
  "கலீயம்",
  "கட்டோசை",
  "கல்வழி",
  "கசகம்",
  "கவாஅன்",
  "களாசம்",
  "கல்வளை",
  "கமலினி",
  "கடிப்பு",
  "கடிப்பா",
  "கழுமம்",
  "கடிப்பை",
  "கடுகோரை",
  "கண்வாய்",
  "கண்குழி",
  "கபிலர்",
  "கடலாடி",
  "கண்டாலி",
  "கண்கொதி",
  "கண்டால்",
  "கற்றாழை",
  "கவனம்",
  "கசட்டை",
  "கபிலம்",
  "கருவன்",
  "ககுளம்",
  "கண்டாய்",
  "கபோதம்",
  "கண்டார்",
  "கரளம்",
  "கத்திரி",
  "கட்டுரை",
  "கத்திரு",
  "கறையான்",
  "கருவாய்",
  "கற்றார்",
  "கறமண்",
  "கண்ணறை",
  "கட்டுமை",
  "கனகன்",
  "கவரிமா",
  "கட்டுமா",
  "கடுடம்",
  "கரீப்பு",
  "கடலடி",
  "கடனம்",
  "கயக்கு",
  "கப்பம்",
  "கல்நார்",
  "கடைதல்",
  "கடைவழி",
  "கண்ணொளி",
  "கங்கம்",
  "கட்சம்",
  "கஞ்சுகி",
  "கலைதல்",
  "கற்றானை",
  "கடைமோனை",
  "கடலெலி",
  "கழகம்",
  "கடலெள்",
  "கலிஞ்சு",
  "களைஞன்",
  "கனகம்",
  "கப்பல்",
  "கப்பறை",
  "களிதம்",
  "கங்கன்",
  "கண்ணூடு",
  "கப்பரை",
  "கட்டுவை",
  "கண்மூடி",
  "கண்ணன்",
  "கடலாமை",
  "கடுவினை",
  "கடுதாசி",
  "கண்ணோய்",
  "கங்கவி",
  "கக்கடி",
  "கடாரம்",
  "கதுவாய்",
  "கருவாடு",
  "கசப்பி",
  "கசப்பு",
  "கங்கர்",
  "கரியல்",
  "கண்பிடி",
  "கடலர்",
  "கதநம்",
  "கற்படை",
  "கசைவேலை",
  "கவலம்",
  "கரசம்",
  "கரியர்",
  "கலிசம்",
  "கன்றல்",
  "கன்முகை",
  "கடன்மை",
  "கண்ணளி",
  "கருதல்",
  "கடுவிலை",
  "கருத்தா",
  "கற்படி",
  "கருத்து",
  "கழியர்",
  "கரணன்",
  "கருவறை",
  "கண்கூடு",
  "கருவலி",
  "கலைஞானி",
  "கருவல்",
  "கழியல்",
  "கடற்கோ",
  "கடைவாய்",
  "கரியன்",
  "கண்ணூறு",
  "கருவரி",
  "கணிசம்",
  "கக்கம்",
  "கற்பசு",
  "கத்திகை",
  "கற்போன்",
  "கமைதல்",
  "கலனம்",
  "கடதாசி",
  "கருவம்",
  "கவினம்",
  "கண்டிகை",
  "கனப்பு",
  "கடைமுறை",
  "கடினம்",
  "கம்பன்",
  "கத்தலை",
  "களைகண்",
  "கவிச்சி",
  "கதண்டு",
  "கவிச்சு",
  "கனைப்பு",
  "கராளம்",
  "கடைமுளை",
  "கடத்தி",
  "களவம்",
  "கயாவாளி",
  "கற்புரை",
  "கரந்தை",
  "கடத்து",
  "கத்தளை",
  "கழிகடை",
  "கடைசல்",
  "களவன்",
  "கருவிலி",
  "கரணம்",
  "கருவிளா",
  "கழனிலை",
  "கணியான்",
  "கலிலம்",
  "கற்பொடி",
  "கள்வம்",
  "கத்தன்",
  "கராளன்",
  "கஞ்சிரா",
  "கரவடி",
  "கழிகோல்",
  "கள்வன்",
  "கராம்பு",
  "கலலம்",
  "கவேதுகை",
  "கர்மம்",
  "கழுவன்",
  "கடுகென",
  "கராக்கி",
  "கருவினை",
  "கடாகம்",
  "கவல்பு",
  "கரைசிலை",
  "கம்படி",
  "கரிகாடு",
  "கத்தரி",
  "கலவாசு",
  "கத்தரை",
  "கத்தம்",
  "கரைவாடை",
  "ககுபம்",
  "கடுநகை",
  "கற்பொறி",
  "கவிரம்",
  "கலிங்கு",
  "கஃகான்",
  "கமகம்",
  "கணகம்",
  "கல்லூரி",
  "கடிபடி",
  "கற்றளி",
  "கடுமொழி",
  "கண்பாடு",
  "கலிபலி",
  "கன்முலை",
  "கணகன்",
  "கதிதம்",
  "கதனம்",
  "கச்சோடி",
  "கருவேல்",
  "கவளம்",
  "கண்ணடி",
  "கடைசால்",
  "கற்றம்",
  "கன்முழை",
  "கமகன்",
  "கவளிகை",
  "கடுரம்",
  "கப்படா",
  "கரவம்",
  "கப்படி",
  "கச்சோணி",
  "கணைகாடு",
  "கண்ணுள்",
  "கணவாய்",
  "கலவார்",
  "கடுநடை",
  "கசவம்",
  "கரவர்",
  "கருவாலி",
  "கனலோன்",
  "கண்ணுறை",
  "கம்பளி",
  "கருணம்",
  "கம்பல்",
  "கருவாளி",
  "கடுவாய்",
  "கறப்பு",
  "கம்பலை",
  "கலுடம்",
  "கடவான்",
  "கற்புழை",
  "கலபம்",
  "கடைநிலை",
  "கதம்பு",
  "கதம்பை",
  "கருணன்",
  "கள்ளாமை",
  "கடிச்சை",
  "கம்பம்",
  "கண்ணுமை",
  "காதரவு",
  "காராண்மை",
  "காகோதம்",
  "காய்வாழை",
  "கால்மிதி",
  "காலுளைவு",
  "கார்மலி",
  "காட்டான்",
  "காராஞ்சி",
  "காலேயம்",
  "காவலன்",
  "காலளவு",
  "காகபலி",
  "காமரசி",
  "காபாலன்",
  "காளிமம்",
  "காடுமேடு",
  "காலதர்",
  "காட்டம்",
  "காகந்தி",
  "காமத்தீ",
  "காகதாளி",
  "கானகம்",
  "காத்தான்",
  "காழகம்",
  "காக்காய்",
  "காலவழு",
  "காளிந்தி",
  "காளவாய்",
  "கானீனன்",
  "காட்டாள்",
  "காகதேரி",
  "காகளம்",
  "கானவாழை",
  "கான்றல்",
  "காதகன்",
  "காட்டாறு",
  "காந்தள்",
  "காராளர்",
  "காவலறை",
  "காயகன்",
  "காந்தலை",
  "காந்தல்",
  "காராம்பி",
  "காற்றன்",
  "காஞ்சிரை",
  "காபணம்",
  "காசுமாலை",
  "காகரூடி",
  "காவணம்",
  "கார்பார்",
  "கார்வாரி",
  "காசனம்",
  "காவுதடி",
  "காண்டல்",
  "காக்கம்",
  "காகமாசி",
  "காடியுளி",
  "கால்வழி",
  "காரூகம்",
  "காந்தம்",
  "கால்தல்",
  "காமாட்டி",
  "கார்வார்",
  "காய்மடி",
  "காவந்து",
  "காவாளர்",
  "காற்படை",
  "கார்மணி",
  "காதலன்",
  "காமவேள்",
  "காய்நீர்",
  "கால்நடை",
  "காவட்டை",
  "காமசாலை",
  "காவிமண்",
  "காகோடகி",
  "காரகம்",
  "கானனீர்",
  "காப்பீடு",
  "காதிகன்",
  "காமாட்சி",
  "கார்ப்பு",
  "காற்காறை",
  "காரியம்",
  "காலாவதி",
  "காலூரம்",
  "காமலீலை",
  "காயமேரை",
  "காரெனல்",
  "காலமழை",
  "காகநதி",
  "காரகன்",
  "காற்றாடி",
  "காலகதி",
  "காகபீலி",
  "காலத்தீ",
  "காமதம்",
  "காய்பசி",
  "காலேகம்",
  "காஞ்சொறி",
  "காப்பொன்",
  "காரூடம்",
  "காய்ச்சி",
  "காண்வரி",
  "காலகம்",
  "காமியம்",
  "காய்ப்பு",
  "காகாலன்",
  "காத்தல்",
  "காலவம்",
  "காதரம்",
  "கானவன்",
  "காத்திரி",
  "காமியர்",
  "காத்திரை",
  "காழ்கோளி",
  "காட்டாடு",
  "காதரன்",
  "காடுவாரி",
  "காப்பில்",
  "காமனாள்",
  "காசுகல்",
  "காபாலம்",
  "காடாற்று",
  "காடகம்",
  "கால்வாய்",
  "காடுகாள்",
  "காலவகை",
  "காற்சரி",
  "காலவிதி",
  "கார்போகி",
  "காதலோன்",
  "காமரூபி",
  "கார்நெல்",
  "கான்யாறு",
  "காஞ்சிகை",
  "காறுபாறு",
  "காளையம்",
  "காளியன்",
  "காஞ்சிகா",
  "காளாம்பி",
  "காடவன்",
  "கால்நோய்",
  "காவதம்",
  "காலோலம்",
  "காளாஞ்சி",
  "காதலான்",
  "காகிதம்",
  "கார்கோளி",
  "காட்டுமா",
  "காம்பிலி",
  "காசையாடை",
  "காற்பாதை",
  "கார்கோள்",
  "காடுகோள்",
  "காரடம்",
  "காவதன்",
  "காரிகம்",
  "காருகன்",
  "காராமணி",
  "காந்தாரி",
  "காதுகன்",
  "காருகம்",
  "காயிகம்",
  "கான்முளை",
  "காயாபுரி",
  "காலியம்",
  "காளைமாடு",
  "காகோலம்",
  "கார்கோன்",
  "கால்மாடு",
  "காமுகன்",
  "காங்கிசை",
  "காரறிவு",
  "காரோடன்",
  "காமிகம்",
  "காட்டில்",
  "காரிருள்",
  "காஞ்சுகி",
  "காறுதல்",
  "காருடன்",
  "காமவெறி",
  "காளபம்",
  "காம்போகி",
  "காரணன்",
  "காடிகம்",
  "காண்டம்",
  "காசண்டி",
  "காமதேனு",
  "காளமுகி",
  "காமபூமி",
  "காந்தன்",
  "காமநீர்",
  "காற்றேறு",
  "காருடம்",
  "காழ்ப்பு",
  "காணலன்",
  "காணுமோர்",
  "காலசம்",
  "கார்வினை",
  "காதகம்",
  "காய்கறி",
  "காரீயம்",
  "காணிக்கை",
  "காலநேமி",
  "காயகம்",
  "காதவம்",
  "காவிதிமை",
  "காதன்மை",
  "காணுதல்",
  "காளிதம்",
  "கானிலம்",
  "காழியன்",
  "காவியம்",
  "காப்பாள்",
  "காமுருகி",
  "காவுதல்",
  "காரடன்",
  "காவலாளி",
  "காவலாள்",
  "காய்தல்",
  "காளிகம்",
  "காளகம்",
  "காசரம்",
  "காபிலம்",
  "காட்டேறி",
  "கால்கோள்",
  "காஞ்சனி",
  "காமப்பூ",
  "காசநீர்",
  "காவியன்",
  "காதுதல்",
  "காவுவோர்",
  "காமநோய்",
  "கானனம்",
  "காலிமாடு",
  "காசாயம்",
  "காலமாறு",
  "காசுமண்",
  "காரணம்",
  "காபந்து",
  "காமரம்",
  "கால்வாசி",
  "காசிரம்",
  "காண்டிகை",
  "காச்சுரை",
  "கிழத்தி",
  "கிழாஅன்",
  "கிங்கிணி",
  "கில்தல்",
  "கிண்ணம்",
  "கிள்ளல்",
  "கிருதம்",
  "கியாழம்",
  "கிடுமுடி",
  "கிரந்தி",
  "கியாதம்",
  "கிணகன்",
  "கில்லம்",
  "கிளியீடு",
  "கிரகணி",
  "கிண்டன்",
  "கிழிப்பு",
  "கின்னரி",
  "கிஞ்சன்",
  "கிராணம்",
  "கிறுக்கு",
  "கிளைப்பு",
  "கிராமணி",
  "கிரீதன்",
  "கிரமம்",
  "கிழிசல்",
  "கிணைநிலை",
  "கிழியீடு",
  "கினிதல்",
  "கிழக்கு",
  "கிராவம்",
  "கிட்டார்",
  "கிண்கிணி",
  "கிராந்தி",
  "கிணையன்",
  "கின்னம்",
  "கிழிமுறி",
  "கிருட்டி",
  "கிடுபிடி",
  "கிலீபம்",
  "கிழாத்தி",
  "கிராதன்",
  "கிட்டம்",
  "கிதவம்",
  "கிற்றல்",
  "கிடுகிடு",
  "கியானம்",
  "கிருகிணி",
  "கிறுசன்",
  "கிழிதம்",
  "கிடக்கை",
  "கிஞ்சில்",
  "கிளைதல்",
  "கிளைவழி",
  "கிஞ்சுகி",
  "கிதவன்",
  "கித்தம்",
  "கிடிகோள்",
  "கிரிசம்",
  "கிம்புரி",
  "கிரேந்தி",
  "கிரீட்டி",
  "கிளையார்",
  "கிராக்கி",
  "கிடேச்சை",
  "கிராம்பு",
  "கிடேச்சு",
  "கிரீசன்",
  "கிற்பன்",
  "கிழிதல்",
  "கிழவது",
  "கிரணம்",
  "கிடங்கு",
  "கிராகதி",
  "கிழங்கு",
  "கிரேதம்",
  "கிச்சிலி",
  "கிடப்பு",
  "கிரிசன்",
  "கிணிதம்",
  "கிருசம்",
  "கிங்கரி",
  "கிணாங்கு",
  "கிங்கரை",
  "கிழமழை",
  "கிலுக்கு",
  "கிண்டான்",
  "கிரணன்",
  "கிரகம்",
  "கிரீடம்",
  "கிக்கிரி",
  "கித்தான்",
  "கிச்சடி",
  "கிடுக்கு",
  "கிடுகோலை",
  "கிளைநதி",
  "கிறாக்கி",
  "கிளர்வி",
  "கிஞ்சம்",
  "கிழமேல்",
  "கிளவரி",
  "கிளைஞர்",
  "கிராமம்",
  "கிணாட்டு",
  "கிழியல்",
  "கிரயம்",
  "கிடைச்சி",
  "கிளப்பு",
  "கிடைச்சு",
  "கிசலம்",
  "கிட்டடி",
  "கித்தில்",
  "கிருபாளு",
  "கிஞ்சல்",
  "கிழவோன்",
  "கிடைச்சை",
  "கிண்டல்",
  "கிடாரம்",
  "கிருதன்",
  "கிரீவம்",
  "கிருத்தி",
  "கிழவன்",
  "கிரிகிரி",
  "கிலுகிலி",
  "கிலேசம்",
  "கிருகம்",
  "கீழைநாள்",
  "கீழிதழ்",
  "கீல்தல்",
  "கீகசம்",
  "கீரவாணி",
  "கீரைமணி",
  "கீழ்நிலை",
  "கீலச்சு",
  "கீழ்பால்",
  "கீறுதல்",
  "கீழ்தல்",
  "கீச்சான்",
  "கீலகம்",
  "கீதவீதி",
  "கீழண்டை",
  "கீசகம்",
  "கீதநடை",
  "கீன்றல்",
  "கீற்பாய்",
  "கீரைமீன்",
  "கீடமணி",
  "கீழ்வாய்",
  "கீண்டல்",
  "கீதசாலை",
  "கீலாலம்",
  "கீழுதல்",
  "கீழுலகு",
  "கீழோங்கி",
  "கீரிநோய்",
  "கீழாதல்",
  "கீடமாரி",
  "கீர்வாணி",
  "கீர்த்தி",
  "கீள்தல்",
  "கீகடம்",
  "கீற்றன்",
  "கீல்வாயு",
  "கீரிராசி",
  "கீழ்மடை",
  "கீல்முளை",
  "கீதவம்",
  "கீசரன்",
  "கீரணம்",
  "குற்குலு",
  "குலைதல்",
  "குற்றிசை",
  "குறுக்கை",
  "குறுக்கே",
  "குறுக்கு",
  "குழலோன்",
  "குப்பம்",
  "குழுவல்",
  "குறுமல்",
  "குண்டிகை",
  "குளாஞ்சி",
  "குலசன்",
  "குரோட்டா",
  "குரோட்டு",
  "குடிகேடு",
  "குருச்சி",
  "குபிலன்",
  "குடைவேல்",
  "குடிகேடி",
  "குச்சில்",
  "குறும்பு",
  "குறும்பி",
  "குப்பல்",
  "குறைவில்",
  "குலிகம்",
  "குமிலம்",
  "குடிகோள்",
  "குழாஅல்",
  "குற்றம்",
  "குழைதல்",
  "குமரம்",
  "குருடன்",
  "குணுக்கு",
  "குழுவன்",
  "குசும்பை",
  "குரிசில்",
  "குளகன்",
  "குவிதல்",
  "குழைச்சு",
  "குழைச்சி",
  "குசும்பா",
  "குசும்பு",
  "குடியான்",
  "குறிப்பு",
  "குறவன்",
  "குப்பன்",
  "குசலன்",
  "குளம்பு",
  "குணிப்பு",
  "குலீனன்",
  "குரச்சை",
  "குதுகம்",
  "குமரன்",
  "குடமுழா",
  "குரோதன",
  "குல்யன்",
  "குணவான்",
  "கும்பம்",
  "குலகுரு",
  "குதானன்",
  "குறியோன்",
  "குரோதம்",
  "குறண்டி",
  "கும்பல்",
  "குத்தல்",
  "குறட்பா",
  "குடியாள்",
  "குழலூதி",
  "குழியானை",
  "குழுதாழி",
  "குளிப்பு",
  "குதிகால்",
  "குபினன்",
  "குரூபம்",
  "குந்துரு",
  "குத்தினி",
  "குறட்டை",
  "குதபம்",
  "குடந்தை",
  "குலாயம்",
  "குதைச்சு",
  "கும்பிடு",
  "குதம்பை",
  "குபையம்",
  "குடிபடை",
  "கும்மலி",
  "குருமான்",
  "கும்மல்",
  "கும்பன்",
  "குதலைமை",
  "குடிலம்",
  "குதபன்",
  "குருவால்",
  "குளிரம்",
  "குரோதன்",
  "குப்பிமா",
  "குண்டில்",
  "குசவன்",
  "குணகர்",
  "குணாளன்",
  "குபிதன்",
  "குபேரன்",
  "குரத்தி",
  "குணபாசி",
  "குறுநெறி",
  "குரவன்",
  "குருமுனி",
  "குருசம்",
  "குடும்பி",
  "குறைமதி",
  "குடும்பு",
  "குடுக்கை",
  "குணகம்",
  "குடீரம்",
  "குவிரம்",
  "குதிரம்",
  "குறுநொய்",
  "குமிதல்",
  "குரோடம்",
  "குட்டான்",
  "குறுகார்",
  "குத்தம்",
  "குடையாணி",
  "குடியிறை",
  "குனாசம்",
  "குச்சம்",
  "குழந்தை",
  "குளநெல்",
  "குடசம்",
  "குடமுனி",
  "குமுகம்",
  "குதற்று",
  "குயலன்",
  "குத்தன்",
  "குச்சரி",
  "குழைசேறு",
  "குடத்தி",
  "குரவம்",
  "குத்துணி",
  "குலமுறை",
  "குசவம்",
  "குறுவிலை",
  "குல்லம்",
  "குருந்து",
  "குங்குலு",
  "குருசில்",
  "குடக்கு",
  "குடக்கி",
  "குடைகரி",
  "குடம்பை",
  "குத்தகை",
  "குல்லரி",
  "குலமணி",
  "குவாதம்",
  "குளிசம்",
  "குடிங்கு",
  "குந்தணை",
  "குட்டேறு",
  "குளியல்",
  "குமிதம்",
  "குலவுரி",
  "குசவோடு",
  "குஞ்சன்",
  "குலீரம்",
  "குலாமர்",
  "குளுத்தி",
  "குடகன்",
  "குரங்கி",
  "குரங்கு",
  "குதையாணி",
  "குடபலை",
  "குடக்கோ",
  "குறுதல்",
  "குளியம்",
  "குந்தம்",
  "குப்பான்",
  "குக்கில்",
  "குறைபாடு",
  "குலபதி",
  "குஞ்சம்",
  "குறுநர்",
  "குளுந்தை",
  "குதனம்",
  "குறைதல்",
  "குருமுறை",
  "குறோட்டை",
  "குடங்கை",
  "குழிசீலை",
  "குதிப்பு",
  "குடகம்",
  "குந்தன்",
  "குணாம்பு",
  "குருத்து",
  "குவியல்",
  "குணாம்பி",
  "குடிநிலை",
  "குந்தாணி",
  "குரைப்பு",
  "குறிஞ்சி",
  "குறிஞ்சா",
  "குருசேவை",
  "குணபம்",
  "குஞ்சரி",
  "குருபூசை",
  "குந்தாலி",
  "குளிர்மை",
  "குறஞ்சி",
  "குந்தாளி",
  "குனிப்பு",
  "குமண்டை",
  "குடிரம்",
  "குடுகுடா",
  "குடுகுடி",
  "குதைதல்",
  "குழையல்",
  "குணமணி",
  "குருநோய்",
  "குணநிதி",
  "குத்தூசி",
  "குடதிசை",
  "குசத்தி",
  "குலக்கு",
  "குறிச்சி",
  "குரம்பை",
  "குருப்பு",
  "குளைச்சு",
  "குகாரணி",
  "குமுதிகை",
  "குளிசீலை",
  "குதாவிடை",
  "குய்யம்",
  "குளுவன்",
  "குருநாள்",
  "குரம்பு",
  "குயவன்",
  "குலாலன்",
  "குரோசம்",
  "குறளடி",
  "குணக்கு",
  "குழிதல்",
  "குலுக்கி",
  "குறளன்",
  "குண்டுணி",
  "குகைச்சி",
  "குசாண்டு",
  "குருமன்",
  "குணசாலி",
  "குறுநடை",
  "குறுவால்",
  "குணங்கு",
  "குபசுபா",
  "குட்டினி",
  "குரணம்",
  "குலுக்கை",
  "குணிதம்",
  "குலுமம்",
  "குழிநரி",
  "குலம்பா",
  "குறுநணி",
  "குணாலம்",
  "குறைநிறை",
  "குன்மம்",
  "குயவரி",
  "குளிகன்",
  "குடமலை",
  "குளஞ்சி",
  "குல்லாய்",
  "குடநாடு",
  "குலைநோய்",
  "குஞ்சான்",
  "குழுமல்",
  "குடைநிலை",
  "குழம்பு",
  "குடிசல்",
  "குவேலம்",
  "குளவாழை",
  "குழகம்",
  "குத்துனி",
  "குலமீன்",
  "குழகன்",
  "குணதிசை",
  "குதரம்",
  "குறோக்கை",
  "குடிவெறி",
  "குல்வலி",
  "குலைப்பு",
  "குறுணல்",
  "குருமணி",
  "குமட்டு",
  "குலகிரி",
  "குருக்கு",
  "குலவரை",
  "குலவரி",
  "குகீலம்",
  "குரும்பை",
  "குரும்பி",
  "குயினர்",
  "குடற்பை",
  "குடமண்",
  "குன்னம்",
  "குணனம்",
  "குலிரம்",
  "குள்ளம்",
  "குபலம்",
  "குறித்து",
  "குற்பம்",
  "குறுநகை",
  "குள்ளன்",
  "குண்டூசி",
  "குனிதல்",
  "குடிநீர்",
  "குழப்பு",
  "குகரம்",
  "குக்குடி",
  "குயுக்தி",
  "குகரர்",
  "குழிப்பு",
  "குச்சிகை",
  "குத்தாலா",
  "குடதாடி",
  "குமுறல்",
  "குத்தாளை",
  "குயத்தி",
  "குண்டலி",
  "குடாவடி",
  "குருகூர்",
  "குடாப்பு",
  "குலவன்",
  "குதிமுள்",
  "குமாரம்",
  "குலாதனி",
  "குடாக்கு",
  "குன்றம்",
  "குடாக்கை",
  "குலோமிசை",
  "குன்றர்",
  "குட்டன்",
  "குறத்தி",
  "குரூரம்",
  "குட்டரி",
  "குன்றல்",
  "குளுப்பை",
  "குணட்டு",
  "குலிசம்",
  "குமுதம்",
  "குயிறல்",
  "குறுமுனி",
  "குறியீடு",
  "குட்டம்",
  "குராசானி",
  "குஞ்சுறை",
  "குரகம்",
  "குயுத்தி",
  "குமிழாணி",
  "குழும்பு",
  "குக்குரி",
  "குலிசன்",
  "குடவோலை",
  "குடவன்",
  "குல்லிரி",
  "குவாகம்",
  "குறுகல்",
  "குண்டனி",
  "குறங்கு",
  "குபதன்",
  "குக்கல்",
  "குத்தாணி",
  "குடமாலை",
  "குழற்சி",
  "குமாரன்",
  "குசலம்",
  "குகுரன்",
  "குக்கர்",
  "குறையவை",
  "குமைதல்",
  "குக்குலி",
  "குக்குலு",
  "குளகம்",
  "குண்டணி",
  "குண்டம்",
  "குகூகம்",
  "குலிஞன்",
  "குமிண்டி",
  "குழிங்கை",
  "குடைதல்",
  "குக்கன்",
  "குடவம்",
  "குடாரம்",
  "குடியன்",
  "குட்டுணி",
  "குமுக்கு",
  "குண்டன்",
  "குழியம்",
  "குறைகோள்",
  "குபதம்",
  "குசுமம்",
  "குணுங்கு",
  "குடவறை",
  "குருவன்",
  "குடவரை",
  "குணவதி",
  "குடவர்",
  "கூட்டமை",
  "கூச்சம்",
  "கூடசன்",
  "கூதுளம்",
  "கூவைநீறு",
  "கூவநூல்",
  "கூழையன்",
  "கூப்பிடு",
  "கூவுவான்",
  "கூபகம்",
  "கூர்தல்",
  "கூடகம்",
  "கூரைவீடு",
  "கூளியர்",
  "கூதளம்",
  "கூட்டடி",
  "கூடாதார்",
  "கூவிளம்",
  "கூசுதல்",
  "கூர்மிகை",
  "கூப்பாடு",
  "கூளுதல்",
  "கூட்டம்",
  "கூட்டல்",
  "கூவிரம்",
  "கூட்டாளி",
  "கூழைக்கை",
  "கூனுதல்",
  "கூர்மன்",
  "கூழைநரி",
  "கூடாரம்",
  "கூத்தர்",
  "கூட்டர்",
  "கூகைநீறு",
  "கூடாக்கு",
  "கூர்கேவு",
  "கூலவதி",
  "கூள்தல்",
  "கூழாதல்",
  "கூர்மம்",
  "கூட்டுமா",
  "கூரியன்",
  "கூசனம்",
  "கூறுதல்",
  "கூவியர்",
  "கூழ்ப்பு",
  "கூடலர்",
  "கூலகம்",
  "கூடுதல்",
  "கூதாளம்",
  "கூவுதல்",
  "கூபரம்",
  "கூட்டோடு",
  "கூகனம்",
  "கூர்முள்",
  "கூழங்கை",
  "கூற்றன்",
  "கூத்தன்",
  "கூம்பல்",
  "கூலியாள்",
  "கூச்சல்",
  "கூர்ச்சு",
  "கூர்ச்சி",
  "கூரியம்",
  "கூசிதம்",
  "கூகாகம்",
  "கூத்தாடி",
  "கூந்தல்",
  "கூபாரம்",
  "கூதனம்",
  "கூன்கிடை",
  "கூர்ப்பு",
  "கூற்றம்",
  "கூந்தாலி",
  "கூரணம்",
  "கூறுபாடு",
  "கூப்பீடு",
  "கூடையன்",
  "கெம்பரை",
  "கெலுழன்",
  "கெடுவாய்",
  "கெடலூழ்",
  "கெண்டண்",
  "கெவியூதி",
  "கெடுகுறி",
  "கெடுமதி",
  "கெட்டணை",
  "கெடுபிடி",
  "கெலிப்பு",
  "கெளிதம்",
  "கெண்டம்",
  "கெடுதலை",
  "கெந்தம்",
  "கெடுதல்",
  "கெட்டேன்",
  "கெடுவான்",
  "கெச்சம்",
  "கெட்டம்",
  "கெண்டிகை",
  "கெடும்பு",
  "கெடிமாடு",
  "கெக்கலி",
  "கெடிலம்",
  "கெசாசைநா",
  "கெளுத்தி",
  "கேத்திரி",
  "கேதனம்",
  "கேவலம்",
  "கேகயம்",
  "கேருதல்",
  "கேயிகம்",
  "கேசரர்",
  "கேசவன்",
  "கேதுதல்",
  "கேதுமால்",
  "கேளிக்கை",
  "கேடயம்",
  "கேரளன்",
  "கேட்டல்",
  "கேகலன்",
  "கேசகன்",
  "கேலிகலை",
  "கேவேடன்",
  "கேவலன்",
  "கேட்போர்",
  "கேசரம்",
  "கேலகன்",
  "கேசதம்",
  "கேளலர்",
  "கேடுபாடு",
  "கேமாச்சி",
  "கேவுதல்",
  "கேயூரம்",
  "கேழ்த்த",
  "கேசவம்",
  "கேவணம்",
  "கேள்வன்",
  "கேளிதம்",
  "கேதாரம்",
  "கேரளம்",
  "கேடகம்",
  "கைமூட்டு",
  "கைந்தலை",
  "கைப்பட",
  "கைம்மணி",
  "கைமலிவு",
  "கையுதவி",
  "கைவரிசை",
  "கைமுதல்",
  "கைதகம்",
  "கைத்தாய்",
  "கைமூலம்",
  "கைதவம்",
  "கைக்கிளை",
  "கைக்காறை",
  "கையாட்சி",
  "கைம்பெண்",
  "கைப்புலி",
  "கைச்சூடு",
  "கைத்தடி",
  "கைம்மீன்",
  "கைரவம்",
  "கைவாரம்",
  "கைந்நொடி",
  "கைத்திரி",
  "கைமகவு",
  "கைத்தாள்",
  "கைவாளம்",
  "கைவட்டி",
  "கைவளம்",
  "கைலாசம்",
  "கைவந்தி",
  "கைநாட்டு",
  "கைமட்டு",
  "கைதொடன்",
  "கைப்பணி",
  "கைம்மேலே",
  "கைவிரைவு",
  "கைசிகம்",
  "கைவலம்",
  "கைக்கோல்",
  "கையுடன்",
  "கையாடல்",
  "கைச்சரி",
  "கைமுட்டி",
  "கையேந்தி",
  "கையேற்பு",
  "கைகயன்",
  "கைச்சிறை",
  "கைதவன்",
  "கைக்குடை",
  "கையறல்",
  "கைபோதல்",
  "கைம்மலை",
  "கைப்பிடி",
  "கைவாக்கு",
  "கையுறுதி",
  "கைத்தூண்",
  "கைதரல்",
  "கையலகு",
  "கைநெல்லி",
  "கைதொடல்",
  "கைப்பாடு",
  "கையறவு",
  "கைலாயம்",
  "கைத்தல்",
  "கைப்புடை",
  "கைகாட்டு",
  "கையேறல்",
  "கைக்காணி",
  "கைதூவல்",
  "கைதோய்வு",
  "கைவேகம்",
  "கைப்படை",
  "கைகண்ட",
  "கைந்நூல்",
  "கைநீளம்",
  "கையறுதி",
  "கைவீச்சு",
  "கைகாரன்",
  "கைமாற்று",
  "கைவசம்",
  "கைமரம்",
  "கைம்மான்",
  "கையறம்",
  "கைரிகம்",
  "கைம்மாறு",
  "கைகாவல்",
  "கைதூவாமை",
  "கைராட்டு",
  "கைக்குறி",
  "கைச்சாடு",
  "கைப்பாணி",
  "கைவண்டி",
  "கையூட்டு",
  "கைக்கூலி",
  "கைவன்மை",
  "கொதியல்",
  "கொடுவாள்",
  "கொக்கில்",
  "கொடிநிலை",
  "கொத்தல்",
  "கொத்தளி",
  "கொட்டறை",
  "கொண்டான்",
  "கொசுவம்",
  "கொலைமலை",
  "கொல்லன்",
  "கொறிதலை",
  "கொண்டோன்",
  "கொடுவாய்",
  "கொப்பூழ்",
  "கொழுநீர்",
  "கொள்ளம்",
  "கொல்லைமை",
  "கொத்தானை",
  "கொத்தான்",
  "கொள்வோன்",
  "கொங்கணி",
  "கொள்விலை",
  "கொம்பன்",
  "கொழியல்",
  "கொட்டம்",
  "கொடுப்பு",
  "கொடுப்பை",
  "கொற்றாள்",
  "கொற்றம்",
  "கொலைவன்",
  "கொய்யுளை",
  "கொணசில்",
  "கொடூரம்",
  "கொண்டம்",
  "கொளுக்கி",
  "கொடுஞ்சி",
  "கொடுக்கி",
  "கொடுக்கு",
  "கொச்சம்",
  "கொடுசூரி",
  "கொடும்பை",
  "கொப்புள்",
  "கொசிகம்",
  "கொதிப்பு",
  "கொழுநன்",
  "கொருடன்",
  "கொட்டன்",
  "கொழுநனை",
  "கொடும்பு",
  "கொடியாடு",
  "கொல்லறு",
  "கொக்கரி",
  "கொற்றன்",
  "கொண்டல்",
  "கொள்ளாமை",
  "கொக்கரை",
  "கொக்கறை",
  "கொடுமூலை",
  "கொல்லர்",
  "கொய்யடி",
  "கொச்சன்",
  "கொலைநன்",
  "கொடிப்பூ",
  "கொழுமீதி",
  "கொற்சேரி",
  "கொந்தம்",
  "கொழுப்பு",
  "கொடிச்சி",
  "கொள்ளார்",
  "கொசவம்",
  "கொத்தாள்",
  "கொல்லமா",
  "கொம்பர்",
  "கொய்தல்",
  "கொள்வினை",
  "கொதிநீர்",
  "கொங்காணி",
  "கொட்டணை",
  "கொந்தளை",
  "கொளாஅல்",
  "கொம்பூதி",
  "கொடுவரி",
  "கொடியான்",
  "கொந்தல்",
  "கொடுவேலி",
  "கொற்றவை",
  "கொடையாளி",
  "கொற்றவி",
  "கொழுஞ்சி",
  "கொடிஞ்சி",
  "கொட்டடி",
  "கொட்டாவி",
  "கொளுத்து",
  "கொந்தாழை",
  "கொடிவழி",
  "கொடைவினா",
  "கொழுமுறி",
  "கொடியன்",
  "கொழிப்பு",
  "கொஞ்சல்",
  "கொலையாளி",
  "கொத்தன்",
  "கொடித்தீ",
  "கொந்தாலி",
  "கொல்லுலை",
  "கொடுமடி",
  "கொணாதல்",
  "கொடைமுடி",
  "கொடியது",
  "கொடைக்கை",
  "கொட்டாறு",
  "கொண்கன்",
  "கொழிஞ்சி",
  "கொதியன்",
  "கொல்லாமை",
  "கொப்பரி",
  "கொஞ்சம்",
  "கொக்கான்",
  "கொப்பரை",
  "கொடிவேலி",
  "கொங்கரி",
  "கொழுந்து",
  "கொழுந்தி",
  "கொப்பம்",
  "கொடியாள்",
  "கொய்யல்",
  "கொறுக்கை",
  "கொலைஞன்",
  "கொடுவினை",
  "கொறுக்கு",
  "கொடையோன்",
  "கொடியோன்",
  "கொங்கன்",
  "கொஞ்சன்",
  "கொத்தம்",
  "கொள்வனை",
  "கொடுங்கை",
  "கொற்றான்",
  "கொடிவீடு",
  "கொடுமுடி",
  "கொட்டில்",
  "கொற்றுறை",
  "கொட்டகை",
  "கொட்டாய்",
  "கோமயம்",
  "கோபனம்",
  "கோடாலம்",
  "கோண்டம்",
  "கோலாஞ்சி",
  "கோலகம்",
  "கோமுகம்",
  "கோவலூர்",
  "கோசலம்",
  "கோருதல்",
  "கோளகம்",
  "கோம்பல்",
  "கோகனம்",
  "கோனிச்சி",
  "கோம்பறை",
  "கோங்கம்",
  "கோளாளன்",
  "கோதானம்",
  "கோண்டன்",
  "கோடிகம்",
  "கோநகர்",
  "கோயிலார்",
  "கோசரம்",
  "கோகருணி",
  "கோணவாய்",
  "கோடிகர்",
  "கோட்பறை",
  "கோணியல்",
  "கோமளம்",
  "கோற்கொடி",
  "கோமகள்",
  "கோறம்பு",
  "கோமணம்",
  "கோமியம்",
  "கோகனதை",
  "கோண்மீன்",
  "கோரோசனை",
  "கோட்பாடு",
  "கோப்பன்",
  "கோபீகன்",
  "கோவின்மை",
  "கோக்காலி",
  "கோளாம்பி",
  "கோரகம்",
  "கோரவாரி",
  "கோணுதல்",
  "கோத்திரி",
  "கோத்திரை",
  "கோத்தல்",
  "கோலவேர்",
  "கோலிகன்",
  "கோற்புழு",
  "கோடாகோடி",
  "கோதும்பை",
  "கோரதம்",
  "கோணங்கி",
  "கோபாலன்",
  "கோந்துரு",
  "கோற்றேன்",
  "கோப்பாளி",
  "கோழையன்",
  "கோட்டலை",
  "கோமரம்",
  "கோசாரம்",
  "கோபிதம்",
  "கோகத்தி",
  "கோடையிடி",
  "கோதுதல்",
  "கோகிலம்",
  "கோடாவதி",
  "கோதாவிரி",
  "கோணயூசி",
  "கோரசம்",
  "கோரிக்கை",
  "கோலியன்",
  "கோமகன்",
  "கோசவதி",
  "கோளேசம்",
  "கோட்டம்",
  "கோடரம்",
  "கோடாங்கி",
  "கோணிப்பை",
  "கோரம்பு",
  "கோவலர்",
  "கோரிதம்",
  "கோள்வாய்",
  "கோமேதம்",
  "கோட்டான்",
  "கோவெனல்",
  "கோடாசுழி",
  "கோலாச்சி",
  "கோடபதி",
  "கோத்தனி",
  "கோவாங்கு",
  "கோதுகம்",
  "கோபத்தீ",
  "கோலாலம்",
  "கோணையன்",
  "கோலோகம்",
  "கோதந்தி",
  "கோட்டுமா",
  "கோவியர்",
  "கோசிகம்",
  "கோதட்டு",
  "கோகயம்",
  "கோகடம்",
  "கோயிலாள்",
  "கோல்வளை",
  "கோவணன்",
  "கோவிதன்",
  "கோடுதல்",
  "கோதையன்",
  "கோசமம்",
  "கோடகம்",
  "கோதாவரி",
  "கோழியான்",
  "கோவலன்",
  "கோலரம்",
  "கோகுலம்",
  "கோடீரம்",
  "கோட்கூறு",
  "கோடங்கி",
  "கோயின்மை",
  "கோடியர்",
  "கோழிமுள்",
  "கோலுதல்",
  "கோற்றொடி",
  "கோமாளம்",
  "கோசிகன்",
  "கோடாஞ்சி",
  "கோசம்பி",
  "கோதனம்",
  "கோரங்கி",
  "கோவிட்டு",
  "கோபுரம்",
  "கோவளம்",
  "கோடிரம்",
  "கோமாட்டி",
  "கோபாலிகை",
  "கோவணம்",
  "கௌவுகன்",
  "கௌசனம்",
  "கௌஞ்சம்",
  "கௌதுகம்",
  "கௌசுகம்",
  "கௌவியம்",
  "கௌதகம்",
  "கௌடிலம்",
  "கௌவுதல்",
  "கௌரவம்",
  "கௌசிகம்",
  "கௌடநெறி",
  "கௌரியம்",
  "கௌசலம்",
  "கௌமாரம்",
  "கௌபீனம்",
  "கௌதமன்",
  "கௌரியன்",
  "கௌசிகன்",
  "கௌதமிமை",
  "கௌமோதகி",
  "கௌணியர்",
  "கௌரிகேணி",
  "கௌரவர்",
  "கௌவுதடி",
  "ஙகரம்",
  "சரராசி",
  "சலனம்",
  "சலசாதி",
  "சததம்",
  "சல்லகி",
  "சயசய",
  "சனிதிசை",
  "சடுகுடு",
  "சஞ்சிகை",
  "சருமன்",
  "சத்துரு",
  "சகடிகை",
  "சமலன்",
  "சவுதம்",
  "சத்துமா",
  "சஞ்சாலி",
  "சலக்கு",
  "சலனன்",
  "சந்தானி",
  "சதோகுரு",
  "சகுடம்",
  "சம்வரை",
  "சமானதை",
  "சசாபம்",
  "சதாவேரி",
  "சமைகடை",
  "சடுத்தி",
  "சதமூலை",
  "சயித்தி",
  "சழங்கு",
  "சரணர்",
  "சவியம்",
  "சலநிதி",
  "சட்டினி",
  "சணப்பை",
  "சஞ்சாரி",
  "சணப்பு",
  "சனுகம்",
  "சருமம்",
  "சரணம்",
  "சன்னல்",
  "சம்புளி",
  "சகாதேவி",
  "சதுவகை",
  "சடாதரி",
  "சந்திகை",
  "சமானன்",
  "சதவல்",
  "சழிதல்",
  "சர்ச்சை",
  "சமலம்",
  "சரக்கு",
  "சல்லடை",
  "சன்னம்",
  "சதுரம்",
  "சகடோல்",
  "சரோசம்",
  "சடிதிவு",
  "சடைநாய்",
  "சதுவல்",
  "சகாரம்",
  "சஞ்சடி",
  "சர்வதா",
  "சதகோடி",
  "சடகம்",
  "சலலம்",
  "சரடம்",
  "சராவம்",
  "சலசூசி",
  "சவுண்டி",
  "சந்தசு",
  "சரவீணை",
  "சமீபம்",
  "சன்மலி",
  "சகனம்",
  "சவுரர்",
  "சவ்வாது",
  "சதும்பை",
  "சதியன்",
  "சலிப்பு",
  "சதாரம்",
  "சவடால்",
  "சக்கிரி",
  "சடுலம்",
  "சகலம்",
  "சன்மம்",
  "சயனம்",
  "சதைதல்",
  "சலராசி",
  "சந்தகை",
  "சதனம்",
  "சயந்தி",
  "சதுப்பு",
  "சவளம்",
  "சர்வன்",
  "சயிலம்",
  "சழக்கு",
  "சலபதி",
  "சந்தேகி",
  "சரவடி",
  "சதாயுசு",
  "சடானன்",
  "சணம்பு",
  "சகலன்",
  "சகியம்",
  "சள்ளல்",
  "சமட்டி",
  "சக்கிலி",
  "சலுப்பு",
  "சரிமேரை",
  "சர்வம்",
  "சதைவலி",
  "சமீகம்",
  "சண்டம்",
  "சகத்து",
  "சயிலாதி",
  "சங்கம்",
  "சம்பால்",
  "சங்கரி",
  "சமகலி",
  "சலபம்",
  "சரியம்",
  "சமுக்கா",
  "சரவன்",
  "சலாத்தி",
  "சம்மான்",
  "சங்களை",
  "சமகம்",
  "சட்டால்",
  "சணகம்",
  "சமுசாரி",
  "சம்போர்",
  "சக்கோலி",
  "சடையோன்",
  "சகலர்",
  "சண்ணம்",
  "சமயம்",
  "சதாகதி",
  "சம்பான்",
  "சலகம்",
  "சங்கிலி",
  "சமிக்கை",
  "சண்டன்",
  "சரதம்",
  "சடைதல்",
  "சலீகம்",
  "சமழ்மை",
  "சட்சம்",
  "சலகன்",
  "சலசல",
  "சம்பாரி",
  "சலதாரி",
  "சலதாரை",
  "சதோடம்",
  "சக்கடி",
  "சமவிலை",
  "சவரணை",
  "சபரணை",
  "சகோதரி",
  "சமைப்பு",
  "சளிப்பு",
  "சவத்தி",
  "சதிபதி",
  "சயம்பு",
  "சதுரன்",
  "சரமாரி",
  "சலாங்கு",
  "சன்மினி",
  "சகாயன்",
  "சனிமூலை",
  "சகபாடி",
  "சர்த்தி",
  "சட்டம்",
  "சவடன்",
  "சன்னதி",
  "சருவம்",
  "சகச்சை",
  "சபீனம்",
  "சருவரி",
  "சவரன்",
  "சபரன்",
  "சகளம்",
  "சவணம்",
  "சடாடவி",
  "சத்திலி",
  "சருவல்",
  "சட்டன்",
  "சலாவணி",
  "சக்கல்",
  "சமைதல்",
  "சசிவன்",
  "சகுணம்",
  "சளுக்கி",
  "சளுக்கு",
  "சத்திரி",
  "சகாயம்",
  "சமசம்",
  "சவுசம்",
  "சகளன்",
  "சசுபம்",
  "சதிகொலை",
  "சண்டாளி",
  "சற்குரு",
  "சமணம்",
  "சரிபாதி",
  "சமித்து",
  "சண்டிகை",
  "சடாமுனி",
  "சயகம்",
  "சமபூமி",
  "சலசம்",
  "சதகம்",
  "சம்பவை",
  "சறுக்கை",
  "சகதேவி",
  "சறுக்கு",
  "சவரம்",
  "சஞ்சீவி",
  "சமூகம்",
  "சபரம்",
  "சப்பன்",
  "சடிலம்",
  "சடரம்",
  "சனியன்",
  "சரிபம்",
  "சடாகம்",
  "சங்காரி",
  "சகசம்",
  "சமரம்",
  "சகசோதி",
  "சராசரி",
  "சகிதன்",
  "சரமண்",
  "சளகன்",
  "சளுகன்",
  "சபாபதி",
  "சரமணி",
  "சனனம்",
  "சட்பம்",
  "சமிபாடு",
  "சபிண்டி",
  "சப்பரை",
  "சகசன்",
  "சதளம்",
  "சவையல்",
  "சமணர்",
  "சளுகம்",
  "சவுளம்",
  "சதவீரு",
  "சப்பல்",
  "சம்பிளி",
  "சசியம்",
  "சமாசம்",
  "சரித்து",
  "சரமம்",
  "சங்கடை",
  "சமேதன்",
  "சலிலம்",
  "சங்கடி",
  "சதாபதி",
  "சலவாதி",
  "சன்னாசி",
  "சராகம்",
  "சக்குபு",
  "சமனிசை",
  "சதையல்",
  "சபரியை",
  "சமத்து",
  "சமேதம்",
  "சங்கியை",
  "சடைச்சி",
  "சதயம்",
  "சரிமணி",
  "சகிதம்",
  "சங்கதி",
  "சகசரி",
  "சராளம்",
  "சதங்கை",
  "சரபடி",
  "சப்பாணி",
  "சதையம்",
  "சரபம்",
  "சடாமுடி",
  "சமாப்தி",
  "சகோடம்",
  "சரகதி",
  "சவுடால்",
  "சபையார்",
  "சவதலை",
  "சகோரம்",
  "சகணம்",
  "சம்பம்",
  "சமசியை",
  "சரமழை",
  "சசகதி",
  "சருத்தி",
  "சங்கிதை",
  "சரவாணி",
  "சரிவர",
  "சபினம்",
  "சப்படி",
  "சம்பரி",
  "சமாளம்",
  "சவதம்",
  "சதேரன்",
  "சங்கினி",
  "சபதம்",
  "சம்பளி",
  "சமிலாகி",
  "சந்தம்",
  "சஞ்சம்",
  "சமுகம்",
  "சசகம்",
  "சவுக்கு",
  "சதேகரு",
  "சடாலம்",
  "சவுக்கை",
  "சந்தில்",
  "சச்சடி",
  "சகடம்",
  "சடையன்",
  "சந்தியை",
  "சமங்கை",
  "சகரர்",
  "சரீரம்",
  "சல்லிசு",
  "சககாரி",
  "சஞ்சலை",
  "சடங்கு",
  "சமீரன்",
  "சனபதி",
  "சமையல்",
  "சம்மதி",
  "சர்மம்",
  "சவையார்",
  "சடக்கு",
  "சமநிலை",
  "சல்லிகை",
  "சவிக்கை",
  "சந்ததி",
  "சரளம்",
  "சமூலம்",
  "சடம்பு",
  "சந்தணி",
  "சகுனம்",
  "சவனன்",
  "சலவன்",
  "சல்லாரி",
  "சமிஞ்ஞை",
  "சறைமணி",
  "சமதலை",
  "சந்தடி",
  "சரகம்",
  "சமனிகை",
  "சயிப்பு",
  "சனமாலி",
  "சதிரம்",
  "சரசோதி",
  "சலங்கு",
  "சலங்கை",
  "சமானம்",
  "சதுமணி",
  "சகிப்பு",
  "சத்தம்",
  "சரிதன்",
  "சமரேகை",
  "சச்சரி",
  "சடாதாரி",
  "சச்சம்",
  "சனகம்",
  "சத்தமி",
  "சராப்பு",
  "சடினம்",
  "சச்சரை",
  "சமசோடி",
  "சதோகரு",
  "சலவர்",
  "சலதம்",
  "சத்தன்",
  "சவனம்",
  "சரிதம்",
  "சகண்டை",
  "சல்லம்",
  "சரசம்",
  "சவலம்",
  "சன்னிதி",
  "சபலம்",
  "சடலம்",
  "சரிதல்",
  "சதிங்கி",
  "சலாபம்",
  "சமிதம்",
  "சமனம்",
  "சடைமுடி",
  "சவுத்து",
  "சண்பனி",
  "சமாலம்",
  "சல்லரி",
  "சததாரை",
  "சம்சாரி",
  "சந்தரி",
  "சமீரணி",
  "சாலியன்",
  "சார்வணை",
  "சாவரம்",
  "சாகாரம்",
  "சாவறுதி",
  "சாரூபம்",
  "சாணகம்",
  "சாமகம்",
  "சாக்குறி",
  "சார்த்து",
  "சாடைமாடை",
  "சாலாரம்",
  "சாகரம்",
  "சாப்பறை",
  "சாலுகம்",
  "சாவயம்",
  "சாணாங்கி",
  "சாணங்கி",
  "சாப்பாடு",
  "சாத்தம்",
  "சாதுரன்",
  "சாதகம்",
  "சாக்தன்",
  "சாயகம்",
  "சாக்தம்",
  "சாலேயம்",
  "சாயாநீர்",
  "சாகியம்",
  "சாருசம்",
  "சார்வோலை",
  "சாத்தர்",
  "சாகுபடி",
  "சாமணம்",
  "சாலோகம்",
  "சாமளம்",
  "சாதுரம்",
  "சாயினம்",
  "சாய்ப்பு",
  "சாரகம்",
  "சாதீயம்",
  "சாத்தல்",
  "சாடுதல்",
  "சாத்தவி",
  "சாடாவாக",
  "சாரசன்",
  "சார்ச்சி",
  "சாரியன்",
  "சாலாதார்",
  "சாயவேர்",
  "சாசிபம்",
  "சாறுதல்",
  "சாவணம்",
  "சாதுயர்",
  "சாத்தான்",
  "சாம்பம்",
  "சாரங்கி",
  "சாருகம்",
  "சாத்தானி",
  "சாளரம்",
  "சாதனம்",
  "சாபலம்",
  "சாகளம்",
  "சாலுதல்",
  "சாளியல்",
  "சாதவேதா",
  "சாயனம்",
  "சாதாவேலி",
  "சாரசம்",
  "சாட்குலி",
  "சாடுவர்",
  "சாதிசம்",
  "சாதரூபி",
  "சாமீபம்",
  "சாமேளம்",
  "சாதேவம்",
  "சாருகன்",
  "சாதகன்",
  "சாடுகம்",
  "சாசனம்",
  "சாந்தன்",
  "சால்புளி",
  "சாமுண்டி",
  "சாலூகம்",
  "சாரிதம்",
  "சாம்பன்",
  "சாந்தம்",
  "சாம்பவி",
  "சாளகம்",
  "சாடுமாலி",
  "சார்தல்",
  "சாயாபதி",
  "சாத்துவி",
  "சாலேகம்",
  "சான்றோர்",
  "சாவகன்",
  "சாரியல்",
  "சாற்றோலை",
  "சாமுதம்",
  "சார்மணை",
  "சானகம்",
  "சாணாத்தி",
  "சாரீரம்",
  "சாகாமுலி",
  "சாதாரண",
  "சாம்பர்",
  "சாகதன்",
  "சாத்துறி",
  "சாரியம்",
  "சார்வரி",
  "சாலூரம்",
  "சாடவம்",
  "சார்ப்பு",
  "சாத்திரி",
  "சாவகம்",
  "சாராயம்",
  "சாளைவாய்",
  "சாம்பல்",
  "சாக்காடு",
  "சாணாகம்",
  "சாதனன்",
  "சாமந்தி",
  "சாக்கடை",
  "சாலகிரி",
  "சாதிகம்",
  "சாங்கம்",
  "சாம்பார்",
  "சாறுதாரி",
  "சாலகன்",
  "சாகசன்",
  "சாட்கோல்",
  "சாதீகம்",
  "சாக்காளி",
  "சாணாக்கி",
  "சாதிமுறை",
  "சாதுவன்",
  "சாரதம்",
  "சாய்கால்",
  "சாவாமூலி",
  "சாதலம்",
  "சாதிமான்",
  "சாரிசம்",
  "சாறுவேளை",
  "சாரதர்",
  "சாரிபம்",
  "சாமரம்",
  "சாம்பான்",
  "சாளிகம்",
  "சாத்தன்",
  "சாய்தல்",
  "சாலக்கு",
  "சாகசம்",
  "சாலகம்",
  "சாலிகன்",
  "சாதுகம்",
  "சாரணர்",
  "சான்மலி",
  "சாபாலன்",
  "சாரலம்",
  "சாய்மணை",
  "சாமியம்",
  "சாவட்டை",
  "சாட்டம்",
  "சான்றோன்",
  "சாதாரம்",
  "சான்றார்",
  "சில்லுணா",
  "சின்னூல்",
  "சிடுக்கு",
  "சிங்ஙுவை",
  "சிலியானை",
  "சிகண்டி",
  "சிறுமுறி",
  "சிறிவில்",
  "சிள்வீடு",
  "சின்னீர்",
  "சிதுரம்",
  "சிசுகம்",
  "சிறப்பு",
  "சிவசன்",
  "சிரட்டை",
  "சிலாசம்",
  "சிரகம்",
  "சிறாங்கை",
  "சிறுகுடி",
  "சிறுநெறி",
  "சிறுநகை",
  "சிரவம்",
  "சிறுபசி",
  "சிமட்டி",
  "சித்தல்",
  "சிற்றெண்",
  "சிறுமணி",
  "சினைப்பு",
  "சினைப்பூ",
  "சிலதன்",
  "சிகைமாலை",
  "சிமாளம்",
  "சின்மதி",
  "சின்னாயி",
  "சித்தர்",
  "சிறுவீடு",
  "சிமிண்டு",
  "சிதடன்",
  "சித்தரி",
  "சிவுகம்",
  "சிரேட்டி",
  "சிள்ளீடு",
  "சிதிரம்",
  "சிலமம்",
  "சிதனம்",
  "சிக்கம்",
  "சிறுநணி",
  "சிதுரன்",
  "சிங்காணி",
  "சித்தம்",
  "சிறைநோய்",
  "சிற்றிலை",
  "சிமிலம்",
  "சிருங்கி",
  "சிற்றில்",
  "சிட்டம்",
  "சிசுவதை",
  "சிறைவன்",
  "சிங்காடி",
  "சிக்கர்",
  "சிற்றுள்",
  "சிம்பல்",
  "சிலம்பு",
  "சிற்றிரை",
  "சிலம்பி",
  "சிற்றிரு",
  "சிலுநீர்",
  "சிறுவிலை",
  "சிட்டர்",
  "சிறுகாய்",
  "சித்தன்",
  "சிதிலம்",
  "சிட்டன்",
  "சிறுதகை",
  "சிறுபெண்",
  "சிறுகாலை",
  "சிறுகால்",
  "சிக்கல்",
  "சிற்றுளி",
  "சிகரம்",
  "சிபாரிசு",
  "சிறுத்தை",
  "சில்லிகை",
  "சினேகம்",
  "சிற்றுரு",
  "சிகுரம்",
  "சிலாமணி",
  "சிவயோகி",
  "சிவதூதி",
  "சிறுதுடி",
  "சிறுநீர்",
  "சிணாட்டு",
  "சிற்றூர்",
  "சிறுதிசை",
  "சிஞ்ஞாசு",
  "சிரோசம்",
  "சிலுகன்",
  "சிவகம்",
  "சிரமம்",
  "சிறுபறை",
  "சிரநதி",
  "சிமிட்டா",
  "சிமிட்டு",
  "சிமிட்டி",
  "சினாடிகா",
  "சிலமன்",
  "சியேனம்",
  "சிறுமலை",
  "சிறுசொல்",
  "சிறுபூளை",
  "சில்லூறு",
  "சிறுமல்",
  "சில்லான்",
  "சிறுதடி",
  "சிகிச்சை",
  "சிவஞானி",
  "சிற்றவை",
  "சிப்பம்",
  "சிகிலம்",
  "சிகைதாடு",
  "சிறுநனி",
  "சிற்சபை",
  "சிறுதேர்",
  "சிற்றெலி",
  "சிற்றாடை",
  "சிவபூசை",
  "சிந்தடி",
  "சிறகர்",
  "சிறுதாலி",
  "சிம்புரி",
  "சிவகதி",
  "சிறுதாரை",
  "சிலேட்டி",
  "சிக்கடி",
  "சிறுமூசை",
  "சிங்காரி",
  "சிலுவல்",
  "சிறுவம்",
  "சிரையன்",
  "சிலுவலி",
  "சிங்கம்",
  "சினீவாலி",
  "சிறுவாடு",
  "சிவிட்கு",
  "சிரிப்பு",
  "சிற்றூண்",
  "சிறுதிகை",
  "சிறைதல்",
  "சிருக்கு",
  "சிரமன்",
  "சிலாமனா",
  "சிறுவன்",
  "சிசுரம்",
  "சிந்துரை",
  "சிங்கல்",
  "சிலைநார்",
  "சிவகாமி",
  "சிறுவோர்",
  "சின்னகை",
  "சிரௌதம்",
  "சில்லம்",
  "சிருகம்",
  "சில்லறை",
  "சிந்தன்",
  "சிந்தனை",
  "சிறுக்கி",
  "சித்திலி",
  "சில்லாடை",
  "சித்திரை",
  "சில்லரி",
  "சிலாபம்",
  "சில்லர்",
  "சிறங்கை",
  "சிராந்தி",
  "சின்மொழி",
  "சிமுட்டி",
  "சிச்சிலி",
  "சிதவல்",
  "சிறியன்",
  "சிசிரம்",
  "சிலையாவி",
  "சிறுவரை",
  "சித்துரு",
  "சிற்றாள்",
  "சிம்புள்",
  "சினேசம்",
  "சிவதடி",
  "சிம்புளி",
  "சிறுகல்",
  "சிங்கன்",
  "சிசுரூடை",
  "சிலுக்கு",
  "சினப்பு",
  "சிம்பிலி",
  "சிறுவல்",
  "சிரங்கு",
  "சிந்தம்",
  "சிரோமணி",
  "சிருதம்",
  "சிரங்கை",
  "சிற்றால்",
  "சிமையம்",
  "சிறுகுழி",
  "சிரீடம்",
  "சிரேயசு",
  "சிறுசெய்",
  "சிவசிவ",
  "சிதர்வை",
  "சினைவினை",
  "சிற்றாறு",
  "சிறுமீன்",
  "சிதகன்",
  "சிலேதம்",
  "சிசுரூசை",
  "சிலாலேகை",
  "சில்வாய்",
  "சிதையர்",
  "சித்தினி",
  "சிகழிகை",
  "சிறுவாள்",
  "சிதாரம்",
  "சிவிங்கி",
  "சில்சொல்",
  "சிவந்தி",
  "சிலாம்பு",
  "சின்னது",
  "சிதகம்",
  "சிணுக்கு",
  "சிறியிலை",
  "சிரத்தை",
  "சிஞ்சினீ",
  "சிறுசோறு",
  "சினகர்",
  "சிவ்வல்",
  "சிசினம்",
  "சினவர்",
  "சிதைதல்",
  "சிவப்பி",
  "சில்பலி",
  "சிவப்பு",
  "சிக்குரு",
  "சித்தேரி",
  "சினவல்",
  "சிலந்தி",
  "சிகிடிமா",
  "சின்னன்",
  "சித்துடு",
  "சிகதம்",
  "சிவிரம்",
  "சிங்கினி",
  "சிலுப்பு",
  "சிருட்டி",
  "சிலாந்தி",
  "சிவமது",
  "சிறுகீரை",
  "சிகிமுனி",
  "சிக்குவை",
  "சிற்பன்",
  "சிகரிகை",
  "சிலும்பு",
  "சிவநிசி",
  "சிதம்பு",
  "சின்னம்",
  "சிறுவிடு",
  "சின்னெறி",
  "சிலகம்",
  "சிரமிலி",
  "சிறுகாடு",
  "சிங்கிலி",
  "சிதரம்",
  "சிலாப்பி",
  "சிமயம்",
  "சிமிக்கி",
  "சிரந்தை",
  "சினாவில்",
  "சிலவர்",
  "சின்னல்",
  "சிற்பம்",
  "சிங்குவை",
  "சிரீமுக",
  "சிவச்சி",
  "சிறாம்பி",
  "சிலாபேசி",
  "சிறாம்பு",
  "சிற்பரை",
  "சிக்கென",
  "சிற்பர்",
  "சிறைவீடு",
  "சிம்மம்",
  "சிற்றடி",
  "சிவளிகை",
  "சிகாமணி",
  "சீரகர்",
  "சீவதயை",
  "சீமத்து",
  "சீதபானு",
  "சீதரன்",
  "சீமணல்",
  "சீரிணன்",
  "சீலாந்தி",
  "சீவதாது",
  "சீர்கேடு",
  "சீர்கேடி",
  "சீவகர்",
  "சீதளம்",
  "சீகாரம்",
  "சீவதம்",
  "சீரியது",
  "சீண்டல்",
  "சீரணம்",
  "சீம்பால்",
  "சீவகளை",
  "சீவந்தி",
  "சீவகோடி",
  "சீர்த்தி",
  "சீரமோடா",
  "சீமூதம்",
  "சீந்தில்",
  "சீபலம்",
  "சீராட்டு",
  "சீக்காய்",
  "சீவையர்",
  "சீதனம்",
  "சீலைமண்",
  "சீதநீர்",
  "சீனப்பா",
  "சீவரேகை",
  "சீதாபதி",
  "சீபாதம்",
  "சீர்பதி",
  "சீயெனல்",
  "சீலவான்",
  "சீவனம்",
  "சீக்கிரி",
  "சீதகன்",
  "சீறுமாறு",
  "சீவகன்",
  "சீவரம்",
  "சீறியாழ்",
  "சீதபேதி",
  "சீவியம்",
  "சீட்டாள்",
  "சீரிப்பு",
  "சீதகம்",
  "சீவதன்",
  "சீவுதல்",
  "சீகரம்",
  "சீருணம்",
  "சீவடம்",
  "சீழ்க்கை",
  "சீறுதல்",
  "சீக்குரு",
  "சீவரர்",
  "சீர்ப்பு",
  "சீத்தல்",
  "சீரியார்",
  "சீமாட்டி",
  "சீவிதம்",
  "சீக்கல்",
  "சீவதசை",
  "சீவான்மை",
  "சீலனம்",
  "சீரிடம்",
  "சீவாளம்",
  "சீறளவு",
  "சீற்றம்",
  "சீப்பால்",
  "சீரகம்",
  "சீமுகம்",
  "சீதாளம்",
  "சீந்தல்",
  "சீசகம்",
  "சீவகம்",
  "சீராளன்",
  "சீவராசி",
  "சீராகம்",
  "சீமூதன்",
  "சுனகன்",
  "சுற்றாலை",
  "சுரதலை",
  "சுளிக்கு",
  "சுடுபடை",
  "சும்புள்",
  "சுகிதம்",
  "சுளிதல்",
  "சுளையம்",
  "சுவசம்",
  "சுக்கிலை",
  "சுவனம்",
  "சுறணம்",
  "சுமப்பு",
  "சுலுகம்",
  "சுழியம்",
  "சுண்டான்",
  "சுதாவாய்",
  "சுழுமுனை",
  "சுடுமண்",
  "சுவாபம்",
  "சுட்கம்",
  "சுதாமனு",
  "சுளுகோடி",
  "சுகசீவி",
  "சுத்துரு",
  "சுராட்டு",
  "சுவேதனை",
  "சுபாவம்",
  "சுடுகோல்",
  "சுழல்வு",
  "சுழியல்",
  "சுவேகம்",
  "சுரளிகை",
  "சுனாவணி",
  "சுறுக்கு",
  "சுழுத்தி",
  "சுதாநிதி",
  "சுமதலை",
  "சுகியன்",
  "சுரகுரு",
  "சுழற்சி",
  "சுரண்டி",
  "சுற்றம்",
  "சுருட்டை",
  "சுருட்டு",
  "சுருட்டி",
  "சுரப்பு",
  "சுரதம்",
  "சுயேச்சை",
  "சுவேதம்",
  "சுல்லம்",
  "சுரதரு",
  "சுறண்டி",
  "சுதலம்",
  "சுண்டெலி",
  "சுகுடம்",
  "சுரேசன்",
  "சுவாதம்",
  "சுதனம்",
  "சுகுச்சை",
  "சுனுக்கு",
  "சுசீலம்",
  "சுருக்கை",
  "சுடுநீர்",
  "சுருக்கி",
  "சுருக்கு",
  "சுரும்பு",
  "சுற்கம்",
  "சுடுவான்",
  "சுட்டல்",
  "சுழுந்து",
  "சுகபேதி",
  "சுரிமண்",
  "சுலபம்",
  "சுடுசொல்",
  "சுசுகம்",
  "சுழிப்பு",
  "சுகவாரி",
  "சுருக்க",
  "சுகரம்",
  "சுகிப்பு",
  "சும்பன்",
  "சுசீலன்",
  "சுலோபம்",
  "சுழலன்",
  "சுராளம்",
  "சுறட்டை",
  "சுதேசம்",
  "சுக்கில",
  "சுரநதி",
  "சுறட்டு",
  "சுரூபம்",
  "சுளுக்கி",
  "சுமத்தி",
  "சுளுக்கு",
  "சுத்தம்",
  "சுப்பிரி",
  "சுறாளம்",
  "சுரமலி",
  "சுள்ளான்",
  "சுறாமுள்",
  "சுபதம்",
  "சுற்பம்",
  "சுண்டில்",
  "சுனைதல்",
  "சுபுகம்",
  "சுங்கான்",
  "சுவேச்சை",
  "சுன்னம்",
  "சுழற்று",
  "சுழற்றி",
  "சுவண்டு",
  "சுடீரம்",
  "சுதந்தி",
  "சுரமதி",
  "சுலோகம்",
  "சுளுந்து",
  "சுபாங்கி",
  "சுடுதல்",
  "சுகதாரு",
  "சுரியூசி",
  "சும்மாது",
  "சுபாங்கை",
  "சுட்டணி",
  "சுடுவல்",
  "சுர்க்கி",
  "சுவாசம்",
  "சுதிலயை",
  "சுளகம்",
  "சுரபதி",
  "சுள்ளாணி",
  "சுரநடை",
  "சுவானம்",
  "சும்மாடு",
  "சுத்தன்",
  "சுதாவில்",
  "சுகந்தி",
  "சுசுருசை",
  "சுல்கம்",
  "சுக்கான்",
  "சுகுணம்",
  "சுள்ளம்",
  "சுல்வம்",
  "சுசிகம்",
  "சுந்தரி",
  "சுக்கம்",
  "சுரியல்",
  "சுசிதம்",
  "சுணைப்பு",
  "சுண்டல்",
  "சுடுபுண்",
  "சுமங்கை",
  "சுற்றுலா",
  "சுருளல்",
  "சுடுகாடு",
  "சுபாலிகை",
  "சுள்ளல்",
  "சுமையாள்",
  "சுக்கல்",
  "சுமடன்",
  "சுமுகம்",
  "சுற்றும்",
  "சுறவம்",
  "சுருங்கு",
  "சுவையணி",
  "சுருங்கை",
  "சுமக்க",
  "சுரசம்",
  "சுமைதலை",
  "சுவடன்",
  "சுமுகன்",
  "சுங்கன்",
  "சுருதம்",
  "சுழியாணி",
  "சுரியாணி",
  "சுலுப்பு",
  "சுரிதல்",
  "சுண்டன்",
  "சுபேதார்",
  "சுடுகண்",
  "சுருவம்",
  "சுசூகம்",
  "சுசனம்",
  "சுழிமுனை",
  "சுமரணை",
  "சுவரம்",
  "சுலனன்",
  "சுடுசோறு",
  "சுவரறை",
  "சுதகம்",
  "சுண்டம்",
  "சுரிதம்",
  "சுமைகூலி",
  "சுட்டிகை",
  "சுதினம்",
  "சுமையடை",
  "சுணங்கு",
  "சுயமாய்",
  "சுவுகம்",
  "சுண்டிகை",
  "சுகதன்",
  "சுடரோன்",
  "சுயம்பு",
  "சுதாச்சி",
  "சுணங்கை",
  "சுதும்பு",
  "சுவரணை",
  "சுழுனானை",
  "சுடுவன்",
  "சுண்டாலி",
  "சுதன்மை",
  "சுத்திகை",
  "சுசனன்",
  "சுகதம்",
  "சுப்பல்",
  "சுழிதல்",
  "சுமந்த",
  "சுசிரம்",
  "சுகவாசி",
  "சுரசனி",
  "சுண்ணம்",
  "சுகோததி",
  "சுழியன்",
  "சுபோதம்",
  "சுவணம்",
  "சுசுலம்",
  "சுடுபொன்",
  "சுங்கம்",
  "சூலைநீர்",
  "சூழ்ச்சி",
  "சூரணிகை",
  "சூள்தல்",
  "சூசகன்",
  "சூசிகம்",
  "சூளிகம்",
  "சூர்ப்பு",
  "சூளாமணி",
  "சூதகம்",
  "சூறாவாரி",
  "சூச்சான்",
  "சூலுதல்",
  "சூபகன்",
  "சூதுகளி",
  "சூருமம்",
  "சூக்காய்",
  "சூவானம்",
  "சூகரம்",
  "சூடிச்சி",
  "சூழ்தல்",
  "சூறாவளி",
  "சூழ்வளி",
  "சூழியல்",
  "சூடகம்",
  "சூலபாணி",
  "சூர்ணம்",
  "சூதநதி",
  "சூக்குளி",
  "சூதவம்",
  "சூலானோன்",
  "சூன்றல்",
  "சூடாலம்",
  "சூவெனல்",
  "சூனியன்",
  "சூதானம்",
  "சூட்டாணி",
  "சூர்தல்",
  "சூராட்டி",
  "சூசகம்",
  "சூதசாலை",
  "சூழியம்",
  "சூத்திரி",
  "சூரினர்",
  "சூதுவாது",
  "சூரணம்",
  "சூழ்வினை",
  "சூனியம்",
  "சூடாமணி",
  "சூளுறவு",
  "சூசுகம்",
  "சூலவேல்",
  "சூழ்கோடை",
  "சூழ்வோர்",
  "சூசிக்கை",
  "சூசியம்",
  "சூழ்தாழை",
  "சூதரம்",
  "சூர்ணிகை",
  "சூறையர்",
  "சூழ்வார்",
  "சூளறவு",
  "சூரியன்",
  "சூசிகன்",
  "சூறுதல்",
  "சூதனம்",
  "சூக்கம்",
  "சூலிகம்",
  "சூசனம்",
  "சூடுதல்",
  "சென்னல்",
  "செம்மலை",
  "செம்மல்",
  "செத்தல்",
  "செறுவர்",
  "செவியேறு",
  "செக்கல்",
  "செடிச்சி",
  "செந்நெறி",
  "செஞ்சாலி",
  "செங்கண்",
  "செட்டிமை",
  "செருப்பு",
  "செப்பட",
  "செந்திரு",
  "செப்பேடு",
  "செல்வழி",
  "சென்றது",
  "செங்கோல்",
  "செக்கர்",
  "செருநர்",
  "செறிதல்",
  "செஞ்ஞானி",
  "செற்றோர்",
  "செம்மீன்",
  "செந்துறை",
  "செழுமறை",
  "செந்தூள்",
  "செய்யான்",
  "செம்மறி",
  "செம்பொன்",
  "செம்பாலை",
  "செல்லம்",
  "செம்பால்",
  "செந்தொடை",
  "செக்கம்",
  "செளிம்பு",
  "செங்கொல்",
  "செலுந்தி",
  "செம்பாளை",
  "செருகுபூ",
  "செப்பிலை",
  "செறுதல்",
  "செல்வுழி",
  "செதுமொழி",
  "செங்ண்மா",
  "செல்வம்",
  "செய்பாவை",
  "செறிப்பு",
  "செப்படி",
  "செல்லன்",
  "செம்மான்",
  "செய்கால்",
  "செந்தினை",
  "செங்கொள்",
  "செதுவல்",
  "செலியம்",
  "செஞ்சிலை",
  "செய்சுனை",
  "செய்யார்",
  "செதுக்கை",
  "செழிப்பு",
  "செதுக்கு",
  "செம்பலா",
  "செலவாளி",
  "செல்லாறு",
  "செதுக்கி",
  "செறிஞர்",
  "செம்பொடி",
  "செற்றம்",
  "செம்மொழி",
  "செங்கார்",
  "செகதம்",
  "செந்தாளி",
  "செந்தாள்",
  "செங்காய்",
  "செலவடை",
  "செங்காரி",
  "செந்தாழை",
  "செந்தரா",
  "செந்தில்",
  "செந்தார்",
  "செம்பகை",
  "செவியுறை",
  "செவியான்",
  "செய்கரை",
  "செவியடி",
  "செலாவணி",
  "செங்காலி",
  "செல்லாமை",
  "செருந்தி",
  "செருந்து",
  "செந்நெல்",
  "செந்தலை",
  "செங்காவி",
  "சென்னடை",
  "செம்புள்",
  "செறுப்பு",
  "செவ்வண்",
  "செழுத்து",
  "செவ்வணி",
  "செல்லல்",
  "செழிச்சி",
  "செழிதல்",
  "செல்சார்",
  "செம்மாளி",
  "செவிரம்",
  "செலுப்பு",
  "செம்பாடு",
  "செம்மணி",
  "செழுகம்",
  "செங்களி",
  "செங்கோடு",
  "செய்தல்",
  "சென்மம்",
  "செவியம்",
  "செஞ்செவே",
  "செல்கதி",
  "செவ்வரி",
  "செழுந்து",
  "செக்கடி",
  "செடகன்",
  "செழியன்",
  "செந்நிலை",
  "செவியிலி",
  "செலாமணி",
  "செந்நீர்",
  "செப்பாடு",
  "செருமல்",
  "செற்றல்",
  "செறிவன்",
  "செய்யாள்",
  "செதும்பு",
  "செம்பாதி",
  "செருத்தி",
  "செங்கீரை",
  "செம்மண்",
  "செய்யன்",
  "செவியன்",
  "செபமாலை",
  "செந்தேன்",
  "செப்பம்",
  "செல்வன்",
  "செந்தாது",
  "செஞ்சொல்",
  "செளைப்பு",
  "செங்கிடை",
  "செவிமறை",
  "செம்படை",
  "செம்பொறி",
  "செருவிளை",
  "செக்கான்",
  "செப்போடு",
  "செங்காடு",
  "செப்பலி",
  "செறும்பு",
  "செப்பல்",
  "செலதம்",
  "செளுகம்",
  "சென்னம்",
  "செருமுனை",
  "செற்றார்",
  "செவ்வல்",
  "செலுவன்",
  "செஞ்சோறு",
  "செண்ணம்",
  "செங்கல்",
  "செய்குறை",
  "செஞ்சம்",
  "செனகன்",
  "செய்யல்",
  "செலகம்",
  "செறுநர்",
  "செவியறை",
  "செம்புண்",
  "செய்கூலி",
  "செக்கணி",
  "சேதாரம்",
  "சேர்ப்பு",
  "சேபாலம்",
  "சேட்படை",
  "சேவிதம்",
  "சேய்த்து",
  "சேர்ச்சி",
  "சேட்டன்",
  "சேலேகம்",
  "சேலவன்",
  "சேரலன்",
  "சேக்காளி",
  "சேர்தல்",
  "சேரிமடை",
  "சேய்நீர்",
  "சேலோதம்",
  "சேணியன்",
  "சேட்டம்",
  "சேடகம்",
  "சேமநிதி",
  "சேவகம்",
  "சேவுகம்",
  "சேனாபதி",
  "சேந்தன்",
  "சேதனன்",
  "சேர்த்தி",
  "சேருகம்",
  "சேவலோன்",
  "சேமணன்",
  "சேணாடர்",
  "சேதிமம்",
  "சேவகன்",
  "சேகரம்",
  "சேகண்டி",
  "சேக்கோள்",
  "சேர்கால்",
  "சேரமான்",
  "சேதகம்",
  "சேதியம்",
  "சேமரம்",
  "சேதனம்",
  "சேரங்கை",
  "சேலகம்",
  "சேவையூர்",
  "சேகரன்",
  "சேணியர்",
  "சேர்க்கை",
  "சேடியம்",
  "சேயிலம்",
  "சேமவில்",
  "சேலியால்",
  "சேவியம்",
  "சையானம்",
  "சைதனம்",
  "சையகம்",
  "சையமினி",
  "சையோத்தி",
  "சைவவாதி",
  "சைரிகன்",
  "சைலகம்",
  "சைகதம்",
  "சைந்தவி",
  "சைரிபம்",
  "சைவலம்",
  "சைத்திரி",
  "சைவரல்",
  "சைத்தான்",
  "சைரிகம்",
  "சைக்கினை",
  "சையோகம்",
  "சைலபதி",
  "சைனியம்",
  "சையெனல்",
  "சைமானம்",
  "சைசவம்",
  "சொற்புள்",
  "சொடக்கு",
  "சொடுதலை",
  "சொல்வழு",
  "சொறிபுண்",
  "சொள்ளல்",
  "சொரிதல்",
  "சொன்னல்",
  "சொண்டன்",
  "சொன்மாரி",
  "சொற்பம்",
  "சொண்டிலி",
  "சொக்கறை",
  "சொற்பானு",
  "சொம்மாளி",
  "சொக்கலி",
  "சொத்தலி",
  "சொன்மாலை",
  "சொக்காளி",
  "சொணைப்பு",
  "சொன்னம்",
  "சொக்காலி",
  "சொல்வகை",
  "சொப்பட",
  "சொரூபம்",
  "சொகினம்",
  "சொந்தம்",
  "சொக்கம்",
  "சொச்சம்",
  "சொரிவாய்",
  "சொக்காய்",
  "சொலியன்",
  "சொண்டறை",
  "சொற்போர்",
  "சொர்ணம்",
  "சொல்லணி",
  "சொல்லாளி",
  "சொல்லுரை",
  "சொறியன்",
  "சொனாகம்",
  "சொலிப்பு",
  "சொக்கன்",
  "சொப்பம்",
  "சொற்பாடு",
  "சொற்சுவை",
  "சொர்னம்",
  "சொற்பழி",
  "சொட்டம்",
  "சொடிதல்",
  "சொரிதம்",
  "சொற்காரி",
  "சொற்பிழை",
  "சொறிதல்",
  "சொற்றல்",
  "சோபானம்",
  "சோதரன்",
  "சோடணம்",
  "சோர்தல்",
  "சோராவரி",
  "சோம்பேறி",
  "சோம்பாயி",
  "சோராவாரி",
  "சோங்கம்",
  "சோபாலிகை",
  "சோதிடம்",
  "சோத்தம்",
  "சோணங்கி",
  "சோடசம்",
  "சோலுதல்",
  "சோதிநாள்",
  "சோத்தும்",
  "சோர்ச்சி",
  "சோலைமலை",
  "சோதியான்",
  "சோம்பல்",
  "சோபிதம்",
  "சோசியம்",
  "சோதகம்",
  "சோழியன்",
  "சோணேசன்",
  "சோதியம்",
  "சோமதாரி",
  "சோமநாதி",
  "சோல்தல்",
  "சோகாப்பு",
  "சோனைமாரி",
  "சோதிடர்",
  "சோத்தாள்",
  "சோலிமாலி",
  "சோனகம்",
  "சோசியன்",
  "சோரகன்",
  "சோழகம்",
  "சோணாகம்",
  "சோற்றிலை",
  "சோம்பாகி",
  "சோளகம்",
  "சோழநாடு",
  "சோப்பம்",
  "சோனாமாரி",
  "சோனகன்",
  "சோணகிரி",
  "சோரகவி",
  "சோடிப்பு",
  "சோபனம்",
  "சோழகன்",
  "சோதிடன்",
  "சோதியன்",
  "சோடையன்",
  "சோம்பன்",
  "சோதகன்",
  "சோபலம்",
  "சோவெனல்",
  "சோசனம்",
  "சோமேசன்",
  "சோமதிசை",
  "சோணிதம்",
  "சோனாவாரி",
  "சௌரியம்",
  "சௌமியன்",
  "சௌரகன்",
  "சௌசேயன்",
  "சௌசிகன்",
  "சௌபானம்",
  "சௌமாரம்",
  "சௌக்கம்",
  "சௌவீரம்",
  "சௌமியம்",
  "சௌனிகன்",
  "சௌரியன்",
  "சௌகந்தி",
  "சௌதாயம்",
  "சௌகதன்",
  "சௌந்தரி",
  "ஞஃகான்",
  "ஞரிவாளை",
  "ஞலவல்",
  "ஞானநிலை",
  "ஞானவான்",
  "ஞாலுதல்",
  "ஞானக்கை",
  "ஞாஞ்சில்",
  "ஞாளிதம்",
  "ஞாலமாது",
  "ஞாத்தல்",
  "ஞாங்கர்",
  "ஞாறுதல்",
  "ஞானசபை",
  "ஞாதேயம்",
  "ஞாபகம்",
  "ஞானபூசை",
  "ஞெலுவன்",
  "ஞெமிதல்",
  "ஞெலிதல்",
  "ஞெள்ளல்",
  "ஞெளிதல்",
  "ஞெலுவல்",
  "ஞெரிதல்",
  "ஞெள்தல்",
  "ஞெகிழம்",
  "ஞெலிகோல்",
  "தடித்து",
  "தனேசன்",
  "தந்தார்",
  "தனதாள்",
  "தகவுரை",
  "தன்னிலை",
  "தண்ணம்",
  "தனவனா",
  "தகுளம்",
  "தந்தாம்",
  "தரையோடு",
  "தலையாரி",
  "தழற்சி",
  "தனதன்",
  "தண்ணீர்",
  "தசிரம்",
  "தன்தரை",
  "தய்யான்",
  "தருப்பு",
  "தருப்பி",
  "தருப்பை",
  "தப்பல்",
  "தருக்கி",
  "தருக்கு",
  "தங்கம்",
  "தளையல்",
  "தப்பளை",
  "தவசம்",
  "தகரார்",
  "தடைதல்",
  "தளைவார்",
  "தன்னினி",
  "தவாநிலை",
  "தலகம்",
  "தலைப்பு",
  "தலைப்பா",
  "தகராறு",
  "தரவழி",
  "தலையிடி",
  "தருநன்",
  "தண்டம்",
  "தணியல்",
  "தரிப்பு",
  "தறிதல்",
  "தடாதகை",
  "தறிதலை",
  "தங்கல்",
  "தண்டன்",
  "தளையம்",
  "தண்டனை",
  "தசுமன்",
  "தனுமணி",
  "தலாடம்",
  "தன்பாடு",
  "தண்ணளி",
  "தறிவலை",
  "தனுரேகை",
  "தமயன்",
  "தங்கள்",
  "தலைவலி",
  "தறுகணி",
  "தக்கது",
  "தவநிலை",
  "தலசம்",
  "தக்கணை",
  "தடிமன்",
  "தலைதல்",
  "தண்ணடை",
  "தராசம்",
  "தளிதல்",
  "தழுவல்",
  "தசனம்",
  "தன்பிடி",
  "தக்கடை",
  "தலைநாள்",
  "தனுசாரி",
  "தம்புரா",
  "தக்கடி",
  "தளுகன்",
  "தலைநோய்",
  "தளிவம்",
  "தபோதன்",
  "தக்கியா",
  "தறுகண்",
  "தப்பறை",
  "தளம்பு",
  "தக்கிணை",
  "தலைக்கை",
  "தசநாடி",
  "தகத்து",
  "தம்முன்",
  "தனிமுடி",
  "தம்பலை",
  "தழைதல்",
  "தம்பலி",
  "தளபதி",
  "தம்பல்",
  "தன்மானி",
  "தலவாரி",
  "தழும்பு",
  "தலைநோவு",
  "தமசம்",
  "தழுவணை",
  "தழுவணி",
  "தல்லிகை",
  "தறுவாய்",
  "தர்பார்",
  "தழுதணை",
  "தட்சணை",
  "தனிசர்",
  "தலைவன்",
  "தத்தாரி",
  "தம்மான்",
  "தவக்கு",
  "தவக்கை",
  "தருணன்",
  "தனிநிலை",
  "தக்கோலி",
  "தம்பனை",
  "தழலாடி",
  "தக்தம்",
  "தம்போலி",
  "தளப்பு",
  "தருணம்",
  "தந்துவை",
  "தரபடி",
  "தசமம்",
  "தத்தம்",
  "தசைப்பு",
  "தம்பர்",
  "தடாகம்",
  "தப்படி",
  "தன்வினை",
  "தகரடி",
  "தனிகம்",
  "தம்மோன்",
  "தம்பம்",
  "தடுக்கு",
  "தற்பின்",
  "தக்காளி",
  "தம்பதி",
  "தடிப்பு",
  "தலையீடு",
  "தம்மனை",
  "தக்காரி",
  "தமரம்",
  "தசகூலி",
  "தட்பம்",
  "தசைநார்",
  "தனித்தி",
  "தந்துரை",
  "தக்கார்",
  "தம்மோய்",
  "தயாவம்",
  "தன்பேறு",
  "தக்கோர்",
  "தவண்டை",
  "தமுக்கு",
  "தனியன்",
  "தகரம்",
  "தரகன்",
  "தவ்வல்",
  "தக்கேசி",
  "தம்பூரு",
  "தடுப்பு",
  "தளுக்கு",
  "தபோநிதி",
  "தடையம்",
  "தசகம்",
  "தத்தடி",
  "தனபதி",
  "தர்மம்",
  "தச்சன்",
  "தந்தம்",
  "தணவம்",
  "தந்தமா",
  "தடவல்",
  "தழைப்பு",
  "தசும்பு",
  "தனாட்டி",
  "தண்டேறு",
  "தசைவலி",
  "தமாலம்",
  "தனிகன்",
  "தசைதல்",
  "தத்தன்",
  "தரியார்",
  "தற்கொலை",
  "தமையம்",
  "தலைமுறை",
  "தம்பிகை",
  "தமக்கை",
  "தளைநார்",
  "தந்ததி",
  "தல்லம்",
  "தட்சிணை",
  "தண்டிகை",
  "தனிச்சி",
  "தடைவிடை",
  "தருவாரி",
  "தகுதல்",
  "தனுவேதி",
  "தண்டோரா",
  "தஞ்சன்",
  "தபனம்",
  "தராபதி",
  "தகலோன்",
  "தலையல்",
  "தவனம்",
  "தலையளி",
  "தகர்வு",
  "தரையர்",
  "தந்துகி",
  "தணிக்கை",
  "தமையன்",
  "தடக்கு",
  "தறுதல்",
  "தறுதலை",
  "தறும்பு",
  "ததியர்",
  "தரங்கு",
  "தகைப்பு",
  "தனிமொழி",
  "தட்டொளி",
  "தடைஇய",
  "தனிமம்",
  "தவாவினை",
  "தளர்வு",
  "தறைதல்",
  "தரளம்",
  "தனவான்",
  "தவிசம்",
  "தலைமாடு",
  "தலையறை",
  "தளருடை",
  "தண்பணை",
  "தமரோசை",
  "தழனாள்",
  "தண்டாமை",
  "தரக்கு",
  "தளுவம்",
  "தமிழோர்",
  "தமராணி",
  "தடிதல்",
  "தகுவன்",
  "தற்குறி",
  "தகைதல்",
  "தரகரி",
  "தபனன்",
  "ததிசம்",
  "தஞ்சம்",
  "தவனன்",
  "தசவாயு",
  "தற்சனி",
  "தவுதல்",
  "ததைதல்",
  "தற்பாடி",
  "தன்மம்",
  "தணக்கு",
  "தவிசணை",
  "ததிகேடு",
  "தழிஞ்சி",
  "தமாலகி",
  "தலக்கு",
  "தருமம்",
  "தசைபிடி",
  "தணுப்பு",
  "தண்டான்",
  "தட்டோடு",
  "தலையணி",
  "தலையணை",
  "தளைதல்",
  "தங்காள்",
  "தலைவாரி",
  "தலைவாய்",
  "தனயன்",
  "தடமண்",
  "தலையடி",
  "தட்டான்",
  "தலைமாறு",
  "தணிப்பு",
  "தணிதல்",
  "தடுமன்",
  "தன்மன்",
  "தழையணி",
  "தமரூசி",
  "தகவல்",
  "தரப்பு",
  "தமனம்",
  "தருமன்",
  "தனுசன்",
  "தயாளம்",
  "தட்டழி",
  "தயிரேடு",
  "தனிவழி",
  "தலைவிதி",
  "தமிழன்",
  "தபுதல்",
  "தட்டல்",
  "தண்ணுமை",
  "தகனம்",
  "தட்டறை",
  "தணப்பு",
  "தனிதர்",
  "தக்கல்",
  "தலையுவா",
  "தனிதம்",
  "தருமதி",
  "தக்கர்",
  "தனையள்",
  "தயிலம்",
  "தலைமாலை",
  "தன்மணி",
  "தக்கம்",
  "தள்ளல்",
  "தளவம்",
  "தளவாய்",
  "தந்திரை",
  "தந்திரி",
  "தற்பரை",
  "தரணம்",
  "தள்ளாமை",
  "தளவான்",
  "தற்பம்",
  "தன்னமை",
  "தன்னம்",
  "தனையன்",
  "தக்கன்",
  "தளிமம்",
  "தவிப்பு",
  "தயாளன்",
  "தகிலன்",
  "தணலம்",
  "தகிலிமா",
  "தடிமாடு",
  "தப்பிலி",
  "தகனன்",
  "தங்கான்",
  "தலைவிளை",
  "தனிவீடு",
  "தடயம்",
  "தரூடம்",
  "தலையோடு",
  "தடாரம்",
  "தமியம்",
  "தடியன்",
  "தமியள்",
  "தனியூர்",
  "தண்டர்",
  "தலையாடி",
  "தழையுடை",
  "தழுதாழை",
  "தங்கூசு",
  "தண்டல்",
  "தண்டலை",
  "தராசுநா",
  "தவந்து",
  "தரதூது",
  "தன்வழி",
  "தவளம்",
  "தன்னோர்",
  "தமகன்",
  "தருதல்",
  "தடாதடி",
  "தமர்மை",
  "தலைமடை",
  "தரிசனை",
  "தட்டம்",
  "தமோமணி",
  "தனிட்டை",
  "தனரேகை",
  "தமியன்",
  "தாருகாரி",
  "தானீகம்",
  "தாட்சிணை",
  "தாக்கல்",
  "தாபதம்",
  "தாய்வேர்",
  "தானாதல்",
  "தாமசம்",
  "தாசேரம்",
  "தாக்கோல்",
  "தாக்கம்",
  "தாறுமாறு",
  "தானவள்",
  "தாசோகம்",
  "தார்நிலை",
  "தாதச்சி",
  "தாராளம்",
  "தாசநெறி",
  "தாளுதல்",
  "தாவரன்",
  "தாரிப்பு",
  "தாட்சணை",
  "தாத்திரி",
  "தாரிகம்",
  "தார்ப்பூ",
  "தாவளம்",
  "தாபரன்",
  "தானையம்",
  "தாம்பணி",
  "தாட்படை",
  "தாதுவாதி",
  "தாத்தாரி",
  "தாட்சம்",
  "தாட்கோல்",
  "தாபமாறி",
  "தாபரம்",
  "தாப்புலி",
  "தானக்கை",
  "தானிகன்",
  "தாத்துரு",
  "தாழ்வாய்",
  "தானைநிலை",
  "தாட்கோரை",
  "தாவரம்",
  "தாசியம்",
  "தானிகம்",
  "தாராட்டு",
  "தாயித்து",
  "தாழ்ச்சி",
  "தாரகன்",
  "தாசுவம்",
  "தாயகம்",
  "தாடாளன்",
  "தாமதம்",
  "தாபிதம்",
  "தாளிதம்",
  "தாணையம்",
  "தாளிப்பு",
  "தாவாரம்",
  "தாட்சன்",
  "தாட்பாள்",
  "தாரிசம்",
  "தார்யம்",
  "தாழ்ப்பு",
  "தாம்போகி",
  "தாரகம்",
  "தாங்கல்",
  "தாராவணி",
  "தாபசன்",
  "தாசரதி",
  "தானவாரி",
  "தாய்வழி",
  "தாங்கள்",
  "தாளம்மை",
  "தாபமானி",
  "தானூரம்",
  "தாபனன்",
  "தாமிரிகை",
  "தாரகாரி",
  "தாளாளன்",
  "தாமிரம்",
  "தாவுதல்",
  "தாய்ச்சி",
  "தாஅயது",
  "தாதலம்",
  "தாரணம்",
  "தாபனம்",
  "தாமநூல்",
  "தாடனம்",
  "தாழ்வர்",
  "தாழ்வரை",
  "தாதானம்",
  "தானவன்",
  "தாந்தன்",
  "தானியம்",
  "தாழ்வறை",
  "தாளகம்",
  "தாட்டன்",
  "தானநிலை",
  "தாவனம்",
  "தாடாற்றி",
  "தானைமாலை",
  "தாமலகி",
  "தாதைதாதை",
  "தாநகம்",
  "தாளிசம்",
  "தாப்பிசை",
  "தாராடம்",
  "தாபகன்",
  "தாட்டான்",
  "தாட்டானை",
  "தாராபதி",
  "தாளிக்கை",
  "தாழ்தல்",
  "தாடிமம்",
  "தானாபதி",
  "தாக்கீது",
  "தாருணம்",
  "தாளுருவி",
  "தாமீகன்",
  "தாபதன்",
  "தாம்பூலி",
  "தானகம்",
  "தாளாண்மை",
  "தாட்கம்",
  "தாடகம்",
  "தாதுவைரி",
  "தாருகம்",
  "தாமநிதி",
  "தானவர்",
  "தாண்முளை",
  "தாபகம்",
  "திட்பம்",
  "திருணம்",
  "திருமண்",
  "தியோதம்",
  "திருமால்",
  "திகளர்",
  "திருடதை",
  "திறவோன்",
  "திரிசூலி",
  "திமிலம்",
  "தியூதம்",
  "திரிதியை",
  "திரராசி",
  "திரிபுடி",
  "திறவான்",
  "திமிரன்",
  "திரிபுடை",
  "திரலடி",
  "தின்பன",
  "திருமுடி",
  "திருகாணி",
  "திருடன்",
  "திரிபதை",
  "திண்கல்",
  "திறவது",
  "திறப்பு",
  "திருமேனி",
  "திவரம்",
  "தியாகர்",
  "திருவினை",
  "திசையானை",
  "தியாகம்",
  "திரோதம்",
  "திருணதை",
  "தின்மார்",
  "திரவாதி",
  "தியந்தி",
  "திகைதல்",
  "திணிம்பு",
  "தினசரி",
  "திசையோனி",
  "திம்மலி",
  "திரட்டு",
  "திதளம்",
  "திருவுரு",
  "திரிமணை",
  "திரிமணி",
  "திரிசிகை",
  "திரணம்",
  "தித்திரி",
  "திப்பிலி",
  "திருகணை",
  "திருகுளி",
  "திருகணி",
  "திருமாது",
  "தித்திரு",
  "திப்பம்",
  "திரிபலை",
  "திடுக்கு",
  "தியானம்",
  "திப்பலி",
  "திறக்கு",
  "திருமலை",
  "திணைவழு",
  "திருவடி",
  "திகசம்",
  "திரிபோது",
  "திதிநாடி",
  "திணைகள்",
  "திருவாழி",
  "திரிசொல்",
  "திணிகம்",
  "திதிட்சை",
  "திருவாலி",
  "தியாதிரு",
  "திருகூசி",
  "திருவணை",
  "திதைதல்",
  "திடுமன்",
  "தினகாரி",
  "திடுமல்",
  "திமிரம்",
  "திணிப்பு",
  "திசைமொழி",
  "திருடம்",
  "திகரடி",
  "திருவோலை",
  "திமிலர்",
  "திதீட்சை",
  "திதியம்",
  "திரமம்",
  "தினமணி",
  "திண்ணன்",
  "திசிலன்",
  "தியாசம்",
  "திண்ணிமை",
  "திணிதல்",
  "திருதிமை",
  "திருக்கை",
  "திகழ்வு",
  "திருக்கு",
  "திரிவேணி",
  "திணியன்",
  "திட்டாணி",
  "திரிகண்",
  "திரோதாயி",
  "திசைப்பு",
  "தில்லானா",
  "தின்மர்",
  "திடுமலி",
  "திருதியை",
  "திமிராளி",
  "தியசம்",
  "திரிபாகி",
  "திடுகூறு",
  "திமிதம்",
  "திருகல்",
  "திருட்டு",
  "திருட்டி",
  "திரிதூளி",
  "திசாயம்",
  "தியாலம்",
  "திருதம்",
  "தில்லம்",
  "தியரடி",
  "திராவடி",
  "திருவாணை",
  "திரியாமை",
  "திகரம்",
  "தினவாரி",
  "திண்ணம்",
  "திரையல்",
  "திமிராரி",
  "திரிசிரா",
  "திருமொழி",
  "திருவன்",
  "தியக்கு",
  "திராணம்",
  "திறலோன்",
  "திருவோடு",
  "திருப்தி",
  "திருநீறு",
  "திராட்சை",
  "திங்கள்",
  "திரவம்",
  "திருப்பி",
  "திருப்பு",
  "திரிதல்",
  "திரைதல்",
  "திரகம்",
  "திருகம்",
  "திறவிது",
  "திருநாள்",
  "திதிசர்",
  "திக்குறு",
  "திரையன்",
  "திலகன்",
  "திராலம்",
  "திரிகல்",
  "திருமுன்",
  "திவசம்",
  "திம்மன்",
  "திருவிலி",
  "திருவில்",
  "திலகம்",
  "திலதம்",
  "திராங்கு",
  "திரட்சி",
  "திக்கம்",
  "திமிசம்",
  "தியாதன்",
  "திரிபுரி",
  "திரிபுரை",
  "திரைப்பு",
  "திருத்து",
  "திருத்தி",
  "திரேதம்",
  "திக்கரி",
  "திக்கரை",
  "திருவிழா",
  "திறாங்கு",
  "திறத்தி",
  "திதீக்கை",
  "திரயம்",
  "திசாலம்",
  "திகைப்பு",
  "திகிரிகை",
  "திரியல்",
  "தியாமம்",
  "தின்றல்",
  "திணிநிலை",
  "திருமுறை",
  "திசாதிசை",
  "தித்தம்",
  "திட்டம்",
  "திரிதேகி",
  "திருநாடு",
  "திமிதிமி",
  "தினகவி",
  "திருவாசி",
  "தீம்புகை",
  "தீக்கல்",
  "தீம்பன்",
  "தீய்தல்",
  "தீத்தல்",
  "தீநாக்கு",
  "தீவனம்",
  "தீப்புண்",
  "தீக்கதி",
  "தீமகம்",
  "தீசகன்",
  "தீவத்தி",
  "தீத்தம்",
  "தீப்பொறி",
  "தீபகூபி",
  "தீஞ்சொல்",
  "தீவகம்",
  "தீனிப்பை",
  "தீண்டல்",
  "தீரிக்கை",
  "தீபகம்",
  "தீர்ப்பு",
  "தீவிரம்",
  "தீயகம்",
  "தீக்காலி",
  "தீழ்ப்பு",
  "தீபாவலி",
  "தீயினம்",
  "தீவாணம்",
  "தீபாவளி",
  "தீக்கனா",
  "தீப்படை",
  "தீரவாசி",
  "தீந்தொடை",
  "தீச்சொல்",
  "தீங்கனி",
  "தீமுகம்",
  "தீஞ்சுவை",
  "தீஞ்சேறு",
  "தீப்பிணி",
  "தீவானம்",
  "தீம்புளி",
  "தீட்டம்",
  "தீர்தல்",
  "தீயழல்",
  "தீபனம்",
  "தீப்தம்",
  "தீமூட்டு",
  "தீவட்டி",
  "தீப்பசி",
  "தீராந்தி",
  "தீக்குறி",
  "தீக்கோழி",
  "தீயறம்",
  "தீக்கோள்",
  "தீபவதி",
  "தீவேள்வி",
  "துப்பாசி",
  "துரக்கு",
  "துவன்று",
  "துறுட்டி",
  "துவிதியை",
  "துமானம்",
  "துன்பம்",
  "துய்யம்",
  "துப்பல்",
  "துயிலார்",
  "துன்பன்",
  "துணிதல்",
  "துவிசம்",
  "துருவாடு",
  "துப்பம்",
  "துணங்கை",
  "துணங்கு",
  "துளபம்",
  "துரைசானி",
  "துலாந்து",
  "துதியம்",
  "துறவோர்",
  "துன்னார்",
  "துளவன்",
  "துவையல்",
  "துறவர்",
  "துணையல்",
  "துளுவன்",
  "துணையறை",
  "துவியம்",
  "துயில்வு",
  "துருவாதி",
  "துவாதசி",
  "துரிதம்",
  "துராசாரி",
  "துயக்கு",
  "துளவம்",
  "துலிதம்",
  "துலங்கு",
  "துளையம்",
  "துளுவம்",
  "துவிசன்",
  "துண்டன்",
  "துளைநிறை",
  "துணியல்",
  "துரியம்",
  "துணுக்கு",
  "துடிப்பு",
  "துண்டில்",
  "துண்டம்",
  "துருவல்",
  "துவட்டு",
  "துடைகாலி",
  "துவட்டா",
  "துமிதம்",
  "துருப்பு",
  "துச்சாரி",
  "துடுக்கு",
  "துரங்கி",
  "துலோபம்",
  "துரியன்",
  "துரோணம்",
  "துழாதல்",
  "துறுதல்",
  "துருதம்",
  "துந்தம்",
  "துருவம்",
  "தும்பிலி",
  "துறுவல்",
  "துருகம்",
  "தும்சம்",
  "துவிபடி",
  "துடவர்",
  "துறுகல்",
  "துக்குடி",
  "துவீபம்",
  "துந்துபி",
  "துலாகோடி",
  "துலைவாய்",
  "துல்லம்",
  "துட்டரி",
  "துக்குணி",
  "துக்கர்",
  "துட்டம்",
  "துச்சம்",
  "தும்புரு",
  "துவட்சி",
  "துந்துமி",
  "தும்பினி",
  "துமிதல்",
  "துயினடை",
  "துவளல்",
  "தும்பகா",
  "துரும்பு",
  "துயரடி",
  "துருக்கு",
  "துக்காணி",
  "துகினூல்",
  "துட்டன்",
  "துகிரிகை",
  "துச்சன்",
  "துவிபாசி",
  "துருமாரி",
  "துசகம்",
  "துற்கதி",
  "துபாக்கி",
  "துவயம்",
  "துவறல்",
  "துத்தன்",
  "துவராடை",
  "துயிலெடை",
  "துனைதல்",
  "துவள்வு",
  "துயற்சி",
  "துள்ளம்",
  "துரோட்டி",
  "துத்தரி",
  "துக்கம்",
  "துன்னூசி",
  "துணிக்கை",
  "துள்ளுமா",
  "துடுப்பு",
  "துய்ப்பு",
  "துவாரடை",
  "துரகம்",
  "துனிப்பு",
  "துத்தம்",
  "துவிரம்",
  "துன்னல்",
  "துவசம்",
  "துரட்டு",
  "தும்மல்",
  "துருணன்",
  "துவசர்",
  "துலவம்",
  "துன்முகி",
  "துடிநூல்",
  "துடியன்",
  "துளைப்பு",
  "துரோகம்",
  "தும்பன்",
  "துக்கடி",
  "துத்தல்",
  "துய்யாள்",
  "துஞ்சர்",
  "துன்னம்",
  "துறட்டு",
  "துறட்டி",
  "துவேசம்",
  "துன்னர்",
  "துணுக்கை",
  "துயரம்",
  "துவனம்",
  "துஞ்சற",
  "துன்மதி",
  "துவ்வான்",
  "துடைவாழை",
  "துலுக்கி",
  "துவிதம்",
  "துலுக்கு",
  "துடிகம்",
  "துந்நெறி",
  "துறப்பு",
  "துமுக்கி",
  "துங்கம்",
  "துழைதல்",
  "துடிநிலை",
  "துறுபடை",
  "துச்சில்",
  "துருமதி",
  "துவேடம்",
  "துள்ளொலி",
  "துடியடி",
  "துர்க்கை",
  "துன்னெறி",
  "துக்கடா",
  "துவ்வாமை",
  "துடக்கு",
  "துணைவன்",
  "தும்பால்",
  "துகினம்",
  "தும்பாலை",
  "துதிக்கை",
  "துங்கன்",
  "துவாரம்",
  "துன்னீதி",
  "துளைதல்",
  "துங்கதை",
  "துள்ளல்",
  "துராசர்",
  "துற்றவை",
  "துத்தாரி",
  "துதைதல்",
  "துய்யன்",
  "துறுமல்",
  "துமுலம்",
  "துகளிலி",
  "துப்பன்",
  "துருமம்",
  "துணிநிலா",
  "துவைதம்",
  "துவக்கு",
  "துப்பார்",
  "துரோணிகை",
  "துறைவன்",
  "துகுதல்",
  "துரைச்சி",
  "துகிலிகை",
  "துன்னீர்",
  "துகையல்",
  "துருத்தி",
  "துவரம்",
  "துளக்கு",
  "துரீயம்",
  "துற்றர்",
  "துண்டிகை",
  "துகூலம்",
  "துவைதல்",
  "துமிலம்",
  "துச்சிமை",
  "துணைதல்",
  "துரப்பு",
  "துளைக்கை",
  "தூமியம்",
  "தூபமணி",
  "தூளனம்",
  "தூக்கிலி",
  "தூமலம்",
  "தூவுதல்",
  "தூடணம்",
  "தூமிரம்",
  "தூறுதலை",
  "தூரோணம்",
  "தூர்மம்",
  "தூறுதல்",
  "தூடியம்",
  "தூளிதம்",
  "தூரியன்",
  "தூவையர்",
  "தூற்றல்",
  "தூவத்தி",
  "தூசுப்பு",
  "தூண்மாலை",
  "தூலகம்",
  "தூதுவளை",
  "தூக்குணி",
  "தூவானம்",
  "தூனனம்",
  "தூக்கிரி",
  "தூரகாரி",
  "தூறுமாறு",
  "தூசரன்",
  "தூர்க்கை",
  "தூரேத்தி",
  "தூவரம்",
  "தூசியம்",
  "தூடிதம்",
  "தூபரம்",
  "தூரியம்",
  "தூசரம்",
  "தூயவன்",
  "தூசனம்",
  "தூர்தல்",
  "தூர்த்தை",
  "தூக்கம்",
  "தூண்டில்",
  "தூபிதம்",
  "தூமயோனி",
  "தூணிகர்",
  "தூங்கிசை",
  "தூங்காமை",
  "தூமமணி",
  "தூர்வாரி",
  "தூங்கல்",
  "தூசிகம்",
  "தூகுதல்",
  "தூமகேது",
  "தூக்கல்",
  "தூருதல்",
  "தூதுவன்",
  "தூம்பல்",
  "தூணீரம்",
  "தூறுவாதி",
  "தெளிப்பு",
  "தெகுட்டி",
  "தெறுதல்",
  "தெறிநடை",
  "தெம்முனை",
  "தெளிதல்",
  "தெருட்சி",
  "தெருவம்",
  "தெந்தனா",
  "தெண்டகை",
  "தெய்நெய்",
  "தென்னோலை",
  "தெரிகவி",
  "தெரிதல்",
  "தெப்பம்",
  "தெரிசம்",
  "தெண்டன்",
  "தென்மலை",
  "தெக்கணை",
  "தெசலம்",
  "தெட்பம்",
  "தெண்ணர்",
  "தெறிப்பு",
  "தென்கால்",
  "தெவ்வினை",
  "தெவ்வம்",
  "தென்கோடு",
  "தென்மொழி",
  "தெட்டல்",
  "தென்னன்",
  "தென்கலை",
  "தெம்மாடி",
  "தெய்வாவி",
  "தெம்பல்",
  "தெங்கம்",
  "தெகுளம்",
  "தென்னுரை",
  "தெலுங்கு",
  "தெறிவில்",
  "தெண்ணீர்",
  "தெரிகோல்",
  "தெரிசொல்",
  "தெரியல்",
  "தெவ்வன்",
  "தெகிட்டு",
  "தெற்றல்",
  "தெரிநிலை",
  "தென்னிலை",
  "தெருளான்",
  "தெளிநீர்",
  "தெறுநர்",
  "தெய்வம்",
  "தெழிப்பு",
  "தெள்விளி",
  "தென்னர்",
  "தெற்றென",
  "தென்னல்",
  "தெளிஞன்",
  "தென்பார்",
  "தெய்வதை",
  "தெவிட்டு",
  "தென்றல்",
  "தெள்ளிமை",
  "தெண்டிரை",
  "தெருள்வு",
  "தெருட்டு",
  "தெரிப்பு",
  "தெரிகடை",
  "தெட்சணை",
  "தென்முனி",
  "தேசிகம்",
  "தேசனம்",
  "தேவசேவை",
  "தேர்ச்சி",
  "தேட்டம்",
  "தேத்தடை",
  "தேவபாணி",
  "தேக்கம்",
  "தேவனம்",
  "தேசவாசி",
  "தேசமுகி",
  "தேன்வதை",
  "தேறுகடை",
  "தேனருவி",
  "தேரலர்",
  "தேவதேவு",
  "தேய்தல்",
  "தேவாங்கு",
  "தேசியம்",
  "தேசகன்",
  "தேக்கர்",
  "தேற்றார்",
  "தேவளம்",
  "தேவயானை",
  "தேனிறால்",
  "தேயாமணி",
  "தேறகம்",
  "தேவதரு",
  "தேவகோடி",
  "தேவகதி",
  "தேயாமதி",
  "தேவதாசி",
  "தேன்றாடு",
  "தேசிகன்",
  "தேசலம்",
  "தேலுதல்",
  "தேடுதல்",
  "தேரறேர்",
  "தேவரன்",
  "தேறினர்",
  "தேவாரம்",
  "தேங்காய்",
  "தேய்நீர்",
  "தேட்கடை",
  "தேயுசகா",
  "தேவபூமி",
  "தேறலார்",
  "தேணிறம்",
  "தேற்றன்",
  "தேன்கூடு",
  "தேவகுரு",
  "தேயுடலி",
  "தேவரான்",
  "தேறுதலை",
  "தேறுதல்",
  "தேனுகாரி",
  "தேவசபை",
  "தேவலன்",
  "தேலிக்கை",
  "தேம்பல்",
  "தேரகன்",
  "தேவபாடை",
  "தேவபதி",
  "தேர்நிலை",
  "தேவமாதா",
  "தேறலர்",
  "தேவதாரு",
  "தேய்கடை",
  "தேய்பிறை",
  "தேர்தல்",
  "தேறுநர்",
  "தேற்றம்",
  "தேனுகம்",
  "தையெனல்",
  "தைவாதல்",
  "தைசதன்",
  "தைத்தல்",
  "தைவிகம்",
  "தைனியம்",
  "தைபீதம்",
  "தைதேயர்",
  "தைப்பான்",
  "தைதிலம்",
  "தைரியம்",
  "தைசதம்",
  "தைவரல்",
  "தைலிகன்",
  "தைவதம்",
  "தொண்ணூறு",
  "தொழிலாளி",
  "தொட்பம்",
  "தொம்பறை",
  "தொகைநூல்",
  "தொடர்பு",
  "தொன்னீர்",
  "தொம்பரை",
  "தொட்டில்",
  "தொன்னூல்",
  "தொழுநோய்",
  "தொந்தனை",
  "தொய்யில்",
  "தொல்படை",
  "தொறுத்தி",
  "தொப்புள்",
  "தொத்தாள்",
  "தொள்ளாடி",
  "தொல்பதி",
  "தொட்டிமை",
  "தொழுப்பு",
  "தொண்டன்",
  "தொய்தம்",
  "தொங்கல்",
  "தொகுப்பு",
  "தொடைவாழை",
  "தொத்தல்",
  "தொகைநிலை",
  "தொழும்பு",
  "தொட்டம்",
  "தொக்கடி",
  "தொழும்பி",
  "தொழுவன்",
  "தொய்தல்",
  "தொக்கடை",
  "தொழுவர்",
  "தொழிலோர்",
  "தொடக்கு",
  "தொழுத்தை",
  "தொழுவம்",
  "தொல்கதை",
  "தொகைவிரி",
  "தொடுக்கை",
  "தொழிதல்",
  "தொங்கன்",
  "தொட்டல்",
  "தொகாநிலை",
  "தொடுப்பு",
  "தொடுப்பி",
  "தொழுதல்",
  "தொத்தன்",
  "தொடுதுணை",
  "தொள்ளாளி",
  "தொல்வினை",
  "தொலைதல்",
  "தொழுவறை",
  "தொம்மனை",
  "தொன்மம்",
  "தொய்யல்",
  "தொடுவாய்",
  "தொத்தூன்",
  "தொறுவன்",
  "தொகுதல்",
  "தொக்கார்",
  "தொடங்கு",
  "தொள்ளம்",
  "தொந்தரை",
  "தொடுதோல்",
  "தொள்கல்",
  "தொம்பன்",
  "தொட்டடி",
  "தொடுதல்",
  "தொடர்வு",
  "தொந்தம்",
  "தொலியல்",
  "தொடரர்",
  "தொக்கம்",
  "தொம்பம்",
  "தொண்டர்",
  "தொள்ளல்",
  "தொடையல்",
  "தொடுவான்",
  "தோய்தல்",
  "தோடயம்",
  "தோற்புரை",
  "தோளாமணி",
  "தோணுதல்",
  "தோதகன்",
  "தோன்றல்",
  "தோய்ப்பு",
  "தோதனம்",
  "தோற்பாய்",
  "தோமரம்",
  "தோற்பாடி",
  "தோகசம்",
  "தோய்பனி",
  "தோயசம்",
  "தோணியம்",
  "தோட்டுணை",
  "தோட்டிமை",
  "தோப்பாடி",
  "தோரணம்",
  "தோரியம்",
  "தோழிச்சி",
  "தோகதம்",
  "தோற்றம்",
  "தோரணன்",
  "தோயதம்",
  "தோராயம்",
  "தோட்சுமை",
  "தோண்மேல்",
  "தோவாளம்",
  "தோலோதோல்",
  "தோலுழிஞை",
  "தோடகம்",
  "தோட்டம்",
  "தோளுதல்",
  "தோற்காது",
  "தோலனம்",
  "தோள்வளை",
  "தோற்றல்",
  "தோவத்தி",
  "தோற்பாவை",
  "தோடையம்",
  "தோற்செவி",
  "தோதகம்",
  "தோள்வலி",
  "தோண்டான்",
  "தோலாண்டி",
  "தௌலிகன்",
  "தௌரிதம்",
  "தௌதிகம்",
  "தௌசாரம்",
  "தௌலேயம்",
  "நற்றாய்",
  "நவநிதி",
  "நக்கிரா",
  "நசிதல்",
  "நசைவினை",
  "நலப்பு",
  "நல்வழி",
  "நவீனம்",
  "நகுடம்",
  "நக்கல்",
  "நடைபடி",
  "நற்றம்",
  "நள்ளிடை",
  "நடைமாடு",
  "நன்றாய்",
  "நன்னர்",
  "நபோமணி",
  "நற்சிலை",
  "நயவன்",
  "நயமொழி",
  "நட்டம்",
  "நத்தம்",
  "நடலம்",
  "நயவான்",
  "நற்சொல்",
  "நமைப்பு",
  "நரதுதி",
  "நசைதல்",
  "நதிபதி",
  "நகையால்",
  "நடுநிலை",
  "நன்றாக",
  "நசலாளி",
  "நச்சறை",
  "நசியம்",
  "நடபடி",
  "நற்குறி",
  "நளிப்பு",
  "நனைதல்",
  "நறுக்கு",
  "நச்சினி",
  "நமசம்",
  "நறுதடி",
  "நண்பல்",
  "நடுங்க",
  "நயப்பு",
  "நசுவல்",
  "நல்லாடை",
  "நடைநீர்",
  "நடத்தை",
  "நவமணி",
  "நடுகூலி",
  "நடைபாதை",
  "நரபதி",
  "நவிரல்",
  "நக்குணி",
  "நகாஅல்",
  "நன்செய்",
  "நந்தகி",
  "நடப்பு",
  "நபாகம்",
  "நடைமலை",
  "நன்பால்",
  "நகைவர்",
  "நசையுரை",
  "நள்ளார்",
  "நற்கதி",
  "நசியல்",
  "நகாஅர்",
  "நவ்வல்",
  "நற்குடி",
  "நற்கோள்",
  "நறவம்",
  "நறுமடி",
  "நல்லூழ்",
  "நசுகுணி",
  "நடுக்கு",
  "நல்விதி",
  "நயினார்",
  "நவரோசு",
  "நசிறாணி",
  "நளுங்கு",
  "நன்மொழி",
  "நடைமனை",
  "நப்பாசை",
  "நல்லாறு",
  "நயவர்",
  "நல்லார்",
  "நல்வினை",
  "நம்பன்",
  "நன்கொடை",
  "நறுமண்",
  "நன்சொல்",
  "நடவடி",
  "நடைமுறை",
  "நதீசம்",
  "நட்டோடு",
  "நத்தாசை",
  "நன்னிலை",
  "நடேசன்",
  "நன்னெறி",
  "நடக்கை",
  "நந்தனி",
  "நமைதல்",
  "நரகன்",
  "நகரம்",
  "நந்தன்",
  "நல்லாள்",
  "நம்பான்",
  "நந்தினி",
  "நந்தனை",
  "நற்றுளி",
  "நடுவண்",
  "நறுவிலி",
  "நஞ்சன்",
  "நன்காடு",
  "நல்லிசை",
  "நடுவன்",
  "நமதன்",
  "நல்வசி",
  "நடையன்",
  "நடைகூலி",
  "நகுதல்",
  "நல்லோர்",
  "நமகம்",
  "நண்ணம்",
  "நடிப்பு",
  "நடைவழி",
  "நளினம்",
  "நற்சாரி",
  "நற்பேறு",
  "நட்டார்",
  "நச்செலி",
  "நதீரம்",
  "நடபாவி",
  "நடுவூர்",
  "நவதிகை",
  "நளுத்தை",
  "நயனம்",
  "நரல்வு",
  "நறுநெய்",
  "நயவுரை",
  "நரகாரி",
  "நவிரம்",
  "நணியான்",
  "நந்தன",
  "நல்லாக",
  "நஞ்சீடு",
  "நல்லேறு",
  "நயன்மை",
  "நகழ்வு",
  "நட்டோர்",
  "நடுநாள்",
  "நங்கனை",
  "நடுராசி",
  "நரகாலி",
  "நழுவல்",
  "நடைசாரி",
  "நமசன்",
  "நயவார்",
  "நளிதல்",
  "நசிப்பு",
  "நன்னாள்",
  "நகைப்பு",
  "நலவல்",
  "நவியம்",
  "நடுதல்",
  "நவுதல்",
  "நரம்பு",
  "நடுவழி",
  "நற்றுணை",
  "நலவர்",
  "நடனர்",
  "நலிதல்",
  "நங்கள்",
  "நயிந்தை",
  "நபனம்",
  "நற்றுடி",
  "நன்னடை",
  "நந்தல்",
  "நடுவெளி",
  "நற்றோழி",
  "நக்கம்",
  "நலங்கு",
  "நரகல்",
  "நகேசன்",
  "நடுநாடி",
  "நகுலன்",
  "நஞ்சம்",
  "நடனம்",
  "நந்தம்",
  "நரவல்",
  "நண்பன்",
  "நவ்வார்",
  "நடுகல்",
  "நடைவாவி",
  "நரகம்",
  "நன்னீர்",
  "நக்கன்",
  "நட்டணை",
  "நடுதறி",
  "நயமாலி",
  "நரவரி",
  "நண்ணார்",
  "நரகர்",
  "நரிதல்",
  "நரகரி",
  "நற்போர்",
  "நணுகார்",
  "நகிலம்",
  "நன்னாரி",
  "நடைபாவி",
  "நடுவர்",
  "நசைநர்",
  "நடுவீடு",
  "நசுக்கு",
  "நாசனன்",
  "நாலிகம்",
  "நாபிதன்",
  "நாடுதல்",
  "நாவுழலை",
  "நாவியம்",
  "நாரத்தை",
  "நாள்வேலை",
  "நாடவர்",
  "நாற்பொன்",
  "நாதேயம்",
  "நான்மறை",
  "நாடகர்",
  "நாவன்மை",
  "நாராங்கி",
  "நாளிதழ்",
  "நாகம்மா",
  "நாமராசி",
  "நாட்டார்",
  "நாவரணை",
  "நாதாந்தி",
  "நாகநாடு",
  "நாந்தல்",
  "நாவிநெய்",
  "நாண்புடை",
  "நாற்காலி",
  "நாதரவி",
  "நாடகம்",
  "நாற்கதி",
  "நாட்கவி",
  "நாற்பால்",
  "நாயுள்ளி",
  "நாகலதை",
  "நாளாதல்",
  "நாவுதல்",
  "நாட்டாள்",
  "நாளந்தி",
  "நாட்கால்",
  "நாட்காலை",
  "நாசமிலி",
  "நாட்கோள்",
  "நாப்பண்",
  "நாணுகம்",
  "நாவடம்",
  "நாகரம்",
  "நாணிழல்",
  "நாவினார்",
  "நாணகம்",
  "நாயுருவி",
  "நாளெல்லை",
  "நாற்றம்",
  "நாயகன்",
  "நாற்படை",
  "நாற்பான்",
  "நாவலர்",
  "நாணுவம்",
  "நாசனம்",
  "நாளிகம்",
  "நாணையம்",
  "நாதாங்கி",
  "நாபிநாடி",
  "நாயனார்",
  "நாற்றிசை",
  "நாசுவன்",
  "நாகரன்",
  "நாராசம்",
  "நாமநீர்",
  "நாயனம்",
  "நாற்பது",
  "நாகணம்",
  "நாகவாய்",
  "நாற்பண்",
  "நாதியன்",
  "நாளரம்",
  "நாகமணி",
  "நாட்டம்",
  "நாண்மீன்",
  "நாகமாதா",
  "நாவணம்",
  "நாகவாயு",
  "நாறுதல்",
  "நாவிதன்",
  "நாண்மதி",
  "நாரதம்",
  "நாரகர்",
  "நாளறுதி",
  "நாங்கூழ்",
  "நானிலம்",
  "நாகியர்",
  "நாளுக்கு",
  "நாகப்பூ",
  "நாளைக்கு",
  "நாங்குழு",
  "நாரணன்",
  "நால்வர்",
  "நாமகள்",
  "நாயன்தே",
  "நாசிதாரு",
  "நாற்கவி",
  "நாபிசன்",
  "நாடேயன்",
  "நாலாவான்",
  "நாமதாரி",
  "நாம்பல்",
  "நாரதன்",
  "நாணுதல்",
  "நாயகம்",
  "நாராயம்",
  "நாகதிசை",
  "நாளன்று",
  "நாச்செறு",
  "நாணாளும்",
  "நாசியம்",
  "நாடோறும்",
  "நாய்கன்",
  "நாமிதம்",
  "நார்மடி",
  "நாவிகன்",
  "நாமாவளி",
  "நாகதேனி",
  "நாட்கூலி",
  "நாகரிகி",
  "நாலிககை",
  "நாரநிதி",
  "நாட்கூறு",
  "நாவிசம்",
  "நாராயணி",
  "நாற்றடி",
  "நால்வாய்",
  "நாமதாது",
  "நாட்கொடி",
  "நாளகம்",
  "நாசிகேது",
  "நாட்பறை",
  "நாஞ்சில்",
  "நாடகன்",
  "நாசுக்கு",
  "நாத்தூண்",
  "நாட்சோறு",
  "நாலுதல்",
  "நாமயம்",
  "நாணயம்",
  "நாம்பன்",
  "நாகதாளி",
  "நாகவீதி",
  "நாபீலம்",
  "நாவரசு",
  "நாகசம்",
  "நாகதொனி",
  "நாசகாரி",
  "நாபிரம்",
  "நாய்வேளை",
  "நாட்போது",
  "நாகந்தி",
  "நிகலம்",
  "நிச்சம்",
  "நிலைகேடு",
  "நிரூபம்",
  "நிலவடி",
  "நினைதல்",
  "நிரோதனை",
  "நிகாயம்",
  "நிதனம்",
  "நிகணம்",
  "நிடேகம்",
  "நிசிதன்",
  "நிவசதி",
  "நிம்பன்",
  "நியாதம்",
  "நிகாயன்",
  "நித்தல்",
  "நிகோதம்",
  "நிசீதிகை",
  "நிலையாடி",
  "நிகிலம்",
  "நிரோதம்",
  "நியாளம்",
  "நிதேசம்",
  "நிர்வாகி",
  "நிலப்பூ",
  "நிடதம்",
  "நிடேதம்",
  "நித்தம்",
  "நிதிபதி",
  "நியந்தா",
  "நிவேசம்",
  "நிகமம்",
  "நிவப்பு",
  "நிருதூளி",
  "நிடாதம்",
  "நிசாசரி",
  "நிகடம்",
  "நிறைமதி",
  "நிதானன்",
  "நிரூபன்",
  "நிறைமணி",
  "நிகரம்",
  "நிறைசூல்",
  "நிவாசம்",
  "நிருத்தி",
  "நிராகம்",
  "நிறையுரை",
  "நிதானம்",
  "நிதீசன்",
  "நிழலல்",
  "நிரவதி",
  "நிகாதம்",
  "நித்திரை",
  "நியாயர்",
  "நிமிடன்",
  "நிகேதம்",
  "நிபத்தி",
  "நிலம்பி",
  "நிசவான்",
  "நிருபதி",
  "நிகாதன்",
  "நிலமண்",
  "நிற்றல்",
  "நிறுத்து",
  "நிகூடம்",
  "நிலைவரி",
  "நிட்கம்",
  "நிலையாமை",
  "நிமலம்",
  "நிவாரம்",
  "நியதம்",
  "நியாசம்",
  "நிவகம்",
  "நிகழ்வு",
  "நின்மதி",
  "நியோகம்",
  "நிம்பம்",
  "நிமிரல்",
  "நிபாதம்",
  "நிசீதம்",
  "நிமிடம்",
  "நிறைநாழி",
  "நிசிமணி",
  "நிருபாதி",
  "நிகிருதி",
  "நியாயம்",
  "நிசாளம்",
  "நிரனிறை",
  "நிரப்பு",
  "நிகுத்தை",
  "நில்லாமை",
  "நிரைதல்",
  "நிறைமொழி",
  "நிலையம்",
  "நிகளம்",
  "நிசாகம்",
  "நிலையல்",
  "நிடலம்",
  "நிகர்வு",
  "நிகர்வி",
  "நிபுணன்",
  "நிகதம்",
  "நிசாதன்",
  "நிகரார்",
  "நிலைப்பு",
  "நியூனம்",
  "நின்மலி",
  "நினாதம்",
  "நிரையம்",
  "நிதியம்",
  "நிகாரம்",
  "நிலைமொழி",
  "நிலைதல்",
  "நிருமலி",
  "நிலாமுகி",
  "நிமிண்டி",
  "நிருபன்",
  "நிலாமணி",
  "நின்றது",
  "நிறைகல்",
  "நிந்தனை",
  "நிபிடம்",
  "நிகிதம்",
  "நிதகம்",
  "நிமலன்",
  "நிலவேர்",
  "நிலயம்",
  "நிறைகலை",
  "நிபுணம்",
  "நிறைதல்",
  "நிரக்கு",
  "நினைப்பு",
  "நிருபம்",
  "நித்தில்",
  "நிருபர்",
  "நிட்குடி",
  "நிரோதினி",
  "நிரசம்",
  "நிறைகோல்",
  "நியக்கி",
  "நிலைநீர்",
  "நிதியோன்",
  "நிலவாகை",
  "நிலவரி",
  "நிந்தம்",
  "நிர்வாணி",
  "நிமிதல்",
  "நிலவர்",
  "நிர்மூடி",
  "நிகர்தி",
  "நிலவறை",
  "நிருமிதி",
  "நிபானம்",
  "நிற்பது",
  "நிதலம்",
  "நிபுடம்",
  "நிரம்ப",
  "நியமம்",
  "நிசுளம்",
  "நிரயம்",
  "நிசதம்",
  "நிரைசல்",
  "நிதாகம்",
  "நிகீனன்",
  "நிம்மதி",
  "நிலவாசி",
  "நிலைபேறு",
  "நிசாபதி",
  "நிமிளன்",
  "நிசாடம்",
  "நிலமிதி",
  "நிசாரம்",
  "நினதம்",
  "நிரவம்",
  "நிகாசம்",
  "நிருதர்",
  "நிட்டூரி",
  "நிணநெய்",
  "நிம்பிரி",
  "நிரதம்",
  "நிறையவை",
  "நிச்சலை",
  "நிச்சல்",
  "நியுதம்",
  "நிசிதம்",
  "நித்தன்",
  "நிரைகோள்",
  "நிர்மதி",
  "நிசாமணி",
  "நிவாதம்",
  "நிலத்தி",
  "நிகண்டு",
  "நீசாரம்",
  "நீலகிரி",
  "நீனிறம்",
  "நீரவன்",
  "நீகாசம்",
  "நீர்மோர்",
  "நீரெட்டி",
  "நீறுபூசி",
  "நீறுதல்",
  "நீசகம்",
  "நீச்சல்",
  "நீட்டாள்",
  "நீரகம்",
  "நீரரண்",
  "நீட்டல்",
  "நீராட்டு",
  "நீர்விதை",
  "நீரதம்",
  "நீற்றறை",
  "நீக்கம்",
  "நீர்நாடு",
  "நீண்மொழி",
  "நீட்டம்",
  "நீதிநூல்",
  "நீகாரம்",
  "நீரசம்",
  "நீர்யானை",
  "நீர்மாலை",
  "நீக்கல்",
  "நீம்பல்",
  "நீராடல்",
  "நீத்தார்",
  "நீர்வலி",
  "நீருள்ளி",
  "நீவரம்",
  "நீட்டோலை",
  "நீர்போகி",
  "நீதிவான்",
  "நீசசாதி",
  "நீலிதம்",
  "நீரத்தி",
  "நீணாளம்",
  "நீலநெல்",
  "நீருருள்",
  "நீட்சிமை",
  "நீர்விலை",
  "நீர்வாரி",
  "நீடுதல்",
  "நீளியது",
  "நீரிழிவு",
  "நீண்முடி",
  "நீகாமன்",
  "நீரழிவு",
  "நீளிப்பு",
  "நீடுநீர்",
  "நீர்வாழை",
  "நீத்தம்",
  "நீடசம்",
  "நீர்நாய்",
  "நீதவான்",
  "நீராளம்",
  "நீரூற்று",
  "நீர்நிலை",
  "நீவாரம்",
  "நீங்கல்",
  "நீரநிதி",
  "நீளுதல்",
  "நீதிமான்",
  "நீரூபம்",
  "நீங்கள்",
  "நீடாணம்",
  "நீர்நாள்",
  "நீவுதல்",
  "நீத்தல்",
  "நீணுதல்",
  "நீட்பம்",
  "நீவியம்",
  "நீலமணி",
  "நீர்ப்பூ",
  "நீதிநெறி",
  "நுனிப்பு",
  "நுழுவல்",
  "நுகும்பு",
  "நுணுக்கு",
  "நுண்ணிமை",
  "நுசுப்பு",
  "நுதலணி",
  "நுடக்கு",
  "நுணிதல்",
  "நுட்பம்",
  "நுதுப்பு",
  "நுளைச்சி",
  "நுதம்பு",
  "நுணவம்",
  "நுணங்கு",
  "நுழுந்தி",
  "நுளையன்",
  "நுலையிலி",
  "நுளம்பு",
  "நுழைநரி",
  "நுறுங்கு",
  "நுவணம்",
  "நுள்ளான்",
  "நுவற்சி",
  "நும்மோர்",
  "நுள்ளல்",
  "நுணுங்கு",
  "நுடங்கு",
  "நுங்கள்",
  "நுமையன்",
  "நுழைதல்",
  "நுழைவழி",
  "நுகைதல்",
  "நுரம்பு",
  "நுகர்வு",
  "நும்முன்",
  "நூற்கழி",
  "நூறுகோடி",
  "நூதனம்",
  "நூபுரம்",
  "நூற்றாறு",
  "நூங்கர்",
  "நூக்கம்",
  "நூக்கல்",
  "நூத்தல்",
  "நூனாயம்",
  "நூழிலர்",
  "நூலறிவு",
  "நூற்றல்",
  "நூவுதல்",
  "நூதனன்",
  "நூனாழிகை",
  "நூலுண்டை",
  "நூலச்சு",
  "நூறுதல்",
  "நூனயம்",
  "நூர்தல்",
  "நூவுநெய்",
  "நூற்றுறை",
  "நெட்டிடை",
  "நெஞ்சார",
  "நெற்கூடு",
  "நெளிப்பு",
  "நெடுமொழி",
  "நெடுவசி",
  "நெற்பொரி",
  "நெடியோன்",
  "நெட்டூதி",
  "நெற்போர்",
  "நெட்டம்",
  "நெய்ப்பு",
  "நெகிழம்",
  "நெறிதல்",
  "நெட்டிசை",
  "நெதியம்",
  "நெத்தம்",
  "நெட்டோலை",
  "நெடுநாள்",
  "நெளியலி",
  "நெடிலடி",
  "நெப்பம்",
  "நெமிரல்",
  "நெடுநாவை",
  "நெறிபொறி",
  "நெருநல்",
  "நென்னல்",
  "நெளிதம்",
  "நெத்தலி",
  "நெரியாசி",
  "நெறிமான்",
  "நெற்கடை",
  "நெறிகேடு",
  "நெளிதல்",
  "நெடுமிசை",
  "நெஞ்சன்",
  "நெரிசல்",
  "நெட்டில்",
  "நெற்காணி",
  "நெடுங்கை",
  "நெடுநீர்",
  "நெளியன்",
  "நெய்யரி",
  "நெடும்பா",
  "நெருடன்",
  "நெற்றம்",
  "நெற்சூடு",
  "நெருப்பு",
  "நெடுக்கு",
  "நெய்யணி",
  "நெஞ்சம்",
  "நெடிப்பு",
  "நெய்யுலை",
  "நெறிப்பு",
  "நெடுவாசி",
  "நெரியல்",
  "நெகிழ்வு",
  "நெட்டுரு",
  "நெடுவீடு",
  "நெறிநீர்",
  "நெற்குறி",
  "நெஞ்சுள்",
  "நெத்திலி",
  "நெடுமால்",
  "நெய்தல்",
  "நெடுவரி",
  "நெய்வார்",
  "நெடுவல்",
  "நெரிஞ்சி",
  "நெளியல்",
  "நெரிதல்",
  "நெற்குவை",
  "நெய்விழா",
  "நெட்டோடை",
  "நெல்லரி",
  "நெறியோன்",
  "நெறியிலி",
  "நெடுமன்",
  "நெருஞ்சி",
  "நெகுதல்",
  "நெருக்கு",
  "நெக்கல்",
  "நேமியோன்",
  "நேமனம்",
  "நேமகம்",
  "நேர்விடை",
  "நேர்ச்சி",
  "நேரலன்",
  "நேர்த்தி",
  "நேடுதல்",
  "நேசகன்",
  "நேரோடல்",
  "நேரேடம்",
  "நேர்பசை",
  "நேர்பாடு",
  "நேரிசம்",
  "நேர்வான்",
  "நேர்நிறை",
  "நேர்கூறு",
  "நேரியன்",
  "நேர்தல்",
  "நேர்வழி",
  "நேராதல்",
  "நேமியான்",
  "நேட்டம்",
  "நேரியது",
  "நேர்சீர்",
  "நேராதார்",
  "நைகரம்",
  "நைதெனல்",
  "நைசியம்",
  "நைவனம்",
  "நைத்தல்",
  "நைவளம்",
  "நையாண்டி",
  "நைசிகம்",
  "நைமிசம்",
  "நொடிப்பு",
  "நொவ்வல்",
  "நொறுக்கு",
  "நொய்ம்மை",
  "நொசிதல்",
  "நொடிவரை",
  "நொக்கென",
  "நொப்பம்",
  "நொசிப்பு",
  "நொற்பம்",
  "நொய்தல்",
  "நொந்தலை",
  "நொண்டல்",
  "நொறுங்கு",
  "நொலையல்",
  "நொள்ளல்",
  "நொளுவல்",
  "நொந்தார்",
  "நொதுமல்",
  "நொடிதல்",
  "நோக்கம்",
  "நோஞ்சான்",
  "நோற்பாள்",
  "நோய்ஞ்சி",
  "நோட்டம்",
  "நோக்கல்",
  "நோலுதல்",
  "நோல்தல்",
  "நோக்கர்",
  "நோடாலம்",
  "நோக்காடு",
  "நோற்றல்",
  "நோதிறம்",
  "நோன்றல்",
  "நோஞ்சல்",
  "நோற்பார்",
  "பணாமணி",
  "படப்பு",
  "பமரம்",
  "படப்பை",
  "பலிதம்",
  "பட்டாணி",
  "பரம்பு",
  "பன்னாசி",
  "பரிஞ்சு",
  "பல்லீறு",
  "படியோர்",
  "பலசாலி",
  "பட்டாடை",
  "பறைதல்",
  "பழையர்",
  "பலாலம்",
  "பழனம்",
  "படியோலை",
  "பதிலாள்",
  "பகாரம்",
  "பணிநர்",
  "பதுமினி",
  "பதிகம்",
  "பகுதம்",
  "பருமல்",
  "பகுவொளி",
  "பண்டிதை",
  "பணமுடை",
  "பட்டாசு",
  "பகலோன்",
  "படுபனை",
  "பசுநிலை",
  "பதியம்",
  "பணியிறை",
  "பஞ்சணை",
  "பயனிலி",
  "பயனிலை",
  "பணையம்",
  "பஞ்சது",
  "பகுதல்",
  "பருமம்",
  "பலசம்",
  "பதகம்",
  "படுமலை",
  "பணிதல்",
  "பன்னாடு",
  "பணிக்கை",
  "பன்னாடை",
  "பணிக்கு",
  "பல்லகி",
  "பன்மம்",
  "பயிலல்",
  "படியாள்",
  "பரண்டை",
  "பசனம்",
  "பருமன்",
  "பதகன்",
  "பரப்பு",
  "பழையது",
  "பனத்தி",
  "பற்றிலி",
  "பணிதம்",
  "பந்தனை",
  "பருகல்",
  "பத்திரை",
  "படுசூளை",
  "பகன்றை",
  "பஞ்சனி",
  "பரகதி",
  "படைவீடு",
  "பணவன்",
  "பழமனை",
  "படிவம்",
  "பதிட்டை",
  "பயசம்",
  "பத்திமை",
  "பல்நோவு",
  "பத்திரி",
  "பனிச்சை",
  "பளிங்கு",
  "பஞ்சதை",
  "பதசம்",
  "பல்லம்",
  "பரிதல்",
  "பரிச்சை",
  "பரமம்",
  "பந்தம்",
  "படையணி",
  "பதியரி",
  "பணினம்",
  "பற்காவி",
  "பராக்கு",
  "பரிவர்",
  "படவம்",
  "பச்சடி",
  "பழுப்பு",
  "படிப்பு",
  "படிதல்",
  "பஞ்சறை",
  "பணிப்பு",
  "படங்கு",
  "படையர்",
  "பகைமுனை",
  "பளகர்",
  "பறியோலை",
  "படுகர்",
  "பக்கிணி",
  "பகுவாய்",
  "படிவர்",
  "பங்காரு",
  "பஞ்சமி",
  "பளகம்",
  "பற்சர்",
  "பலுகம்",
  "பற்காறை",
  "படுபழி",
  "படிதம்",
  "படவன்",
  "பரமன்",
  "பங்காலி",
  "பழிப்பு",
  "படுசூல்",
  "பசாசம்",
  "பணவெடை",
  "பங்காளி",
  "படிறன்",
  "பஞ்சம்",
  "பல்லதி",
  "படுக்கை",
  "பணியோள்",
  "பரீக்கை",
  "பயில்வு",
  "பசியான்",
  "பர்த்தா",
  "படனம்",
  "பகட்டு",
  "படீரம்",
  "பலபாடு",
  "பவுமன்",
  "பரிதாபி",
  "பவனன்",
  "பராகம்",
  "பத்திடை",
  "பத்ததி",
  "பழமொழி",
  "பக்தன்",
  "படையல்",
  "பயணம்",
  "பல்கணி",
  "பராபரை",
  "படைஞர்",
  "பராபரி",
  "பதணம்",
  "படுகல்",
  "பசுவதி",
  "படுகளி",
  "பரத்தை",
  "பதாகினி",
  "பவ்வம்",
  "பரத்தி",
  "பன்மினி",
  "பரியாரி",
  "பகோளம்",
  "பயலாள்",
  "பசைதல்",
  "பனிமலை",
  "பந்தர்",
  "பழமலை",
  "பத்தம்",
  "பத்தினி",
  "பலாகம்",
  "பகரம்",
  "பணிலம்",
  "பனாட்டு",
  "பந்தல்",
  "பறிமுறை",
  "பழுக்க",
  "படினம்",
  "பகடம்",
  "பல்லவை",
  "பருப்பு",
  "பழையோன்",
  "பகண்டை",
  "பணவம்",
  "பனிநீர்",
  "பனிமாசு",
  "பசகன்",
  "பரகீயை",
  "பக்குவி",
  "பணையான்",
  "பலினம்",
  "பசுவன்",
  "பற்றார்",
  "பற்குனி",
  "பல்லவி",
  "பதிரன்",
  "படிகால்",
  "பதுமம்",
  "பனுவல்",
  "படகுடி",
  "பங்கீடு",
  "பண்டிகை",
  "பரியாசை",
  "பறண்டை",
  "படுகிடை",
  "பம்பல்",
  "பறப்பு",
  "பறப்பை",
  "படலம்",
  "பரப்ப",
  "பலாசம்",
  "பருணன்",
  "பகையாளி",
  "பறையாமை",
  "பண்டாரி",
  "பனையேறி",
  "பல்கல்",
  "பத்தல்",
  "பசியம்",
  "பரவெளி",
  "பதுமன்",
  "படையுறை",
  "பரைச்சி",
  "பரவாதி",
  "பயிரிலி",
  "பவுஞ்சு",
  "பற்குறி",
  "பரிமிதி",
  "பத்தர்",
  "படைவாள்",
  "பண்மாறு",
  "பசும்பை",
  "படுகால்",
  "பதக்கு",
  "பலவான்",
  "பரிகாரி",
  "பழையோள்",
  "பகவன்",
  "பம்மல்",
  "பனித்து",
  "படிலன்",
  "பவனம்",
  "பறட்டை",
  "பகவதி",
  "பலவம்",
  "பச்சிலை",
  "பலவரி",
  "பன்னீர்",
  "பலாண்டு",
  "படிமம்",
  "பணவிடை",
  "பகீரதி",
  "பகாலம்",
  "பல்லார்",
  "பதசாரி",
  "பகைஞன்",
  "பழவினை",
  "பரணம்",
  "பருவதி",
  "பரிக்கை",
  "பதவல்",
  "பனசம்",
  "பயிரவி",
  "பறம்பு",
  "பறம்பி",
  "பப்பளி",
  "படுகுழி",
  "பறைச்சி",
  "பயதம்",
  "பருங்கை",
  "பளிதம்",
  "பழுவம்",
  "படைநிலை",
  "பருங்கி",
  "பங்குரை",
  "பதிதன்",
  "பரிபரி",
  "படைநர்",
  "பரமாணு",
  "பனைநார்",
  "பணமணி",
  "பதவம்",
  "பதவுரை",
  "பங்குனி",
  "பணிவிடை",
  "படவாள்",
  "பயப்பு",
  "பகுளம்",
  "பற்பொடி",
  "பண்டடை",
  "பரக்க",
  "பச்சூன்",
  "படுவசை",
  "பற்றம்",
  "பறளிகை",
  "பதங்கு",
  "படுநீலி",
  "பல்கால்",
  "பட்சணை",
  "பளக்கு",
  "பட்சணி",
  "படுவான்",
  "பதிதல்",
  "பணியல்",
  "பண்டன்",
  "பங்கன்",
  "பண்ணறை",
  "பண்டாகி",
  "பதனம்",
  "பராடம்",
  "பணாடவி",
  "பளாபளா",
  "பண்ணுமை",
  "பருவல்",
  "பற்றாசு",
  "பரவணி",
  "படுகொலை",
  "படிகம்",
  "பரியம்",
  "பண்ணுரை",
  "பட்சம்",
  "பழிமொழி",
  "பனிப்பு",
  "பவளம்",
  "படீனம்",
  "படுகாடு",
  "பகேசிகை",
  "பரிப்பு",
  "பருவம்",
  "பங்கம்",
  "பரியன்",
  "பசுநாகு",
  "படிமேடை",
  "பச்சோலை",
  "பகுப்பு",
  "பற்பல",
  "பதாகம்",
  "பட்டடை",
  "படுபாவி",
  "படுவம்",
  "பளிஞ்சி",
  "படகம்",
  "பறிதல்",
  "பங்கறை",
  "பலலம்",
  "பசண்டை",
  "பன்மாண்",
  "பண்டர்",
  "பரியது",
  "பருக்கை",
  "பலியம்",
  "பண்டம்",
  "பசப்பு",
  "படுதம்",
  "பறங்கி",
  "படாரன்",
  "பத்தாசை",
  "பத்தாசு",
  "பசானம்",
  "படுவன்",
  "பதாகன்",
  "பல்லூழ்",
  "பழிச்சு",
  "பயம்பு",
  "பசுமம்",
  "பண்ணவி",
  "பதிசேவை",
  "பரிகம்",
  "பண்ணல்",
  "பகலடி",
  "பணிமொழி",
  "பசுபதி",
  "பரவல்",
  "பருந்து",
  "பவுரம்",
  "பரதேசி",
  "பணயம்",
  "பரீட்சை",
  "பலகம்",
  "படலிகை",
  "படைப்பு",
  "பதலம்",
  "பழுமணி",
  "படைநாள்",
  "பன்முறை",
  "பற்பம்",
  "பரவர்",
  "பஞ்சான்",
  "பதுக்கை",
  "பட்டன்",
  "பப்பாதி",
  "பரதர்",
  "பட்டிணி",
  "பலிப்பு",
  "பட்டிடை",
  "பலமிலி",
  "பட்டணை",
  "பதிப்பு",
  "பரதம்",
  "படோலம்",
  "படாரர்",
  "பரிசம்",
  "பக்கம்",
  "பயிற்சி",
  "பயினன்",
  "பஞ்சாய்",
  "பகைத்தி",
  "படுதல்",
  "பரிபாடை",
  "பக்கரை",
  "பணியார்",
  "பக்கர்",
  "பச்சுதி",
  "பரிசனை",
  "பரிபாடி",
  "படிசம்",
  "பட்டம்",
  "பரிசில்",
  "பரதன்",
  "பகவான்",
  "பக்கல்",
  "பஞ்சாலை",
  "பட்டோலை",
  "பழிவேலை",
  "பலகணி",
  "பசதன்",
  "பட்டறை",
  "பக்கறை",
  "பவுரணை",
  "பதநீர்",
  "பரேபம்",
  "பலகால்",
  "பஞ்சிகை",
  "பவாயனை",
  "பணிகோள்",
  "பரியரை",
  "பர்ணம்",
  "பதிபடை",
  "படையாள்",
  "பட்டிகை",
  "பற்றற",
  "பக்கிரி",
  "பரியல்",
  "பசிதம்",
  "பழநடை",
  "பவிகம்",
  "பவணர்",
  "பயோதம்",
  "பவணம்",
  "பப்பாளி",
  "பல்பல",
  "பருத்தி",
  "பள்ளாடு",
  "பவழம்",
  "பகைவன்",
  "பட்டிமை",
  "பனைவாரை",
  "பவரணை",
  "பலண்டு",
  "பனையன்",
  "பள்ளர்",
  "பதவிசு",
  "பதிகன்",
  "பதமம்",
  "பரட்டை",
  "பராபவ",
  "படுமுறை",
  "பன்னம்",
  "பள்ளம்",
  "பகலாணி",
  "பறவாதி",
  "பஃறொடை",
  "படரடி",
  "பலகறை",
  "படுபொய்",
  "பணீசன்",
  "பரீதாபி",
  "பட்டினி",
  "பனவன்",
  "பரிபாகி",
  "பனங்கை",
  "பயோததி",
  "பகாநிலை",
  "பன்னல்",
  "பயிற்றி",
  "பனாத்து",
  "பரதாரி",
  "பாடிலம்",
  "பாதலம்",
  "பாபத்தி",
  "பாசவல்",
  "பாரதர்",
  "பாலவன்",
  "பாய்தல்",
  "பாசவர்",
  "பாண்டுரை",
  "பாவனன்",
  "பாழ்வீடு",
  "பாரதம்",
  "பாகபுடி",
  "பாடிரம்",
  "பாவுதல்",
  "பாளிதம்",
  "பாலம்மை",
  "பாவனம்",
  "பாடுவான்",
  "பாவுகல்",
  "பாடனம்",
  "பாலகம்",
  "பானியம்",
  "பாவாடம்",
  "பாப்புரி",
  "பாசவினை",
  "பாய்ச்சு",
  "பாவாற்றி",
  "பாய்ச்சி",
  "பாம்பாடி",
  "பாத்தூண்",
  "பாய்ச்சை",
  "பாவிகம்",
  "பாலிறுவி",
  "பாற்சோறு",
  "பாதுகம்",
  "பாப்பம்",
  "பாகுடம்",
  "பாதசாரி",
  "பாரிடம்",
  "பானகம்",
  "பாழ்வெளி",
  "பாற்றம்",
  "பாக்கழி",
  "பார்வல்",
  "பாவாணர்",
  "பாராசாரி",
  "பாக்கல்",
  "பாச்சல்",
  "பாசறவு",
  "பாணிதம்",
  "பாடிவீடு",
  "பாயிரம்",
  "பாசிவரி",
  "பாசிதம்",
  "பானைமூடி",
  "பாகுபாடு",
  "பாடவள்",
  "பாசமாலை",
  "பாதனம்",
  "பாக்கம்",
  "பாகுலம்",
  "பாலலீலை",
  "பாட்டாளி",
  "பால்மடி",
  "பாட்டாள்",
  "பாகலன்",
  "பாலொடுவை",
  "பாயசம்",
  "பார்க்க",
  "பாலியம்",
  "பாறுதல்",
  "பாரசிகை",
  "பாளயம்",
  "பாரங்கு",
  "பாவினம்",
  "பாகலம்",
  "பாந்தன்",
  "பாசாண்டி",
  "பாளையம்",
  "பாவியர்",
  "பாசகன்",
  "பாக்கன்",
  "பாவியம்",
  "பாரகன்",
  "பாரிகன்",
  "பாரகம்",
  "பால்கோவா",
  "பாவோடல்",
  "பாடுநர்",
  "பாடிதம்",
  "பாவகாரி",
  "பாடுவன்",
  "பாலுண்ணி",
  "பாரவம்",
  "பாலகன்",
  "பாடாணம்",
  "பாழ்மூலை",
  "பாலியன்",
  "பாதசேவை",
  "பாய்த்து",
  "பாகதாரி",
  "பாதபூசை",
  "பாமகள்",
  "பாலுகம்",
  "பாசகம்",
  "பாழாதல்",
  "பால்மறை",
  "பாசுரம்",
  "பாய்விரி",
  "பார்படை",
  "பாததூளி",
  "பாங்கர்",
  "பாந்தல்",
  "பாடசாலை",
  "பாமரம்",
  "பாணத்தி",
  "பாரணம்",
  "பாண்டம்",
  "பானசம்",
  "பாணிசம்",
  "பாலைமணி",
  "பாரிசம்",
  "பாணிப்பூ",
  "பாணிப்பு",
  "பாணிச்சி",
  "பானீயம்",
  "பாந்தம்",
  "பாடகன்",
  "பாப்பான்",
  "பாடியம்",
  "பாடுதல்",
  "பானண்டு",
  "பாண்டல்",
  "பாக்கிலை",
  "பாரியாள்",
  "பாங்கோர்",
  "பாங்கன்",
  "பாடீரம்",
  "பாற்பசு",
  "பாவகன்",
  "பாலமணி",
  "பாலிசன்",
  "பாடவன்",
  "பாற்குனி",
  "பாசடம்",
  "பாசபாணி",
  "பாராட்டு",
  "பாவட்டை",
  "பாதாளம்",
  "பாசிமணி",
  "பாரியம்",
  "பாட்டன்",
  "பாந்தள்",
  "பாடகம்",
  "பாடாவதி",
  "பாவகம்",
  "பாலிசம்",
  "பாஞ்சாலி",
  "பாடவம்",
  "பாதிமதி",
  "பாதேயம்",
  "பாலையாழ்",
  "பாசிநிலை",
  "பாடலம்",
  "பாதிமம்",
  "பாசாங்கு",
  "பாசவீடு",
  "பாற்கூழ்",
  "பாகியம்",
  "பாடணம்",
  "பாட்டம்",
  "பாரிப்பு",
  "பாசீகன்",
  "பாத்தல்",
  "பாகதம்",
  "பாதபம்",
  "பாத்தில்",
  "பாச்சிகை",
  "பாவலர்",
  "பாசனம்",
  "பாணிநடை",
  "பாழிவாய்",
  "பாதகன்",
  "பார்ப்பு",
  "பார்ப்பி",
  "பால்வழு",
  "பாசிவிலை",
  "பாத்திரை",
  "பானுபலை",
  "பாதிரம்",
  "பாகாரம்",
  "பாத்தம்",
  "பாய்மாலி",
  "பாணச்சி",
  "பாசினம்",
  "பாட்பம்",
  "பாகுவன்",
  "பாண்டில்",
  "பாதகம்",
  "பான்மடை",
  "பாலனன்",
  "பாகசாலை",
  "பாடுதுறை",
  "பாகீரதி",
  "பாமரன்",
  "பாம்பணை",
  "பாற்பல்",
  "பாம்புரி",
  "பாலேயம்",
  "பார்வதி",
  "பாதவம்",
  "பாற்காரி",
  "பாராயணி",
  "பாலனம்",
  "பிணைதல்",
  "பிசுனன்",
  "பிசினம்",
  "பிரம்பு",
  "பிலகரி",
  "பிப்பிலி",
  "பிங்காசி",
  "பிடுக்கு",
  "பின்மழை",
  "பிலவம்",
  "பிறைக்கை",
  "பிசுனாறி",
  "பிரதிபை",
  "பிணையிலி",
  "பிப்பலி",
  "பிரேரணை",
  "பிறக்கு",
  "பிக்குணி",
  "பிளிறல்",
  "பிழியல்",
  "பிரிநிலை",
  "பிணிவீடு",
  "பிணாரம்",
  "பினாகம்",
  "பிய்தல்",
  "பின்னோன்",
  "பின்பனி",
  "பிணிதல்",
  "பிரிவினை",
  "பிராக்கு",
  "பின்னாலே",
  "பிழுக்கை",
  "பிணியன்",
  "பிராமணி",
  "பிழிதல்",
  "பிரஞ்ஞை",
  "பிள்ளைமை",
  "பிராணம்",
  "பிசினன்",
  "பிணையல்",
  "பிரவிடை",
  "பிங்கள",
  "பிராணன்",
  "பில்லடை",
  "பிழிஞன்",
  "பிரதிமை",
  "பிருகதி",
  "பிரவுடை",
  "பிணைச்சு",
  "பிடிகம்",
  "பிறப்பு",
  "பிணைப்பு",
  "பிருந்தை",
  "பிராயம்",
  "பிழக்கு",
  "பிறைசூடி",
  "பியந்தை",
  "பிம்பம்",
  "பிரபவ",
  "பித்தேறி",
  "பிரகதி",
  "பிபீலிகை",
  "பின்மாலை",
  "பிழைபாடு",
  "பிரேயம்",
  "பிணைமாடு",
  "பிசுக்கு",
  "பிரட்டு",
  "பிரோகம்",
  "பிரிமணை",
  "பின்னாடி",
  "பின்மோனை",
  "பின்மாரி",
  "பிராத்தி",
  "பிரௌடம்",
  "பிலம்பி",
  "பிராசம்",
  "பிரமாணி",
  "பிற்றல்",
  "பிரமாதி",
  "பிரமாதா",
  "பிணவல்",
  "பிச்சுவா",
  "பிழைமொழி",
  "பிரண்டை",
  "பிரதனை",
  "பிணக்கு",
  "பிணையாளி",
  "பிருகுடி",
  "பின்னோடே",
  "பிரப்பு",
  "பிரகலை",
  "பிரதமை",
  "பித்திகை",
  "பிசுனம்",
  "பிழம்பு",
  "பிறனில்",
  "பிலிம்பி",
  "பிரதானை",
  "பிடகன்",
  "பிரசினை",
  "பிண்டிகை",
  "பிரதானி",
  "பிலிஞ்சி",
  "பின்னனை",
  "பிரேதம்",
  "பின்றாவி",
  "பின்னன்",
  "பிரிசல்",
  "பிறாண்டு",
  "பித்தன்",
  "பிச்சன்",
  "பிராட்டி",
  "பிராந்து",
  "பிராந்தி",
  "பிசானம்",
  "பிண்டாரி",
  "பிழைப்பு",
  "பினாகினி",
  "பிரிசம்",
  "பிராப்தி",
  "பிலாச்சை",
  "பின்னிலை",
  "பிலுக்கு",
  "பிடங்கு",
  "பிதற்று",
  "பிறழ்வு",
  "பிலுக்கி",
  "பிளப்பு",
  "பிடித்து",
  "பிரமன்",
  "பிங்கலை",
  "பிரமரி",
  "பின்னர்",
  "பின்னரை",
  "பிதிரர்",
  "பிணிப்பு",
  "பிறியோலை",
  "பித்தம்",
  "பிலாக்கு",
  "பிரியாமை",
  "பிடவம்",
  "பிசாசம்",
  "பின்னல்",
  "பிரமம்",
  "பிறந்தை",
  "பிங்களை",
  "பிடகம்",
  "பிவாயம்",
  "பிடிபாடு",
  "பிசினேறி",
  "பிங்கம்",
  "பிடாரன்",
  "பிஞ்சம்",
  "பித்தளை",
  "பிதுரம்",
  "பினைதல்",
  "பிராவம்",
  "பிடிதம்",
  "பித்தல்",
  "பிற்பாடு",
  "பின்னம்",
  "பிதாமகி",
  "பிசாசன்",
  "பிசனம்",
  "பிருதிவி",
  "பிசாத்து",
  "பிடியாள்",
  "பிணியோலை",
  "பிரியன்",
  "பிரசம்",
  "பிதகம்",
  "பிறவினை",
  "பிண்டம்",
  "பிசுகம்",
  "பிளிச்சு",
  "பிரதீசி",
  "பிரிதல்",
  "பிறங்கு",
  "பிளாச்சி",
  "பிளாச்சு",
  "பிடிவாதி",
  "பிரசலை",
  "பிக்காரி",
  "பிசைதல்",
  "பிட்டம்",
  "பின்றலை",
  "பிக்கம்",
  "பிடகாரி",
  "பிசிதம்",
  "பிற்கால்",
  "பின்னணி",
  "பிரிப்பு",
  "பின்னணை",
  "பிசினாறி",
  "பிரமிதி",
  "பிசக்கு",
  "பிணியாளி",
  "பில்லாணி",
  "பிற்படை",
  "பிச்சம்",
  "பிரயாசை",
  "பிரியம்",
  "பிடிநாள்",
  "பின்னும்",
  "பிட்டன்",
  "பிடிப்பு",
  "பிடியல்",
  "பின்னடி",
  "பிலகாரி",
  "பீஞ்சல்",
  "பீட்டன்",
  "பீதகம்",
  "பீர்க்கு",
  "பீலிவாகை",
  "பீடபூமி",
  "பீங்கான்",
  "பீலியார்",
  "பீனிசம்",
  "பீலுகம்",
  "பீடணம்",
  "பீவரம்",
  "பீனசம்",
  "பீருகம்",
  "பீடிப்பு",
  "பீபற்சு",
  "பீடரம்",
  "பீறுதல்",
  "பீதகன்",
  "பீரங்கி",
  "பீரெனல்",
  "பீருகன்",
  "பீப்பாய்",
  "பீற்றல்",
  "பீந்தோல்",
  "பீத்தல்",
  "பீத்தோல்",
  "பீலகம்",
  "பீலுகன்",
  "பீழ்தல்",
  "பீக்கம்",
  "பீச்சல்",
  "பீடனம்",
  "புண்ணளை",
  "புளினம்",
  "புற்றாளி",
  "புரியம்",
  "புனைவுளி",
  "புத்துரை",
  "புரூணம்",
  "புல்வாய்",
  "புல்லரி",
  "புங்கம்",
  "புன்னகை",
  "புல்லர்",
  "புக்கில்",
  "புதினம்",
  "புகற்சி",
  "புறவடி",
  "புன்கம்",
  "புகழ்மை",
  "புனவர்",
  "புக்குழி",
  "புகல்வி",
  "புரிநூல்",
  "புகல்வு",
  "புறநடை",
  "புளிமாறு",
  "புலத்தி",
  "புஞ்சம்",
  "புடையன்",
  "புரீடம்",
  "புனிற்றா",
  "புரூரம்",
  "புலாகம்",
  "புரசல்",
  "புதுநிறை",
  "புத்தேள்",
  "புன்சொல்",
  "புரைசல்",
  "புலியுறை",
  "புதியர்",
  "புணர்வு",
  "புள்ளடி",
  "புதல்வி",
  "புலைத்தி",
  "புறத்தி",
  "புற்பதி",
  "புட்டில்",
  "புயகம்",
  "புதையல்",
  "புறமலை",
  "புரையுள்",
  "புரோகதி",
  "புங்கன்",
  "புன்செய்",
  "புன்கண்",
  "புரிசம்",
  "புகழ்வு",
  "புரிநாண்",
  "புறவணி",
  "புத்தன்",
  "புளிஞன்",
  "புனிதன்",
  "புரோசர்",
  "புலவன்",
  "புடைநூல்",
  "புராணன்",
  "புறவிடை",
  "புறமடை",
  "புசகம்",
  "புலையன்",
  "புதியோர்",
  "புட்டம்",
  "புரைதல்",
  "புச்சம்",
  "புலுதம்",
  "புராணம்",
  "புலையாடி",
  "புனைவன்",
  "புதுமாடு",
  "புதற்பூ",
  "புன்றலை",
  "புத்தேன்",
  "புல்லணை",
  "புயக்கு",
  "புல்லுரு",
  "புலர்வு",
  "புறங்கை",
  "புல்லன்",
  "புடைப்பு",
  "புண்ணீர்",
  "புலவல்",
  "புங்கவி",
  "புரியணை",
  "புலைஞர்",
  "புன்றுமி",
  "புசிப்பு",
  "புதுவோர்",
  "புக்தம்",
  "புரையன்",
  "புனவேடு",
  "புன்மாலை",
  "புன்னெறி",
  "புரைப்பு",
  "புறவுரு",
  "புறவுரை",
  "புல்லம்",
  "புல்வீடு",
  "புறநிலை",
  "புளினன்",
  "புவனம்",
  "புரிவளை",
  "புடையல்",
  "புரிதல்",
  "புண்வாய்",
  "புருவம்",
  "புலவர்",
  "புலவரை",
  "புரிசடை",
  "புல்லிது",
  "புகையிலை",
  "புறனிலை",
  "புட்பம்",
  "புருடன்",
  "புலவோன்",
  "புனையல்",
  "புடகம்",
  "புடைமண்",
  "புளியாரை",
  "புத்தோடு",
  "புகையுறை",
  "புன்னீர்",
  "புரப்பு",
  "புழுவதை",
  "புரையோர்",
  "புரண்டை",
  "புனைமொழி",
  "புற்றம்",
  "புட்பறை",
  "புளுகன்",
  "புளைதல்",
  "புய்தல்",
  "புட்டல்",
  "புத்தர்",
  "புல்லார்",
  "புலம்பு",
  "புருடம்",
  "புதுநடை",
  "புறவாழி",
  "புதானன்",
  "புனிதம்",
  "புனைதல்",
  "புலர்பு",
  "புலியூர்",
  "புறக்கு",
  "புறக்கை",
  "புல்லிகை",
  "புலோமசை",
  "புனைவலி",
  "புலுட்டை",
  "புளியோரை",
  "புழுக்கை",
  "புல்லாள்",
  "புழுக்கு",
  "புளிதம்",
  "புள்ளீடு",
  "புத்தாடை",
  "புறம்பு",
  "புத்தம்",
  "புனனாடு",
  "புயவலி",
  "புல்லூறு",
  "புதைகுழி",
  "புளிப்பு",
  "புன்காலி",
  "புல்லூரி",
  "புயலேறு",
  "புறவம்",
  "புராதனி",
  "புரணம்",
  "புறவீடு",
  "புகரோன்",
  "புகைதல்",
  "புதவம்",
  "புரட்சி",
  "புறவீதி",
  "புலைச்சி",
  "புதியது",
  "புளிச்சி",
  "புறமாக",
  "புழைக்கை",
  "புண்ணியை",
  "புளிச்சை",
  "புரோகன்",
  "புறவழி",
  "புரிமணை",
  "புரோகம்",
  "புணர்தை",
  "புடாயம்",
  "புதைதல்",
  "புள்ளம்",
  "புலானீர்",
  "புகுதல்",
  "புகர்வு",
  "புரட்டை",
  "புரட்டு",
  "புறனடை",
  "புட்கலை",
  "புலச்சி",
  "புறவரி",
  "புத்திரி",
  "புரோதம்",
  "புல்லாணி",
  "புறனுரை",
  "புற்கம்",
  "புறமொழி",
  "புதுவது",
  "புளகம்",
  "பூமகன்",
  "பூவழலை",
  "பூந்தேன்",
  "பூவொல்லி",
  "பூதிகம்",
  "பூகலம்",
  "பூமிவேர்",
  "பூழியன்",
  "பூசனம்",
  "பூமாதுளை",
  "பூப்போல",
  "பூதவீடு",
  "பூன்றம்",
  "பூதாரன்",
  "பூனைமூலி",
  "பூடணம்",
  "பூச்சேலை",
  "பூசிப்பு",
  "பூமங்கை",
  "பூங்காடு",
  "பூகதம்",
  "பூம்பிடா",
  "பூலோகம்",
  "பூக்கோள்",
  "பூமுகம்",
  "பூட்டன்",
  "பூகதர்",
  "பூசுத்தி",
  "பூகாகம்",
  "பூளைப்பூ",
  "பூளைசூடி",
  "பூதவம்",
  "பூநிறம்",
  "பூர்த்தி",
  "பூவிந்து",
  "பூழியான்",
  "பூமகள்",
  "பூவினன்",
  "பூவுலகு",
  "பூதானம்",
  "பூரகம்",
  "பூஞ்சான்",
  "பூம்புகை",
  "பூரணன்",
  "பூததயை",
  "பூரிகம்",
  "பூதவாளி",
  "பூங்கோரை",
  "பூரிமாயு",
  "பூசகன்",
  "பூதநாடி",
  "பூகரம்",
  "பூரிதம்",
  "பூஞ்சல்",
  "பூம்பாளை",
  "பூத்தல்",
  "பூசுதன்",
  "பூசந்தி",
  "பூகேசம்",
  "பூவரசு",
  "பூரியார்",
  "பூணுதல்",
  "பூருகம்",
  "பூணிப்பு",
  "பூக்குழி",
  "பூங்காவி",
  "பூருவம்",
  "பூதரம்",
  "பூதவுரு",
  "பூங்கணை",
  "பூசிதம்",
  "பூதிமம்",
  "பூதபதி",
  "பூநாகம்",
  "பூவமளி",
  "பூக்கம்",
  "பூச்சிலை",
  "பூராடம்",
  "பூதரன்",
  "பூராயம்",
  "பூர்ணிமை",
  "பூந்தூள்",
  "பூழ்க்கை",
  "பூர்வம்",
  "பூசிதன்",
  "பூரணமி",
  "பூசணம்",
  "பூதலம்",
  "பூருண்டி",
  "பூமிசம்",
  "பூண்டான்",
  "பூநாதம்",
  "பூரணம்",
  "பூகதன்",
  "பூதவாதி",
  "பூனதம்",
  "பூரியம்",
  "பூசாவிதி",
  "பூதாரம்",
  "பூபாளம்",
  "பூமாதேவி",
  "பூதாவேசி",
  "பூமருது",
  "பூவைநிலை",
  "பூசுரன்",
  "பூர்ணம்",
  "பூசுதல்",
  "பூரியர்",
  "பூரிமம்",
  "பூலத்தி",
  "பூவந்தி",
  "பூஞ்சோலை",
  "பூர்தல்",
  "பூப்பலி",
  "பூமிசன்",
  "பூசைவேளை",
  "பூமிதேவி",
  "பூந்தாது",
  "பூணாரம்",
  "பூபாகம்",
  "பூதியம்",
  "பூமடல்",
  "பூவிதழ்",
  "பூணுநூல்",
  "பூதேசன்",
  "பூங்கொடி",
  "பூந்தோடு",
  "பூபாலன்",
  "பூரபதி",
  "பூரிப்பு",
  "பெய்துரை",
  "பெண்ணடி",
  "பெருமலை",
  "பெருநாள்",
  "பெண்டகை",
  "பெருப்பு",
  "பெருநாரை",
  "பெருநிலை",
  "பெற்றோர்",
  "பெயரன்",
  "பெரியார்",
  "பெரியோன்",
  "பெண்சாதி",
  "பெரியகை",
  "பெய்தல்",
  "பெரியாயி",
  "பெட்டார்",
  "பெருங்கை",
  "பெம்மான்",
  "பெண்பால்",
  "பெருநடை",
  "பெருநீர்",
  "பெருவெளி",
  "பெண்பழி",
  "பெண்ணீலி",
  "பெத்தம்",
  "பெருவாடை",
  "பெண்ணாறு",
  "பெண்ணன்",
  "பெத்தர்",
  "பெருமழை",
  "பெருமாள்",
  "பெண்கரு",
  "பெத்தல்",
  "பெண்கோள்",
  "பெண்மதி",
  "பெயரிய",
  "பெண்வலை",
  "பெறுதல்",
  "பெயரேடு",
  "பெண்கலை",
  "பெயரடி",
  "பெருநகை",
  "பெருக்கி",
  "பெண்ணாள்",
  "பெருக்கு",
  "பெண்டன்",
  "பெறுமதி",
  "பெருநெறி",
  "பெண்பாடு",
  "பெண்வழி",
  "பெட்டல்",
  "பெருமடை",
  "பெருமுளை",
  "பெண்ணலி",
  "பெதும்பை",
  "பெரும்பூ",
  "பெருமான்",
  "பெருவாகை",
  "பெற்றான்",
  "பெந்தம்",
  "பெரியர்",
  "பெற்றிமை",
  "பெண்ணாசை",
  "பெற்றம்",
  "பெட்டன்",
  "பெற்றார்",
  "பெண்குறி",
  "பெருவழி",
  "பெகுலம்",
  "பெருவிலை",
  "பெருவாரி",
  "பெரியோர்",
  "பெருவர்",
  "பெருவரை",
  "பெண்பனை",
  "பெருவலி",
  "பெருகல்",
  "பேராழம்",
  "பேராண்மை",
  "பேர்வழி",
  "பேர்தல்",
  "பேணியார்",
  "பேரளவு",
  "பேரியல்",
  "பேடிசம்",
  "பேழ்வாய்",
  "பேதகம்",
  "பேராண்டு",
  "பேரியாழ்",
  "பேயோடாடி",
  "பேணகம்",
  "பேரருவி",
  "பேரீந்து",
  "பேதகன்",
  "பேணுநர்",
  "பேராளன்",
  "பேரியம்",
  "பேதுறவு",
  "பேசலம்",
  "பேலகம்",
  "பேருதவி",
  "பேரசம்",
  "பேடாடல்",
  "பேருந்து",
  "பேரத்தி",
  "பேய்ச்சி",
  "பேசிலம்",
  "பேர்த்து",
  "பேதிதம்",
  "பேதிகாரி",
  "பேர்பாதி",
  "பேரறிவு",
  "பேணுதல்",
  "பேரவ்வை",
  "பேர்த்தி",
  "பேரீச்சு",
  "பேடணம்",
  "பேணலர்",
  "பேய்நாய்",
  "பேணலார்",
  "பேருண்டி",
  "பேயத்தி",
  "பேனாசனி",
  "பேரிளமை",
  "பேசாநிலை",
  "பேயோட்டி",
  "பேசுதல்",
  "பேச்சாளி",
  "பேராட்டி",
  "பேலுதல்",
  "பேராந்தை",
  "பேடகம்",
  "பேசங்கை",
  "பேய்நிலை",
  "பேசகம்",
  "பேயுள்ளி",
  "பைசந்தி",
  "பைத்தல்",
  "பைஞ்ஞீல்",
  "பையாப்பு",
  "பைங்குழி",
  "பைந்தொடி",
  "பைரவன்",
  "பைப்பய",
  "பைஞ்சேறு",
  "பைஞ்சாய்",
  "பைந்தார்",
  "பைரவர்",
  "பைபீலம்",
  "பைந்தினை",
  "பைம்பொன்",
  "பைம்பூண்",
  "பையரவு",
  "பைங்கொடி",
  "பைபீலிகை",
  "பையோடதி",
  "பைங்கூழ்",
  "பைதிரம்",
  "பைங்கிளி",
  "பைசுனம்",
  "பைரவம்",
  "பைங்கண்",
  "பைசாசம்",
  "பைஞ்ஞீலி",
  "பைக்கம்",
  "பையெனல்",
  "பொதும்பு",
  "பொய்ச்சு",
  "பொன்னாசை",
  "பொன்மலை",
  "பொய்யிடை",
  "பொய்ப்பூ",
  "பொய்ப்பு",
  "பொறைநிலை",
  "பொற்காசு",
  "பொக்கன்",
  "பொடியல்",
  "பொற்றொடி",
  "பொன்னேர்",
  "பொளிதல்",
  "பொருநன்",
  "பொன்னாடை",
  "பொருளுரை",
  "பொட்டணி",
  "பொதிமாடு",
  "பொதுவாள்",
  "பொறுப்பு",
  "பொற்கோள்",
  "பொய்யன்",
  "பொளிமண்",
  "பொருமலி",
  "பொன்னன்",
  "பொக்கம்",
  "பொருமல்",
  "பொழிதல்",
  "பொன்மழை",
  "பொதிர்வு",
  "பொடுதலை",
  "பொல்லாமை",
  "பொற்சபை",
  "பொருட்டு",
  "பொன்மாலை",
  "பொதுவில்",
  "பொல்லாத",
  "பொகுவல்",
  "பொதியம்",
  "பொடிப்பு",
  "பொற்கசை",
  "பொதுதல்",
  "பொந்தர்",
  "பொச்சம்",
  "பொருட்கை",
  "பொங்கடி",
  "பொல்லான்",
  "பொக்கல்",
  "பொருணூல்",
  "பொற்பாவை",
  "பொய்யாணை",
  "பொருளாசை",
  "பொய்தல்",
  "பொதுவர்",
  "பொன்னணி",
  "பொரியரை",
  "பொற்றேரை",
  "பொதியன்",
  "பொல்லாது",
  "பொருணிலை",
  "பொற்படை",
  "பொற்கிழி",
  "பொருளறை",
  "பொற்படி",
  "பொதுநெறி",
  "பொருண்மை",
  "பொதுக்கை",
  "பொதுக்கு",
  "பொறியிலி",
  "பொன்னுரை",
  "பொதிதல்",
  "பொன்னூல்",
  "பொரியல்",
  "பொலிவேடு",
  "பொடியாடி",
  "பொருக்கு",
  "பொற்காரை",
  "பொய்க்க",
  "பொய்யாமை",
  "பொதுமொழி",
  "பொதுவாக",
  "பொற்புவி",
  "பொருவிலி",
  "பொதியில்",
  "பொன்வரை",
  "பொன்வரி",
  "பொய்ம்மை",
  "பொழிப்பு",
  "பொலிகடா",
  "பொருதல்",
  "பொத்தாறு",
  "பொடுகன்",
  "பொற்பொது",
  "பொடியாணி",
  "பொய்க்கை",
  "பொருபுவி",
  "பொருளாள்",
  "பொசிதல்",
  "பொழுதோடு",
  "பொறியறை",
  "பொய்யடி",
  "பொல்லர்",
  "பொருளன்",
  "பொரிதல்",
  "பொலிகாளை",
  "பொருத்து",
  "பொற்றோரை",
  "பொட்டல்",
  "பொதியறை",
  "பொதுவிதி",
  "பொல்லம்",
  "பொன்மணி",
  "பொய்யாறு",
  "பொந்திகை",
  "பொள்ளல்",
  "பொடுதிலை",
  "பொருக்க",
  "பொறிதல்",
  "பொன்விலை",
  "பொதிசோறு",
  "பொங்கல்",
  "பொற்றாலி",
  "பொதுமாது",
  "பொன்னரி",
  "பொடிதல்",
  "பொன்னறை",
  "பொம்மலி",
  "பொத்தல்",
  "பொங்கர்",
  "பொருநல்",
  "பொங்கம்",
  "பொடியன்",
  "பொன்விழா",
  "பொம்மல்",
  "பொடியீர்",
  "பொருப்பு",
  "பொறையன்",
  "பொக்குள்",
  "பொறிப்பு",
  "பொன்பாவை",
  "பொக்கணை",
  "பொன்னோலை",
  "பொன்னோர்",
  "பொலிவீடு",
  "பொக்கணி",
  "பொய்யறை",
  "பொட்டிலி",
  "பொங்கழி",
  "பொட்டில்",
  "பொற்சிலை",
  "பொலங்கா",
  "பொன்னாசி",
  "பொகுட்டு",
  "பொலிதல்",
  "பொல்லது",
  "போதிப்பு",
  "போகுதல்",
  "போதரவு",
  "போடகம்",
  "போசகம்",
  "போதுதல்",
  "போக்கடி",
  "போகநீர்",
  "போர்முனை",
  "போலிமொழி",
  "போட்கன்",
  "போக்கம்",
  "போடுதல்",
  "போழ்தல்",
  "போகபூமி",
  "போக்காளி",
  "போலியாள்",
  "போர்ப்பை",
  "போர்ப்பு",
  "போர்ப்பூ",
  "போத்திரி",
  "போக்கிலி",
  "போக்காடு",
  "போக்கன்",
  "போருந்து",
  "போதிகன்",
  "போழ்வாய்",
  "போலுதல்",
  "போலிநடை",
  "போக்கிரி",
  "போகவழி",
  "போர்முள்",
  "போசனம்",
  "போழ்முகி",
  "போற்றன்",
  "போலிவேலை",
  "போற்றார்",
  "போதகன்",
  "போடுகாடு",
  "போயிற்று",
  "போங்கம்",
  "போருதவி",
  "போசக்கை",
  "போதியார்",
  "போதினன்",
  "போம்பல்",
  "போனகம்",
  "போதந்து",
  "போருதல்",
  "போற்றிமை",
  "போகபதி",
  "போற்றீடு",
  "போதகம்",
  "பௌர்ணமி",
  "பௌரகம்",
  "பௌழியம்",
  "பௌர்ணமை",
  "பௌத்திரி",
  "பௌத்தன்",
  "பௌதிகன்",
  "பௌரிகன்",
  "பௌதீகம்",
  "பௌழியன்",
  "பௌடிகம்",
  "பௌதிகம்",
  "மலாஞ்சி",
  "மறையீறு",
  "மண்ணீர்",
  "மரமுலை",
  "மனைவழி",
  "மகிழம்",
  "மலூகம்",
  "மலநீர்",
  "மத்தன்",
  "மலையாளி",
  "மகரம்",
  "மரையான்",
  "மவ்வல்",
  "மண்கணை",
  "மணிமாலை",
  "மதரணி",
  "மலையாள்",
  "மண்மழை",
  "மந்தாரை",
  "மருபுகா",
  "மணவறை",
  "மகிணன்",
  "மனைவீடு",
  "மச்சம்",
  "மசிகம்",
  "மசகம்",
  "மண்மலி",
  "மகாராணி",
  "மருளாளி",
  "மடங்கு",
  "மறுபாவி",
  "மகுலம்",
  "மஞ்சிகை",
  "மகாநேமி",
  "மஞ்சள்",
  "மகிடம்",
  "மம்மர்",
  "மரவாடி",
  "மற்றும்",
  "மணாளன்",
  "மனுவர்",
  "மருட்பா",
  "மராளம்",
  "மருப்பு",
  "மலைநாடு",
  "மகாதேவி",
  "மடிதல்",
  "மஞ்சாரி",
  "மனோவாயு",
  "மகேசன்",
  "மனையாள்",
  "மதியிலி",
  "மணிமான்",
  "மறைபதி",
  "மட்பகை",
  "மரவாணி",
  "மஞ்சாளி",
  "மற்றது",
  "மலைநெல்",
  "மட்டிலை",
  "மகாண்டு",
  "மண்ணுணி",
  "மடையன்",
  "மயூராரி",
  "மயேசன்",
  "மருங்கு",
  "மகீபன்",
  "மண்புரை",
  "மருங்கை",
  "மச்சான்",
  "மந்தினி",
  "மல்லிகை",
  "மகசர்",
  "மறப்பு",
  "மறுமாடி",
  "மனோபூசை",
  "மஞ்சன்",
  "மடவன்",
  "மஞ்சனி",
  "மறுபடி",
  "மனிசன்",
  "மயிடம்",
  "மத்தாடி",
  "மல்லாய்",
  "மஞ்சரி",
  "மகன்மை",
  "மல்லாரி",
  "மஞ்சம்",
  "மலடன்",
  "மகீபதி",
  "மண்சேய்",
  "மசுரம்",
  "மண்டூகை",
  "மதிப்ப",
  "மனவிழி",
  "மணவணி",
  "மழுவோன்",
  "மன்னினை",
  "மஞ்சணை",
  "மச்சாள்",
  "மப்பன்",
  "மதலிகை",
  "மரையாடு",
  "மருண்மா",
  "மலைதல்",
  "மண்குகை",
  "மந்திரி",
  "மதர்வை",
  "மடவள்",
  "மனோகதி",
  "மதியம்",
  "மணிநீர்",
  "மலைநார்",
  "மஞ்சாடி",
  "மழுவன்",
  "மறுமொழி",
  "மடக்கு",
  "மறிந்து",
  "மனோசியை",
  "மணாட்டு",
  "மகாகிதி",
  "மகரன்",
  "மலைப்பு",
  "மறையிறை",
  "மணங்கு",
  "மதர்வு",
  "மகாதோரை",
  "மதாரம்",
  "மண்பொறி",
  "மதோமதி",
  "மகிளம்",
  "மழையான்",
  "மதிஞன்",
  "மறைக்கோ",
  "மணிவலை",
  "மலாரம்",
  "மதுவம்",
  "மயானம்",
  "மடியுறை",
  "மருதாணி",
  "மரவிடை",
  "மயக்கு",
  "மன்மத",
  "மஞ்சூடை",
  "மண்மூசை",
  "மகீலதை",
  "மறுபாடு",
  "மச்சாவி",
  "மருதணி",
  "மச்சிகை",
  "மண்சிலை",
  "மதிகம்",
  "மயிலெள்",
  "மகாநதி",
  "மறுத்து",
  "மனையிறை",
  "மறுகரை",
  "மகமேரு",
  "மதவலி",
  "மகாதசை",
  "மணிதனு",
  "மண்பாடு",
  "மறிதல்",
  "மரீசிகை",
  "மறுமுள்",
  "மருதம்",
  "மதுபானி",
  "மருதூணி",
  "மயேடம்",
  "மரணம்",
  "மறுமுறை",
  "மண்ணாசை",
  "மடிபிடி",
  "மறுகல்",
  "மகாகாளி",
  "மசடன்",
  "மகவதி",
  "மலிசம்",
  "மன்றன்",
  "மறைப்பு",
  "மனையோள்",
  "மதிமான்",
  "மறைசொல்",
  "மகுடம்",
  "மறவுரை",
  "மகிபன்",
  "மருட்டி",
  "மன்றம்",
  "மருட்டு",
  "மனநோய்",
  "மச்சினி",
  "மணப்பு",
  "மகுரம்",
  "மகாமேரு",
  "மகவன்",
  "மழநாடு",
  "மகாசபை",
  "மகடூஉ",
  "மரயம்",
  "மரமேறி",
  "மழையேறு",
  "மக்கள்",
  "மட்டும்",
  "மனோசன்",
  "மதியுணி",
  "மகமாயி",
  "மன்றல்",
  "மங்கினி",
  "மதுரம்",
  "மறத்தி",
  "மரியாதை",
  "மலவைரி",
  "மனநோவு",
  "மகாகோடி",
  "மருவீடு",
  "மண்பார்",
  "மருட்சி",
  "மண்வாரி",
  "மகதம்",
  "மட்டம்",
  "மண்டிகை",
  "மவுணன்",
  "மசிதல்",
  "மணிமுடி",
  "மறையோன்",
  "மறமலி",
  "மருட்கை",
  "மரிசம்",
  "மன்னன்",
  "மத்திகை",
  "மறுப்ப",
  "மன்றில்",
  "மனிதன்",
  "மண்மேடு",
  "மருமான்",
  "மதூகம்",
  "மணிக்கை",
  "மக்கன்",
  "மலட்டா",
  "மள்ளன்",
  "மடன்மா",
  "மயிலடி",
  "மகாமேதை",
  "மதமொய்",
  "மதநீர்",
  "மருவாளி",
  "மகாகவி",
  "மதவாதி",
  "மசக்கை",
  "மருவார்",
  "மகபதி",
  "மகாமாயி",
  "மறையான்",
  "மனாலம்",
  "மகாமாயை",
  "மனிச்சு",
  "மண்சீலை",
  "மண்மாரி",
  "மகாமாரி",
  "மட்டன்",
  "மத்திமை",
  "மஃகான்",
  "மனோராசி",
  "மன்னல்",
  "மள்ளல்",
  "மண்வேலை",
  "மக்கல்",
  "மண்ணோர்",
  "மகவான்",
  "மங்களை",
  "மலப்பு",
  "மலப்பை",
  "மரவம்",
  "மற்கரை",
  "மசானம்",
  "மக்கர்",
  "மழையடை",
  "மள்ளம்",
  "மக்கம்",
  "மடைமாறி",
  "மண்டம்",
  "மலைமுழை",
  "மட்குகை",
  "மலைவாசி",
  "மண்டலி",
  "மனநிறை",
  "மணலேறு",
  "மடிதாறு",
  "மனநிலை",
  "மண்டர்",
  "மதத்து",
  "மலைமான்",
  "மயூகம்",
  "மறுநாள்",
  "மலைவாழை",
  "மறுப்பு",
  "மரநாய்",
  "மயிலம்",
  "மறுசொல்",
  "மலையடி",
  "மசண்டை",
  "மண்ணம்",
  "மகாபோதி",
  "மறுநோய்",
  "மகூலம்",
  "மறவோன்",
  "மங்கலி",
  "மங்கலை",
  "மங்கல்",
  "மண்ணறை",
  "மடுப்பு",
  "மன்பது",
  "மயேனம்",
  "மன்பதை",
  "மலக்கு",
  "மருமம்",
  "மனசார",
  "மணவினை",
  "மயிலாளி",
  "மனுசன்",
  "மதனன்",
  "மடமான்",
  "மகோததி",
  "மன்மம்",
  "மதைஇய",
  "மகாபூரி",
  "மணியம்",
  "மலைமாது",
  "மரவடி",
  "மடவாள்",
  "மக்கடா",
  "மயிலார்",
  "மந்துரை",
  "மடிப்பு",
  "மடிப்பை",
  "மரீசம்",
  "மகமுறை",
  "மதிகேடி",
  "மனோவதி",
  "மதிகேடு",
  "மதியாணி",
  "மகத்து",
  "மண்ணன்",
  "மகாபலை",
  "மதனம்",
  "மன்னும்",
  "மடவோர்",
  "மறைவிடை",
  "மருத்து",
  "மறுதாய்",
  "மசனம்",
  "மலிதல்",
  "மரக்கா",
  "மதுகம்",
  "மலையம்",
  "மதங்கி",
  "மடநடை",
  "மகிதம்",
  "மடைநூல்",
  "மருகன்",
  "மதாலம்",
  "மலங்கு",
  "மதிசூடி",
  "மனதார",
  "மதகம்",
  "மழுவாளி",
  "மதிதிசை",
  "மலையன்",
  "மழுவாள்",
  "மடவார்",
  "மற்சம்",
  "மதிதம்",
  "மதகரி",
  "மகண்மை",
  "மலரோன்",
  "மகண்மா",
  "மணமாலை",
  "மணிகம்",
  "மறைதல்",
  "மன்னார்",
  "மடையான்",
  "மறவன்",
  "மசூரம்",
  "மற்கோல்",
  "மட்டாய்",
  "மச்சுனி",
  "மதிநெறி",
  "மண்ணசை",
  "மறுவல்",
  "மறுகால்",
  "மனைவேலி",
  "மகளிர்",
  "மசகாரி",
  "மணவோலை",
  "மனமாசு",
  "மலரடி",
  "மல்லம்",
  "மதுபம்",
  "மறுதலை",
  "மகரிகை",
  "மழுமாறி",
  "மஞ்சிலை",
  "மஞ்சில்",
  "மந்தன்",
  "மனனம்",
  "மண்ணீடு",
  "மடியில்",
  "மன்றாடி",
  "மல்லன்",
  "மருகம்",
  "மலரணை",
  "மருளன்",
  "மனுடன்",
  "மரவுரி",
  "மறியல்",
  "மணிமலை",
  "மறைச்சி",
  "மழவன்",
  "மலகரி",
  "மஞ்சிறு",
  "மந்தமா",
  "மகாமுனி",
  "மந்தம்",
  "மணியாடி",
  "மலினம்",
  "மகிழ்வு",
  "மருந்து",
  "மல்லரி",
  "மயர்வு",
  "மவுனம்",
  "மறைமொழி",
  "மல்லர்",
  "மணியாசு",
  "மந்தல்",
  "மடந்தை",
  "மதுபதி",
  "மருளல்",
  "மசியல்",
  "மகராசி",
  "மல்லல்",
  "மதமலை",
  "மகசூல்",
  "மயற்கை",
  "மதப்பு",
  "மவ்வம்",
  "மத்தளி",
  "மண்கல்",
  "மனுதார்",
  "மனுதாரி",
  "மதிநாள்",
  "மதிப்பு",
  "மகம்பூ",
  "மங்குல்",
  "மத்தம்",
  "மர்மம்",
  "மலயம்",
  "மயூரம்",
  "மங்குலி",
  "மழுகூழை",
  "மதிரம்",
  "மனுநெறி",
  "மத்தமா",
  "மாபனம்",
  "மாகவதி",
  "மாசுகம்",
  "மாதுரம்",
  "மாசலம்",
  "மாயுநாடி",
  "மாறுதல்",
  "மாங்காய்",
  "மாலூர்தி",
  "மாலகம்",
  "மானிடன்",
  "மாட்டல்",
  "மானதன்",
  "மாதேவன்",
  "மாரவேள்",
  "மாதாமகி",
  "மாராயன்",
  "மாக்கல்",
  "மாகேசம்",
  "மாலுதல்",
  "மானவன்",
  "மாலியம்",
  "மாக்கள்",
  "மாகுலர்",
  "மாத்தகை",
  "மாயாதேவி",
  "மாந்தன்",
  "மாற்றுமை",
  "மானுதல்",
  "மானிடம்",
  "மாநிலம்",
  "மாருதன்",
  "மாரபேரி",
  "மாற்றான்",
  "மாச்சல்",
  "மாசாலம்",
  "மாட்டேறு",
  "மாறுகம்",
  "மாளுதல்",
  "மாவலான்",
  "மாச்சீர்",
  "மாத்தான்",
  "மாதவர்",
  "மாட்சிமை",
  "மார்கழி",
  "மாற்றம்",
  "மாலைநிலை",
  "மாராப்பு",
  "மாரதன்",
  "மாதவம்",
  "மாலையீடு",
  "மாண்டார்",
  "மாந்தம்",
  "மாரோடம்",
  "மாயிடம்",
  "மாசலன்",
  "மாசிகம்",
  "மாநிறம்",
  "மாற்றோர்",
  "மானியம்",
  "மாயிரம்",
  "மார்பாக",
  "மார்வலி",
  "மாரகம்",
  "மார்வம்",
  "மாலூரம்",
  "மாறுபாடு",
  "மானவாரி",
  "மாதலம்",
  "மாதிரிகை",
  "மாமலர்",
  "மாயவள்",
  "மாலவன்",
  "மாணாக்கி",
  "மானசம்",
  "மாண்டல்",
  "மாயேயம்",
  "மாத்திரை",
  "மாருதம்",
  "மாமோகம்",
  "மாரணம்",
  "மானுடம்",
  "மாய்ப்பு",
  "மாமாலம்",
  "மாமரம்",
  "மாகனன்",
  "மால்தொடை",
  "மாந்தர்",
  "மாதிகம்",
  "மாற்றாள்",
  "மாய்ச்சி",
  "மாராயம்",
  "மாறுகோள்",
  "மாறுகண்",
  "மாரிபம்",
  "மாசனம்",
  "மாதிருகை",
  "மாடியம்",
  "மாதுவான்",
  "மாணவன்",
  "மாடநிலை",
  "மாமாத்து",
  "மாளயம்",
  "மாரிமழை",
  "மாதவன்",
  "மாமனார்",
  "மான்றல்",
  "மாயவன்",
  "மாயூரம்",
  "மாசுமறு",
  "மான்றலை",
  "மாலையணி",
  "மாரிநாள்",
  "மாமல்லை",
  "மாகந்தி",
  "மாணவம்",
  "மாவடம்",
  "மாம்போழ்",
  "மாரகன்",
  "மாற்றல்",
  "மாட்டடி",
  "மாகேயம்",
  "மான்மறி",
  "மால்வீடு",
  "மாடிவீடு",
  "மாவெனல்",
  "மாமகம்",
  "மாலிகன்",
  "மாகாணம்",
  "மாகபதி",
  "மாயோனாள்",
  "மாதராள்",
  "மாய்தல்",
  "மாதுவம்",
  "மாட்டார்",
  "மார்பாணி",
  "மானாவாரி",
  "மாதுலன்",
  "மாதங்கி",
  "மான்றார்",
  "மாடவீதி",
  "மாமிசம்",
  "மாஞ்சம்",
  "மாவிளம்",
  "மாடகம்",
  "மானதம்",
  "மார்பம்",
  "மாராழம்",
  "மானவர்",
  "மாறுகால்",
  "மாவலன்",
  "மாலுகம்",
  "மாதிரம்",
  "மாதுளம்",
  "மானாகம்",
  "மாபலன்",
  "மாலோகம்",
  "மாலயம்",
  "மாறாட்டு",
  "மாயாபுரி",
  "மாம்பால்",
  "மாசுணம்",
  "மாசேனன்",
  "மான்றோல்",
  "மாமியார்",
  "மாதோயம்",
  "மாமகன்",
  "மாநாகம்",
  "மானிதம்",
  "மாரீசம்",
  "மாசியம்",
  "மித்திரை",
  "மிலாரடி",
  "மித்தியை",
  "மிருகாரி",
  "மிருடன்",
  "மிச்சம்",
  "மிகிரன்",
  "மிதுக்கை",
  "மிரியம்",
  "மிறுக்கு",
  "மிளப்பு",
  "மிருசம்",
  "மிதுனன்",
  "மிஞ்சிகை",
  "மிகைமொழி",
  "மிருத்து",
  "மிதவாதி",
  "மிக்கோர்",
  "மின்னல்",
  "மிரியல்",
  "மின்மினி",
  "மிண்டன்",
  "மிசைதல்",
  "மிக்கது",
  "மிட்டாய்",
  "மினுக்கி",
  "மிக்கவை",
  "மினுக்கு",
  "மிகற்கை",
  "மிக்கார்",
  "மிரதம்",
  "மிக்கோன்",
  "மிதிதோல்",
  "மிளகாய்",
  "மிருடானி",
  "மிலைதல்",
  "மிருகம்",
  "மிருஞ்சி",
  "மிகுந்த",
  "மிகுதல்",
  "மிடியன்",
  "மிதுனம்",
  "மித்துரு",
  "மிதியடி",
  "மிதாசனி",
  "மிசைஞர்",
  "மிசிரம்",
  "மிச்சில்",
  "மிதப்பு",
  "மின்னார்",
  "மிகிரம்",
  "மிடுக்கு",
  "மிகுமழை",
  "மிசுக்கை",
  "மிருதம்",
  "மிகுதம்",
  "மீறுதல்",
  "மீனவன்",
  "மீகாரம்",
  "மீனாட்சி",
  "மீன்மடை",
  "மீன்பாடு",
  "மீனராசி",
  "மீட்டும்",
  "மீண்டும்",
  "மீன்சினை",
  "மீலனம்",
  "மீளுதல்",
  "மீக்கூறு",
  "மீளாவழி",
  "மீக்கோள்",
  "மீளவும்",
  "மீன்காரி",
  "மீன்முள்",
  "மீனூர்தி",
  "மீதாட்சி",
  "மீட்பர்",
  "மீத்தோல்",
  "மீதிடல்",
  "மீசரன்",
  "மீந்தது",
  "மீயாட்சி",
  "மீகாமன்",
  "மீனாண்டி",
  "மீசரம்",
  "மீந்தோல்",
  "மீசுரம்",
  "மீன்பறி",
  "மீமாஞ்சை",
  "மீனரசு",
  "மீன்செலு",
  "முன்னோடி",
  "முருகன்",
  "முக்காழி",
  "முழுநாள்",
  "முதுவல்",
  "முடிச்சு",
  "முயக்கு",
  "முக்காலி",
  "முக்கால்",
  "முதலடி",
  "முடிப்பு",
  "முரிகம்",
  "முதனூல்",
  "முதலோன்",
  "முணங்கு",
  "முப்புரி",
  "முதியம்",
  "முட்டான்",
  "முதலாளி",
  "முதியான்",
  "முகிதல்",
  "முதையல்",
  "முன்றில்",
  "முதுதலை",
  "முதனீர்",
  "முறிகரை",
  "முகவுரை",
  "முரஞ்சு",
  "முகநிலை",
  "மும்மீன்",
  "முருங்கை",
  "முற்கூறு",
  "முன்றுறை",
  "முறைப்பு",
  "முதுநீர்",
  "முதல்வி",
  "முமுடம்",
  "முப்பலை",
  "முகரன்",
  "முடிநாறு",
  "முக்காழ்",
  "முரம்பு",
  "முகுளம்",
  "முதன்மை",
  "முட்டிகை",
  "முதியாள்",
  "முட்டாள்",
  "முன்பனி",
  "முதுசொல்",
  "முடிநர்",
  "முனையோர்",
  "முக்கோண்",
  "முன்கடை",
  "முடக்கு",
  "முன்பன்",
  "முணைதல்",
  "முற்சனி",
  "முன்னாடி",
  "முடவன்",
  "முரலல்",
  "முட்டாறு",
  "முற்றிமை",
  "முரிநிலை",
  "முதுமொழி",
  "முதியோர்",
  "முற்றவை",
  "முழுந்து",
  "முதலூழி",
  "முன்னீடு",
  "மும்மாரி",
  "முசிரன்",
  "முடுகடி",
  "முறைவன்",
  "முதுவர்",
  "முற்றில்",
  "முற்றல்",
  "முதியன்",
  "முடிமாலை",
  "முரியல்",
  "முரற்கை",
  "முன்பால்",
  "முருக்கு",
  "முட்கோல்",
  "மும்மடி",
  "முண்டம்",
  "முன்னடி",
  "முடிமார்",
  "முப்பொறி",
  "முரிவாய்",
  "முஞ்சம்",
  "முரகரி",
  "முளையாணி",
  "முதிர்வு",
  "முற்பூண்",
  "முடைதல்",
  "முறியல்",
  "முதுமுறை",
  "முரிப்பு",
  "முளையான்",
  "முன்வாய்",
  "முசிரம்",
  "முழிதல்",
  "முந்தல்",
  "முதுகால்",
  "முகவணை",
  "முறையுளி",
  "முழங்கை",
  "முழுக்கு",
  "முறையீடு",
  "முதனாள்",
  "முக்குடை",
  "முகரிமை",
  "முதுகுடி",
  "முன்சொல்",
  "முழுவல்",
  "முளிதல்",
  "முன்னிரை",
  "முழுவலி",
  "முண்டன்",
  "முறுவல்",
  "முகமன்",
  "முகரம்",
  "முடுவல்",
  "முன்னிலை",
  "முரிதல்",
  "முக்கோல்",
  "முறுகல்",
  "முடுகல்",
  "முண்டாசு",
  "முகமூடி",
  "முகிரன்",
  "முறியன்",
  "முடங்கு",
  "முன்மொழி",
  "முக்கனி",
  "முடங்கி",
  "முரவம்",
  "முன்னுரை",
  "முரிவரி",
  "முந்தன்",
  "முசகம்",
  "முட்டடி",
  "முசுடர்",
  "முறிதல்",
  "முணவல்",
  "முடுக்கு",
  "முகவீணை",
  "முடிதல்",
  "முந்திரி",
  "முகிழம்",
  "முத்தம்",
  "முட்டம்",
  "முக்கம்",
  "முந்நாடு",
  "முன்னம்",
  "முதிரம்",
  "முடைசல்",
  "முனிவர்",
  "முந்தூழ்",
  "முசுப்பு",
  "முன்னர்",
  "முந்தானை",
  "முப்பான்",
  "முப்பகை",
  "முன்றாய்",
  "முதலான",
  "முதலிய",
  "முதுகாடு",
  "முகவரி",
  "முப்போது",
  "முறைகேடு",
  "முக்கல்",
  "முதனிலை",
  "முன்றானை",
  "முடியல்",
  "முதிர்பு",
  "முத்திரை",
  "முமுட்சு",
  "முட்கீரை",
  "முறிச்சி",
  "முத்தரை",
  "முரசம்",
  "முசுண்டை",
  "முத்தர்",
  "முரட்கை",
  "முறிப்பு",
  "முசுண்டி",
  "முகவழி",
  "முகதலை",
  "முடிவுரை",
  "முனைதல்",
  "முகிரம்",
  "முத்தலை",
  "முயற்சி",
  "முற்பாடு",
  "முகுடம்",
  "முன்னேர்",
  "மும்முறை",
  "முழவம்",
  "முசிதல்",
  "முள்வேலி",
  "முனகர்",
  "முழுஞ்சு",
  "முகுரம்",
  "முகப்பு",
  "முடிவினை",
  "முற்பனி",
  "முயல்வு",
  "முனிவன்",
  "முசுட்டை",
  "முழக்கு",
  "முகராசி",
  "முதற்பா",
  "முன்னன்",
  "முரற்சி",
  "முனைவன்",
  "முக்குழி",
  "முன்னணி",
  "முற்றாமை",
  "முன்முறை",
  "மும்மணி",
  "முதிரன்",
  "முன்னணை",
  "முக்குளி",
  "முடத்தி",
  "முழைதல்",
  "முரல்வு",
  "முறுக்கு",
  "முற்றுகை",
  "முத்தன்",
  "முன்றாதை",
  "முழுக்க",
  "முதுபாலை",
  "முரண்டு",
  "முதுகண்",
  "முக்காணி",
  "முன்னோர்",
  "முசலிகை",
  "முடிமணி",
  "முப்புடி",
  "முன்னுற",
  "முழந்து",
  "முற்றம்",
  "முடந்தை",
  "முகவாள்",
  "முழுதோன்",
  "முழுத்த",
  "முரப்பா",
  "முரப்பு",
  "முயற்று",
  "முழுசும்",
  "முருடன்",
  "முரடன்",
  "முறண்டு",
  "முகசோதி",
  "முந்நீர்",
  "முழுநெறி",
  "முதுபாழ்",
  "முசலம்",
  "முகவோலை",
  "முட்டிடை",
  "முன்பில்",
  "முழுவாசி",
  "முற்சீர்",
  "முற்றும்",
  "முதனடை",
  "முந்நூல்",
  "முரணர்",
  "முற்றன்",
  "முழுமணி",
  "முப்பாழ்",
  "முனைஞர்",
  "முகைதல்",
  "முழுமதி",
  "முசிப்பு",
  "மும்மொழி",
  "முற்பால்",
  "முடிநடை",
  "முன்னோன்",
  "முத்தவி",
  "முரணணி",
  "முப்பால்",
  "முதுவோர்",
  "முற்கம்",
  "முருந்து",
  "முன்னல்",
  "முதுபுண்",
  "முறைநீர்",
  "முக்காடு",
  "முறையோர்",
  "முனிதல்",
  "முன்பின்",
  "முதலீடு",
  "முன்னூல்",
  "முகிளம்",
  "முலைவிலை",
  "முழுமன்",
  "முகவேலை",
  "முழுமனை",
  "முச்சில்",
  "முன்னீர்",
  "முன்மடி",
  "மூவட்சி",
  "மூலவாயு",
  "மூக்கிலி",
  "மூதாளர்",
  "மூலநோய்",
  "மூனாயம்",
  "மூலவர்",
  "மூலவேர்",
  "மூக்கில்",
  "மூவாமலை",
  "மூசாப்பு",
  "மூழிவாய்",
  "மூர்க்கை",
  "மூடிகம்",
  "மூர்க்கு",
  "மூக்கன்",
  "மூலைக்கை",
  "மூழாக்கு",
  "மூவிடம்",
  "மூத்தாள்",
  "மூடனம்",
  "மூலகம்",
  "மூசுதல்",
  "மூத்தல்",
  "மூவுதல்",
  "மூடுசீலை",
  "மூஞ்சுறு",
  "மூலியம்",
  "மூடுவழி",
  "மூடுதல்",
  "மூத்தோன்",
  "மூலநாள்",
  "மூருவம்",
  "மூடுபாறை",
  "மூத்தார்",
  "மூவினம்",
  "மூர்ச்சை",
  "மூப்பர்",
  "மூசுண்டை",
  "மூங்கர்",
  "மூத்தம்",
  "மூர்த்தி",
  "மூளூதல்",
  "மூக்கம்",
  "மூட்டம்",
  "மூள்தல்",
  "மூதறிவு",
  "மூக்கறை",
  "மூலவோலை",
  "மூலநாடி",
  "மூவடிவு",
  "மூடுபனி",
  "மூக்கர்",
  "மூதுவர்",
  "மூஞ்சூறு",
  "மூடுதிரை",
  "மூய்தல்",
  "மூக்கணை",
  "மூப்பன்",
  "மூவுலகு",
  "மூழ்தல்",
  "மூழக்கு",
  "மூவெயில்",
  "மூக்கொலி",
  "மூங்கில்",
  "மூக்கூளை",
  "மூதாட்டி",
  "மூண்டன்",
  "மூத்தோர்",
  "மூடமதி",
  "மென்மேல்",
  "மெல்கோல்",
  "மென்கால்",
  "மெல்லணை",
  "மெய்யுரை",
  "மென்மெல",
  "மெய்யுறை",
  "மெல்லிது",
  "மெலிதல்",
  "மெலிப்பு",
  "மெந்திரி",
  "மெல்லிசை",
  "மெய்மாசு",
  "மெல்லிசு",
  "மெய்வலி",
  "மெதுக்கு",
  "மெல்லென",
  "மென்கண்",
  "மெலியார்",
  "மெய்வேறு",
  "மெல்வினை",
  "மெல்லிலை",
  "மெய்ம்மை",
  "மெய்ப்பை",
  "மெள்ளென",
  "மெய்ப்பு",
  "மெல்லரி",
  "மென்பிணி",
  "மெல்லடை",
  "மெழுக்கு",
  "மென்னகை",
  "மென்பால்",
  "மென்னடை",
  "மெலிகோல்",
  "மென்பறை",
  "மென்சொல்",
  "மெலுக்கு",
  "மெய்யன்",
  "மெய்யீறு",
  "மேலனம்",
  "மேவினர்",
  "மேதியான்",
  "மேல்வாய்",
  "மேன்றலை",
  "மேசகம்",
  "மேய்தல்",
  "மேலுக்கு",
  "மேற்றிணை",
  "மேகதனு",
  "மேலாப்பு",
  "மேடகம்",
  "மேடூகம்",
  "மேந்தோல்",
  "மேலைவீடு",
  "மேற்கூரை",
  "மேதவர்",
  "மேல்விரி",
  "மேலாக்கு",
  "மேகநீர்",
  "மேய்க்கி",
  "மேல்கால்",
  "மேற்பால்",
  "மேன்மேல்",
  "மேயவன்",
  "மேம்பாடு",
  "மேல்பாதி",
  "மேதகம்",
  "மேலாதல்",
  "மேந்தலை",
  "மேற்கதி",
  "மேற்பாதி",
  "மேருமணி",
  "மேற்காது",
  "மேல்பால்",
  "மேவுதல்",
  "மேழகம்",
  "மேற்படி",
  "மேல்வினை",
  "மேலுறுதி",
  "மேய்ப்பு",
  "மேலாலம்",
  "மேழியர்",
  "மேலகம்",
  "மேகசம்",
  "மேடவீதி",
  "மேன்மலை",
  "மேகாரம்",
  "மேகனம்",
  "மேந்திகை",
  "மேலைவெளி",
  "மேவலர்",
  "மேதரம்",
  "மேடமதி",
  "மேல்மடை",
  "மேதாவினி",
  "மேலவன்",
  "மேகயோனி",
  "மேல்வாசி",
  "மேகவாயு",
  "மேல்மாடி",
  "மேகதாரி",
  "மேதகவு",
  "மேனிலர்",
  "மேளனம்",
  "மேய்மணி",
  "மேற்குடி",
  "மேற்கோள்",
  "மேல்வீடு",
  "மேலங்கி",
  "மேண்டம்",
  "மேதையர்",
  "மேட்டிமை",
  "மேகவாய்",
  "மேற்றலை",
  "மேற்றிசை",
  "மேகராடி",
  "மையன்மை",
  "மையன்மா",
  "மைத்தல்",
  "மைம்மீன்",
  "மைஞ்சன்",
  "மையவாடி",
  "மைத்திரி",
  "மையலார்",
  "மைச்சாலி",
  "மைத்துனி",
  "மையொற்றி",
  "மைதுனம்",
  "மைந்தன்",
  "மைரேயம்",
  "மைப்போது",
  "மையிழுது",
  "மைந்நூறு",
  "மைக்கூடு",
  "மைதானம்",
  "மைனிகன்",
  "மொத்தினி",
  "மொய்தாய்",
  "மொசிதல்",
  "மொழுப்பு",
  "மொக்குமா",
  "மொய்ப்பு",
  "மொழிதல்",
  "மொத்தம்",
  "மொக்கணி",
  "மொங்கன்",
  "மொழிநூல்",
  "மொக்குள்",
  "மொண்ணன்",
  "மொந்தன்",
  "மொண்டல்",
  "மொந்தணி",
  "மொங்கான்",
  "மொக்கன்",
  "மொய்ம்பு",
  "மொண்டணி",
  "மொசுப்பு",
  "மொழியோசை",
  "மோகலீலை",
  "மோசனம்",
  "மோதிரம்",
  "மோழைவழி",
  "மோங்கில்",
  "மோதரம்",
  "மோகனம்",
  "மோட்டோடு",
  "மோகிதன்",
  "மோட்சம்",
  "மோதவம்",
  "மோனீகம்",
  "மோடனம்",
  "மோதகம்",
  "மோரடம்",
  "மோட்டன்",
  "மோசாடம்",
  "மோத்தல்",
  "மோகவிலை",
  "மோப்பம்",
  "மோசகன்",
  "மோகிதம்",
  "மோதுதல்",
  "மோக்கம்",
  "மோகரம்",
  "மோட்டோலை",
  "மௌசலம்",
  "யவனம்",
  "யசனம்",
  "யவனிகை",
  "யந்திரு",
  "யதேச்சை",
  "யமளம்",
  "யவனாள்",
  "யதிவழு",
  "யவனர்",
  "யட்சிணி",
  "யமகம்",
  "யட்சம்",
  "யஞ்ஞன்",
  "யஞ்ஞம்",
  "யாதவர்",
  "யாவதும்",
  "யானைத்தீ",
  "யாமிகன்",
  "யாசகம்",
  "யாங்கண்",
  "யாகநிலை",
  "யாசிதம்",
  "யாமபதி",
  "யாவகம்",
  "யாபனம்",
  "யாத்தல்",
  "யாமளம்",
  "யாத்தார்",
  "யாதவம்",
  "யாதனம்",
  "யாமியம்",
  "யாகசாலை",
  "யாங்கர்",
  "யாமயாழ்",
  "யானைக்கை",
  "யாதிகன்",
  "யானையடி",
  "யாமுனம்",
  "யானைவாரி",
  "யாமைமணை",
  "யாகபதி",
  "யாவரும்",
  "யாமபேரி",
  "யாதபதி",
  "யாப்பறை",
  "யாதவன்",
  "யானைமால்",
  "யாண்டும்",
  "யாமவதி",
  "யாசகன்",
  "யாத்திரை",
  "யுத்தம்",
  "யுகளம்",
  "யூகசாலி",
  "யூதபதி",
  "யூதபம்",
  "யூகவான்",
  "யோகநிலை",
  "யோகவான்",
  "யோதனம்",
  "யோகிப்பு",
  "யோசனன்",
  "யோகரூடி",
  "யோசனம்",
  "யௌவனம்",
  "யௌவனிகை",
  "யௌகிகம்",
  "யௌதகம்",
  "வவ்வால்",
  "வல்லேறு",
  "வர்மம்",
  "வசிகன்",
  "வராகன்",
  "வச்சனி",
  "வதிரன்",
  "வலயம்",
  "வரைப்பு",
  "வரத்து",
  "வழுவாய்",
  "வருத்து",
  "வழுவாமை",
  "வழிமொழி",
  "வடரம்",
  "வராகம்",
  "வகுப்பு",
  "வத்தாலை",
  "வசனம்",
  "வனைதல்",
  "வதகன்",
  "வறட்சி",
  "வன்காய்",
  "வராளம்",
  "வடிதல்",
  "வதகம்",
  "வறட்டி",
  "வரைநேமி",
  "வறுவிலி",
  "வசுவசி",
  "வண்சிறை",
  "வந்தியை",
  "வஞ்சம்",
  "வருணன்",
  "வருணனை",
  "வலூகம்",
  "வம்பன்",
  "வல்கம்",
  "வடையம்",
  "வடுகர்",
  "வந்தனை",
  "வந்தனி",
  "வஞ்சனி",
  "வஞ்சன்",
  "வங்கூழ்",
  "வஞ்சனை",
  "வருணம்",
  "வடக்கு",
  "வழியடை",
  "வரதம்",
  "வழிமுறை",
  "வழுவாடி",
  "வற்றம்",
  "வலைப்பை",
  "வடுகன்",
  "வறண்டி",
  "வழிபறி",
  "வடிவம்",
  "வறியோன்",
  "வனவன்",
  "வயாநோய்",
  "வகுதல்",
  "வருமொழி",
  "வம்பல்",
  "வலவாய்",
  "வழுதுணை",
  "வகைமாலை",
  "வற்றல்",
  "வருடம்",
  "வறியான்",
  "வகையுளி",
  "வயமம்",
  "வடமீன்",
  "வயிரம்",
  "வறடன்",
  "வத்தினை",
  "வயிரவி",
  "வலைவன்",
  "வனவாசி",
  "வழிபாடு",
  "வசந்தா",
  "வசந்தி",
  "வண்டுறை",
  "வந்தேறி",
  "வழுநீர்",
  "வழாஅல்",
  "வளும்பு",
  "வருவாய்",
  "வதுவர்",
  "வரகதி",
  "வரூதினி",
  "வளயம்",
  "வணிகம்",
  "வலையன்",
  "வரியோலை",
  "வட்கல்",
  "வடிகால்",
  "வண்ணடை",
  "வதிதல்",
  "வழிநிலை",
  "வணைதல்",
  "வன்மொழி",
  "வட்கர்",
  "வற்சன்",
  "வன்கண்",
  "வலிதல்",
  "வளைநீர்",
  "வழிவழி",
  "வணிகன்",
  "வசுகிரி",
  "வனபதி",
  "வரம்பு",
  "வயமான்",
  "வல்லடி",
  "வண்ணான்",
  "வலிதம்",
  "வகுளம்",
  "வன்பால்",
  "வலங்கை",
  "வல்லபி",
  "வன்கனி",
  "வச்சிரி",
  "வகையறா",
  "வனமரை",
  "வண்ணிகை",
  "வல்லபை",
  "வங்காளி",
  "வல்லான்",
  "வளைவாணி",
  "வட்புலி",
  "வன்பார்",
  "வடிமணி",
  "வற்சலை",
  "வடந்தை",
  "வல்லூறு",
  "வலவன்",
  "வருகம்",
  "வருமாறு",
  "வரிதல்",
  "வடமொழி",
  "வயணம்",
  "வலையம்",
  "வளர்வு",
  "வள்ளிசு",
  "வறிஞன்",
  "வறியன்",
  "வடபூமி",
  "வல்லாமை",
  "வரிவரி",
  "வல்லோன்",
  "வல்லார்",
  "வல்வினை",
  "வரையறை",
  "வல்லாரை",
  "வறுதல்",
  "வடிவேல்",
  "வறுவல்",
  "வன்மான்",
  "வசியம்",
  "வல்லிகை",
  "வளகம்",
  "வல்லமை",
  "வல்லம்",
  "வல்லரி",
  "வளிசம்",
  "வரவர",
  "வசைதல்",
  "வளைமணி",
  "வச்சம்",
  "வரைநீர்",
  "வசிகம்",
  "வட்டுடை",
  "வனமாலை",
  "வழுநிலை",
  "வனமாலி",
  "வல்லிசை",
  "வசுநாள்",
  "வத்தம்",
  "வச்சரி",
  "வல்வில்",
  "வறையோடு",
  "வர்க்கை",
  "வல்லவி",
  "வடவர்",
  "வடவரை",
  "வலாகம்",
  "வளநாடு",
  "வத்தனை",
  "வன்கொலை",
  "வகையார்",
  "வடிகாது",
  "வதந்தி",
  "வழிநடை",
  "வன்னனை",
  "வலியான்",
  "வசம்பு",
  "வடமேரு",
  "வட்டிகை",
  "வட்டணை",
  "வமனம்",
  "வக்கணை",
  "வயினம்",
  "வளந்து",
  "வயவன்",
  "வமிசம்",
  "வட்டன்",
  "வடியல்",
  "வற்பம்",
  "வழிவகை",
  "வலட்டி",
  "வன்னம்",
  "வனிதம்",
  "வன்னிகை",
  "வள்ளம்",
  "வசுவாசி",
  "வண்டர்",
  "வங்கர்",
  "வனசன்",
  "வராசான்",
  "வசநாவி",
  "வளிமறை",
  "வண்டல்",
  "வள்ளல்",
  "வற்காலி",
  "வழுக்கை",
  "வரைவாழை",
  "வழுக்கு",
  "வனப்பு",
  "வடகோடு",
  "வதவல்",
  "வழும்பு",
  "வடிம்பு",
  "வற்கம்",
  "வனசம்",
  "வன்பகை",
  "வசிதடி",
  "வளையில்",
  "வறுநகை",
  "வன்புறை",
  "வடமலை",
  "வடுவரி",
  "வழிநாள்",
  "வயவரி",
  "வல்லுளி",
  "வன்புல்",
  "வளைதடி",
  "வண்டம்",
  "வட்டாணி",
  "வங்கன்",
  "வன்பொறை",
  "வட்டெலி",
  "வற்கலை",
  "வட்டகை",
  "வசுமதி",
  "வறுமொழி",
  "வழக்கு",
  "வசிரம்",
  "வற்கரி",
  "வண்டன்",
  "வறுவோடு",
  "வலைச்சி",
  "வண்ணம்",
  "வபனம்",
  "வருமதி",
  "வங்கம்",
  "வரகவி",
  "வரூதம்",
  "வழிநூல்",
  "வகுந்து",
  "வளைதல்",
  "வன்பாடு",
  "வரப்பு",
  "வழகம்",
  "வன்சிறை",
  "வண்டில்",
  "வழிதல்",
  "வரிச்சு",
  "வருநாள்",
  "வழியோன்",
  "வலக்கை",
  "வன்மம்",
  "வழுவல்",
  "வட்கார்",
  "வயனம்",
  "வழுதலை",
  "வதனம்",
  "வயானம்",
  "வளையம்",
  "வடசொல்",
  "வரையாடு",
  "வணிதம்",
  "வந்திகை",
  "வஞ்சகி",
  "வளைப்பு",
  "வயமீன்",
  "வடகிரி",
  "வணக்கு",
  "வயனர்",
  "வட்டில்",
  "வலுவன்",
  "வன்மீன்",
  "வடநூல்",
  "வரதன்",
  "வருநர்",
  "வருக்கு",
  "வரைதல்",
  "வருக்கை",
  "வலிநோய்",
  "வலியன்",
  "வசவன்",
  "வயக்கு",
  "வசனாவி",
  "வட்டினி",
  "வழாநிலை",
  "வளையல்",
  "வயாமது",
  "வலிதின்",
  "வன்சொல்",
  "வறுகறி",
  "வழியல்",
  "வழைச்சு",
  "வழிமோனை",
  "வசீரன்",
  "வளவன்",
  "வதுக்கு",
  "வரணம்",
  "வர்ணனை",
  "வழிதுறை",
  "வம்சம்",
  "வறையல்",
  "வசரம்",
  "வன்செவி",
  "வர்ணம்",
  "வரையாழி",
  "வரலாறு",
  "வலிப்பு",
  "வக்கரை",
  "வடகம்",
  "வசைகவி",
  "வடிசம்",
  "வளிநிலை",
  "வட்டம்",
  "வறுவிது",
  "வரடம்",
  "வள்ளடி",
  "வறுவிதை",
  "வடகலை",
  "வடிசல்",
  "வரநதி",
  "வலமன்",
  "வசிதல்",
  "வளாகம்",
  "வசுகம்",
  "வாதலம்",
  "வாரிதம்",
  "வாரணம்",
  "வாளேந்தி",
  "வாக்காள்",
  "வாசிதம்",
  "வாலறிவு",
  "வானிதம்",
  "வாகியம்",
  "வாணிமன்",
  "வாய்வது",
  "வாலகம்",
  "வாவரசி",
  "வாட்டல்",
  "வாதுதல்",
  "வாசாலன்",
  "வாக்கல்",
  "வானவெளி",
  "வாருகம்",
  "வாணியன்",
  "வாலியம்",
  "வாசிகம்",
  "வாணிச்சி",
  "வாட்டானை",
  "வானவன்",
  "வாயுதாரு",
  "வாணியம்",
  "வான்மொழி",
  "வாதநோய்",
  "வான்கண்",
  "வாகனம்",
  "வாரியன்",
  "வாளகிரி",
  "வாலேபம்",
  "வாகைமாலை",
  "வாரணாசி",
  "வாசரம்",
  "வாசிகன்",
  "வாலிபன்",
  "வாக்கியை",
  "வாணுதல்",
  "வாழிப்பு",
  "வாளகம்",
  "வான்கோழி",
  "வாளாண்மை",
  "வாழைப்பூ",
  "வாசநெய்",
  "வாவயம்",
  "வாஞ்சனை",
  "வாசவன்",
  "வாதகம்",
  "வாக்கன்",
  "வாகடம்",
  "வாயுசன்",
  "வாலுவன்",
  "வாளமலை",
  "வாராகம்",
  "வாய்விடு",
  "வாரிநிதி",
  "வானீரம்",
  "வாலேயம்",
  "வாரசூலை",
  "வார்த்தை",
  "வான்மணி",
  "வாயகம்",
  "வாய்பாடு",
  "வாரணன்",
  "வாள்வரி",
  "வாக்குமி",
  "வாரகம்",
  "வாயிலோர்",
  "வாலியன்",
  "வாசகம்",
  "வாயுமூலை",
  "வாரிகம்",
  "வாடிக்கை",
  "வாழ்வினை",
  "வாயதம்",
  "வானிழல்",
  "வான்மீன்",
  "வாளாங்கு",
  "வாலாயம்",
  "வாதகேது",
  "வாணீசன்",
  "வாழ்தல்",
  "வாலகன்",
  "வாலூகம்",
  "வான்றரு",
  "வாங்கல்",
  "வாளரம்",
  "வாய்மொழி",
  "வானுலகு",
  "வானவில்",
  "வாகீசர்",
  "வாயுதவி",
  "வாமாட்சி",
  "வாமனம்",
  "வாணகன்",
  "வாராவதி",
  "வாட்குடி",
  "வாய்தல்",
  "வாயசம்",
  "வாதிப்பு",
  "வாலாட்டு",
  "வாதனம்",
  "வாணிபம்",
  "வாய்நீர்",
  "வாருகோல்",
  "வாதநாடி",
  "வானரம்",
  "வார்ப்பு",
  "வாயுமானி",
  "வாதிகன்",
  "வாசனம்",
  "வாட்கோரை",
  "வாரிசம்",
  "வாவுதல்",
  "வாழுமோர்",
  "வாடுதல்",
  "வாயினிலை",
  "வாதுவன்",
  "வாய்ப்பு",
  "வான்கழி",
  "வாடாமாலை",
  "வாய்ப்பை",
  "வாரிசாதை",
  "வானோங்கி",
  "வாலரவி",
  "வாட்போர்",
  "வாணகம்",
  "வாம்பல்",
  "வாய்ச்சி",
  "வாணிகன்",
  "வாராகன்",
  "வாழ்நர்",
  "வாழ்நாள்",
  "வாசிரம்",
  "வாழ்ச்சி",
  "வாலுளுவை",
  "வாழைமுகை",
  "வாசகன்",
  "வாளவரை",
  "வாசபதி",
  "வாலாசம்",
  "வானகம்",
  "வாரநடை",
  "வாழகம்",
  "வானநாடி",
  "வாருணம்",
  "வானநாடு",
  "வாலமதி",
  "வாணிதம்",
  "வான்கொடி",
  "வாகீசன்",
  "வார்காது",
  "வாரியம்",
  "வாமனன்",
  "வாருதல்",
  "வாசிப்பு",
  "வானவர்",
  "வாழ்க்கை",
  "வாட்படை",
  "வாணிகம்",
  "வாய்மடை",
  "வாய்வாள்",
  "வாலமனை",
  "வாதநீர்",
  "வார்தல்",
  "வாதூகம்",
  "வாகுரம்",
  "வாய்கரை",
  "வாரிச்சி",
  "வாலிபம்",
  "வாழ்த்து",
  "வாசந்தி",
  "வாட்டம்",
  "வாய்வலி",
  "வாரிசன்",
  "விரக்தி",
  "விராமம்",
  "விண்ணல்",
  "விரலம்",
  "விழுக்கு",
  "விலாவணை",
  "விடாணம்",
  "விலாபம்",
  "விடைதல்",
  "விவட்சை",
  "வியயம்",
  "விருதர்",
  "விந்தன்",
  "விற்பொறி",
  "விசுவாசி",
  "விட்டிசை",
  "விசரம்",
  "விடுகாது",
  "வில்யாழ்",
  "விந்தம்",
  "விடுகதை",
  "விடக்கு",
  "விரியன்",
  "வில்லிமை",
  "விளிப்பு",
  "விழிச்சி",
  "விருதம்",
  "விசிலம்",
  "விசுத்தி",
  "விழிப்பு",
  "விடிவேளை",
  "விட்டிகை",
  "விழலன்",
  "விருகம்",
  "விரிதல்",
  "விடம்பை",
  "விலோமன்",
  "விறலோன்",
  "விதாகம்",
  "விசமம்",
  "விதுப்பு",
  "விடம்பு",
  "விற்றானை",
  "விம்பிகை",
  "விறகாள்",
  "விருந்து",
  "விடைகோள்",
  "விண்டல்",
  "விரிதூறு",
  "வியலிகை",
  "விழிதுறை",
  "விற்கிடை",
  "விஞ்சதி",
  "விதைமணி",
  "வினாசம்",
  "விடிவோரை",
  "விமலம்",
  "விடையோன்",
  "விருந்தை",
  "விருட்டி",
  "விரீடம்",
  "விவாகம்",
  "வினையிலி",
  "விளாகம்",
  "விடபம்",
  "விரணம்",
  "விசித்தி",
  "விதந்து",
  "விநாழிகை",
  "விரியல்",
  "விபஞ்சி",
  "விலாசினி",
  "விடுபதி",
  "விசலம்",
  "விகசம்",
  "வினைநர்",
  "விற்படை",
  "விதைநெல்",
  "விரலாழி",
  "விவாதம்",
  "வியர்வை",
  "வியாமம்",
  "விரிவாக",
  "வியர்வு",
  "வித்தன்",
  "விருசம்",
  "விகலம்",
  "விதிமுறை",
  "விறப்ப",
  "விளிம்பு",
  "விற்கோடி",
  "விழுப்பு",
  "விகடன்",
  "விராகம்",
  "விகிதம்",
  "விலைவாசி",
  "விரலேறு",
  "விநாடிகை",
  "விதுலன்",
  "விரிசல்",
  "விடயம்",
  "விடுபடை",
  "விடப்பு",
  "விசாகம்",
  "விலாளம்",
  "விருப்பு",
  "விகடம்",
  "விதரணை",
  "விற்பனை",
  "வியோமம்",
  "வித்துரு",
  "விமரிசை",
  "வில்வாள்",
  "விராகன்",
  "விபுதன்",
  "விரிநூல்",
  "விரகன்",
  "விண்ணேறு",
  "விரதன்",
  "விராட்டு",
  "வினைவலி",
  "விபசாரி",
  "விதுரன்",
  "விடிமீன்",
  "விசாகன்",
  "விறாட்டி",
  "வினைவர்",
  "விதுடன்",
  "விடுதுறை",
  "வித்தம்",
  "விதுலம்",
  "விகாரம்",
  "விழுமம்",
  "விலாவம்",
  "விருச்சி",
  "விடதம்",
  "விரியோலை",
  "விடுப்பு",
  "விதப்பு",
  "விளாப்பு",
  "விலையாள்",
  "விளப்பு",
  "விபவம்",
  "வியநெறி",
  "வினையன்",
  "வில்வம்",
  "விகலன்",
  "விம்மல்",
  "விதண்டை",
  "வியப்ப",
  "வின்னம்",
  "வியாதம்",
  "விதுரம்",
  "விற்றூண்",
  "விரூபம்",
  "விராதம்",
  "விலாவொடி",
  "வியாதன்",
  "விரோசினி",
  "விசலிகை",
  "விடுவாய்",
  "விரிப்பு",
  "வினையம்",
  "விடாயன்",
  "விரிச்சி",
  "விலோதம்",
  "விம்மம்",
  "விடதாலி",
  "விதனம்",
  "விபாடம்",
  "விநயம்",
  "விலாங்கு",
  "விதிவினை",
  "விறப்பு",
  "விசுக்கு",
  "விடதாரி",
  "விதானம்",
  "விலாசம்",
  "விவிதம்",
  "விசயன்",
  "விசும்பு",
  "விழம்பு",
  "விதரம்",
  "வியூகம்",
  "வினைஞர்",
  "விடுதோல்",
  "விரளல்",
  "விடாதம்",
  "விகாசம்",
  "விண்டேர்",
  "வியலுள்",
  "வியாப்தி",
  "விழைதல்",
  "விசிப்பு",
  "வியமம்",
  "விடமுள்",
  "வியாளம்",
  "விசையம்",
  "விரைகால்",
  "விசுவம்",
  "விசயம்",
  "வினகம்",
  "விலாசனை",
  "விசாரம்",
  "விம்பம்",
  "விரோதம்",
  "விள்ளல்",
  "விட்டல்",
  "விரயம்",
  "விழுமிய",
  "விலைமாது",
  "வியோகம்",
  "வியட்டி",
  "விரகம்",
  "விசிதம்",
  "விடிகாலை",
  "விபாவனை",
  "விசையன்",
  "விரவார்",
  "வியப்பு",
  "விசுவன்",
  "விபினம்",
  "விபத்து",
  "விபத்தி",
  "வில்லார்",
  "வினையாள்",
  "விழுத்து",
  "விலக்கு",
  "விக்கல்",
  "விதாதிரு",
  "விட்டில்",
  "விகுணம்",
  "வியானன்",
  "விகிரம்",
  "விரதம்",
  "விசாலம்",
  "விசேடம்",
  "விழவணி",
  "விசகலி",
  "விட்டம்",
  "விரிஞ்சி",
  "விருடம்",
  "விகிருதி",
  "விட்டரி",
  "விசதம்",
  "விடுநாண்",
  "விற்றல்",
  "விகண்டை",
  "வில்லாள்",
  "விதியுளி",
  "விதிதம்",
  "விபாகம்",
  "விடகாரி",
  "விளைகரி",
  "விறைப்பு",
  "விலைகோள்",
  "வியாபாரி",
  "விச்சம்",
  "விளக்கு",
  "விழுசுமை",
  "விதலம்",
  "விசிகம்",
  "விளம்பு",
  "விளம்பி",
  "விலைவன்",
  "விளைநீர்",
  "விரளம்",
  "விசாணம்",
  "விரவல்",
  "விற்பிடி",
  "விண்ணோர்",
  "வில்லாளி",
  "விவசம்",
  "வினயம்",
  "வின்னாண்",
  "விக்கிள்",
  "விளங்கு",
  "வினாவிடை",
  "விடுகாலி",
  "விடாமழை",
  "விபாதம்",
  "விபாவரி",
  "விடியல்",
  "விடமன்",
  "விசனம்",
  "விடுமுறை",
  "விவரம்",
  "விதாரம்",
  "விளிதல்",
  "விபரம்",
  "விகிர்தி",
  "விசாரணை",
  "விகற்பு",
  "வியவன்",
  "விச்சுளி",
  "விதிக்கு",
  "வில்லடை",
  "விரலணி",
  "விக்குள்",
  "விடுகவி",
  "விடுதல்",
  "விருபன்",
  "வினோதன்",
  "விடுதலை",
  "விமலன்",
  "விடமம்",
  "விரிசிகை",
  "விழியன்",
  "விழைச்சி",
  "விளையுள்",
  "விழைச்சு",
  "விவேகம்",
  "வியாத்தி",
  "விவத்து",
  "விவத்தை",
  "விடர்வு",
  "வில்விழா",
  "விளைதல்",
  "விரிபம்",
  "விடரளை",
  "விதும்பு",
  "விடங்கு",
  "வித்தாரி",
  "விராலம்",
  "வினாதல்",
  "வினாவழு",
  "விடைப்பு",
  "விழவர்",
  "விதியர்",
  "விடிதல்",
  "விழாவணி",
  "விடையம்",
  "விண்மணி",
  "வியாழம்",
  "விரிவுரை",
  "விழையார்",
  "விசானம்",
  "விபோதம்",
  "விபுணன்",
  "விபுலம்",
  "வியாபகி",
  "வியாழன்",
  "விருத்தி",
  "விஞ்சம்",
  "விவகாரி",
  "விடலம்",
  "விரைசொல்",
  "விதிப்பு",
  "விமானம்",
  "விரைப்பு",
  "விட்டேறு",
  "விலங்கி",
  "விலயம்",
  "விரைதல்",
  "விண்டார்",
  "விலங்கு",
  "விளைஞர்",
  "விரசம்",
  "விழுதல்",
  "விட்டுணு",
  "விகாதம்",
  "விருத்தை",
  "விடையன்",
  "விண்மீன்",
  "வினோதம்",
  "வீம்பன்",
  "வீழ்நாள்",
  "வீழ்ச்சி",
  "வீழ்பிடி",
  "வீசிமாலி",
  "வீங்கல்",
  "வீராவளி",
  "வீண்சொல்",
  "வீராணம்",
  "வீரெனல்",
  "வீடுதல்",
  "வீரமாலை",
  "வீசகரி",
  "வீரமுடி",
  "வீட்டாள்",
  "வீட்சணை",
  "வீரணன்",
  "வீழ்க்கை",
  "வீறாப்பு",
  "வீடாரம்",
  "வீரவெறி",
  "வீரணம்",
  "வீசுதல்",
  "வீடுநர்",
  "வீரமொழி",
  "வீழ்தல்",
  "வீசுவில்",
  "வீழ்கதி",
  "வீரவளை",
  "வீற்றம்",
  "வீடாவழி",
  "வீருதம்",
  "வீராப்பு",
  "வீழ்மீன்",
  "வீறுதல்",
  "வீச்சம்",
  "வீக்கம்",
  "வீடுபேறு",
  "வீசனம்",
  "வீற்றும்",
  "வீண்பாடு",
  "வீரியம்",
  "வீபத்து",
  "வெதுப்பு",
  "வெண்ணரி",
  "வெள்ளானை",
  "வெல்லம்",
  "வெளிசம்",
  "வெண்ணீறு",
  "வெண்ணீர்",
  "வெண்கவி",
  "வெகுளாமை",
  "வெஞ்சொல்",
  "வெந்நீர்",
  "வெய்யில்",
  "வெண்படை",
  "வெய்யோன்",
  "வெண்காழ்",
  "வெண்பாவை",
  "வெண்டாவி",
  "வெட்டல்",
  "வெள்ளர்",
  "வெள்ளேடு",
  "வெண்கால்",
  "வெள்ளாமை",
  "வெளுப்பு",
  "வெறிதல்",
  "வெருகம்",
  "வெங்கன்",
  "வெண்ணகை",
  "வெள்ளரி",
  "வெட்டுணி",
  "வெற்றோலை",
  "வெண்பொன்",
  "வெண்காரை",
  "வெண்மீன்",
  "வெள்ளம்",
  "வெறியன்",
  "வெறிகோள்",
  "வெஃகல்",
  "வென்றோன்",
  "வெருட்சி",
  "வெயர்வை",
  "வெள்ளாறு",
  "வெய்யது",
  "வெற்றர்",
  "வெகுள்வு",
  "வெய்துரை",
  "வெங்கோல்",
  "வெளியடை",
  "வெச்சம்",
  "வெளியார்",
  "வெற்றல்",
  "வெண்கிடை",
  "வெள்ளுவா",
  "வெற்றம்",
  "வெகுமதி",
  "வெண்டேர்",
  "வெகுவாய்",
  "வெண்டலை",
  "வெள்ளிலை",
  "வெறுங்கை",
  "வெண்டளை",
  "வெள்ளில்",
  "வெங்கண்",
  "வெள்ளாவி",
  "வெயிலோன்",
  "வெள்வெடி",
  "வெம்பல்",
  "வெண்கொல்",
  "வெல்லல்",
  "வெண்குடை",
  "வெண்மலை",
  "வெவ்வினை",
  "வெண்ணாரை",
  "வெண்ணிலை",
  "வெல்லுமா",
  "வெட்டிது",
  "வெண்பாசி",
  "வெறியாள்",
  "வெள்யாடு",
  "வெவ்வுரை",
  "வெந்துளி",
  "வெடுக்கு",
  "வெறுமனை",
  "வெறுமனே",
  "வெண்மழை",
  "வெடியல்",
  "வெறுமன்",
  "வெள்வாள்",
  "வெங்கார்",
  "வெண்பொடி",
  "வெள்ளென",
  "வெறுப்பு",
  "வெண்சோறு",
  "வெள்ளைமை",
  "வெஇயோன்",
  "வெள்ளணி",
  "வெற்றிமை",
  "வெளிறன்",
  "வெலிகம்",
  "வெதிரன்",
  "வெருட்டி",
  "வெற்பன்",
  "வெள்விழி",
  "வெள்ளோலை",
  "வெளிநாடு",
  "வெஃகாமை",
  "வெண்டோடு",
  "வெடிப்பு",
  "வெண்பலி",
  "வெற்றுரை",
  "வெப்பர்",
  "வெட்கம்",
  "வெள்ளிடி",
  "வெண்டாது",
  "வெறுவிலி",
  "வெறிநாய்",
  "வெள்ளிடை",
  "வெவ்வேறு",
  "வெள்ளடி",
  "வெங்குரு",
  "வெவ்விது",
  "வெய்யன்",
  "வெள்ளடை",
  "வெகுட்சி",
  "வெளிமடை",
  "வெப்பம்",
  "வெகுமாரி",
  "வெளிமான்",
  "வெட்டிமை",
  "வெறிப்பு",
  "வெப்புள்",
  "வெதிரம்",
  "வெளியாடை",
  "வெள்ளோசை",
  "வெள்ளெலி",
  "வெறிச்சு",
  "வெங்கம்",
  "வெள்ளிது",
  "வெண்பிறை",
  "வெருவாமை",
  "வெள்வரி",
  "வெள்ளாடு",
  "வெஞ்சம்",
  "வெண்டிரை",
  "வெற்றாள்",
  "வெண்கரு",
  "வெட்பாலை",
  "வெங்கள்",
  "வெள்வாடை",
  "வெளியீடு",
  "வெஞ்சோறு",
  "வெள்வேல்",
  "வெண்கலி",
  "வெண்டுறை",
  "வெண்மணி",
  "வெண்ணெய்",
  "வெவ்வர்",
  "வெற்றிலை",
  "வெளிப்பு",
  "வெறுக்கை",
  "வெண்கொடி",
  "வெண்மதி",
  "வேலையாள்",
  "வேலசம்",
  "வேகரம்",
  "வேகடம்",
  "வேண்மான்",
  "வேணுகம்",
  "வேறாதல்",
  "வேண்டல்",
  "வேதநெறி",
  "வேற்றான்",
  "வேதகம்",
  "வேளாண்மை",
  "வேசறவு",
  "வேற்றுரு",
  "வேடிதம்",
  "வேசதாரி",
  "வேடுவன்",
  "வேனிலாளி",
  "வேட்டல்",
  "வேடகம்",
  "வேதியன்",
  "வேபனம்",
  "வேகவதி",
  "வேதினம்",
  "வேந்தவை",
  "வேறுபாடு",
  "வேளாவேளை",
  "வேனிலான்",
  "வேய்தல்",
  "வேற்றுமை",
  "வேகடன்",
  "வேசனம்",
  "வேதகன்",
  "வேண்டார்",
  "வேட்டான்",
  "வேர்ப்பு",
  "வேபாக்கு",
  "வேடிக்கை",
  "வேண்மாள்",
  "வேத்தன்",
  "வேம்பன்",
  "வேளாளன்",
  "வேசரம்",
  "வேட்டம்",
  "வேளாட்டி",
  "வேகாளம்",
  "வேண்டாமை",
  "வேடிச்சி",
  "வேதசம்",
  "வேற்றாள்",
  "வேந்துரு",
  "வேறிடம்",
  "வேட்கைமை",
  "வேந்தன்",
  "வேசாறல்",
  "வேதாளம்",
  "வேதனம்",
  "வேதிதம்",
  "வேத்தவை",
  "வேக்காடு",
  "வேதாந்தி",
  "வேட்டோன்",
  "வேசகம்",
  "வேடதாரி",
  "வேற்றோன்",
  "வேடச்சி",
  "வேரகம்",
  "வேலைநாள்",
  "வேணினர்",
  "வேளாவளி",
  "வேசாடல்",
  "வேடுபறி",
  "வேட்டாள்",
  "வேணுசம்",
  "வைதேயம்",
  "வைகலும்",
  "வைகாசம்",
  "வைக்கோல்",
  "வைதிகன்",
  "வைபவம்",
  "வைசியன்",
  "வைகுதல்",
  "வைணுகம்",
  "வைகாலம்",
  "வைத்தூறு",
  "வைசாகம்",
  "வைத்தல்",
  "வைதிகம்",
  "வைதேயன்",
  "வைணிகன்",
  "வைகாப்பு",
  "வைக்கல்",
  "வைகிருள்",
  "வைணவம்",
  "வைரியம்",
  "வைராகம்",
  "வைபோகம்",
  "வைப்புழி",
  "வைந்நுதி",
  "வையாபுரி",
  "வைரியர்",
  "வைணவர்",
  "வைடாலம்",
  "வையகம்",
  "காதலன்",
  "உழவன்",
  "விடுதலை",
  "வெப்பம்",
  "தந்தம்",
  "பஞ்சம்",
  "சிங்கம்",
  "சூரியன்",
  "தண்ணீர்",
  "தண்டனை",
  "அலமாரி",
  "நகரம்",
  "முயற்சி",
  "சிக்னல்",
  "குறியீடு",
  "புருவம்",
  "சீரகம்",
  "காவலர்",
  "மனிதன்",
  "திட்டம்",
  "பிறப்பு",
  "இறப்பு",
  "ஆரஞ்சு",
  "ஊஞ்சல்",
  "ஆதரவு",
  "விவசாயி",
  "வள்ளல்",
  "நிமிடம்",
  "கணக்கு",
  "சிலந்தி",
  "காகிதம்",
  "கிண்ணம்",
  "கிராமம்",
  "கூடாரம்",
  "சட்டம்",
  "கேடயம்",
  "மந்திரி",
  "கடினம்",
  "சமையல்",
  "சத்தம்",
  "தோட்டம்",
  "அரசன்",
  "இரண்டு",
  "புன்னகை",
  "குண்டூசி",
  "மக்கள்",
  "தங்கம்",
  "மக்கள்",
  "பண்டம்",
  "கண்கள்",
  "தென்றல்",
  "லஞ்சம்",
  "குறிப்பு",
  "கிரகம்",
  "விமானம்",
  "நாற்காலி",
  "சிவப்பு",
  "ஜன்னல்",
  "தக்காளி",
  "சிரிப்பு",
  "கண்ணாடி",
  "காவலர்",
  "கிரீடம்",
  "சிலந்தி",
  "நடனம்",
  "மஞ்சள்",
  "கங்காரு",
  "நண்பர்",
  "குரங்கு",
  "உட்கார்",
  "பட்டாணி",
  "கப்பல்",
  "பட்டம்",
  "மிளகாய்",
  "வட்டம்",
  "சதுரம்",
  "எறும்பு",
  "பேருந்து",
  "இனிப்பு",
  "உலகம்",
  "இருபது",
  "அன்னம்",
  "கடிதம்",
  "அறுபது",
  "விளக்கு",
  "குழந்தை",
  "கட்டில்",
  "இந்தியா",
  "அடுப்பு",
  "நூலகம்",
  "அண்ணன்",
  "ரம்பம்",
];

export default words;
