let words = [
  "காதலன்",
  "உழவன்",
  "விடுதலை",
  "வெப்பம்",
  "பண்டம்",
  "தண்ணீர்",
  "தென்றல்",
  "லஞ்சம்",
  "குறிப்பு",
  "கிரகம்",
  "விமானம்",
  "நாற்காலி",
  "சிவப்பு",
  "முயற்சி",
  "குறியீடு",
  "புருவம்",
  "சீரகம்",
  "காவலர்",
  "மனிதன்",
  "திட்டம்",
  "பிறப்பு",
  "தக்காளி",
  "சிங்கம்",
  "சூரியன்",
  "தண்டனை",
  "அலமாரி",
  "நகரம்",
  "இறப்பு",
  "ஊஞ்சல்",
  "ஆதரவு",
  "விவசாயி",
  "வள்ளல்",
  "நிமிடம்",
  "கணக்கு",
  "சிலந்தி",
  "காகிதம்",
  "கிண்ணம்",
  "கிராமம்",
  "கூடாரம்",
  "சட்டம்",
  "கேடயம்",
  "மந்திரி",
  "கடினம்",
  "சமையல்",
  "சத்தம்",
  "தோட்டம்",
  "அரசன்",
  "இரண்டு",
  "புன்னகை",
  "குண்டூசி",
  "மக்கள்",
  "தங்கம்",
  "மக்கள்",
  "சிரிப்பு",
  "கண்ணாடி",
  "காவலர்",
  "கிரீடம்",
  "சிலந்தி",
  "நடனம்",
  "மஞ்சள்",
  "கங்காரு",
  "நண்பர்",
  "குரங்கு",
  "தந்தம்",
  "பஞ்சம்",
  "உட்கார்",
  "பட்டாணி",
  "கப்பல்",
  "பட்டம்",
  "மிளகாய்",
  "வட்டம்",
  "சதுரம்",
  "எறும்பு",
  "பேருந்து",
  "இனிப்பு",
  "உலகம்",
  "இருபது",
  "அன்னம்",
  "கடிதம்",
  "அறுபது",
  "விளக்கு",
  "குழந்தை",
  "கட்டில்",
  "இந்தியா",
  "அடுப்பு",
  "நூலகம்",
  "அண்ணன்",
  "ரம்பம்",
];

export default words;
